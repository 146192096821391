import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../permissions.reducer';

import * as PermissionsReducer from './company-permissions.reducer';

const selectState = createSelector(
  selectFeatureState,
  (state: State) => state.companyPermissions
);

const selectCompanyPermissions = createSelector(selectState, PermissionsReducer.getCompanyPermissions);
const selectLoading = createSelector(selectState, PermissionsReducer.getLoading);
const selectLoaded = createSelector(selectState, PermissionsReducer.getLoaded);

export const PermissionsSelector = {
  selectState,
  selectLoading,
  selectLoaded,
  selectCompanyPermissions
};
