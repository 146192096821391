import { Injectable } from '@angular/core';
import { ReferenceDataService } from '@ifhms/common/angular/data-access/admin-api';
import { ContextService } from '@ifhms/common/angular/utils';
import { ReferenceDataType } from '@ifhms/models/admin';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';
import { RiskCategoriesActions } from './risk-categories.actions';

@Injectable()
export class RiskCategoriesEffects {

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoriesActions.get),
      withLatestFrom(this.contextService.appContext$),
      exhaustMap(([action, appContext]) => {
        return this.referenceDataService.getSelectListByType(
          action.context || appContext,
          ReferenceDataType.RiskCategory
        ).pipe(
          map((items: SelectListItemWithTranslationDto[]) => {
            if (!items) RiskCategoriesActions.error();
            return RiskCategoriesActions.getSuccess({ items });
          }),
          catchError(() => of(RiskCategoriesActions.error()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private contextService: ContextService,
    private referenceDataService: ReferenceDataService
  ) {
  }
}
