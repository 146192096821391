import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { CommonHasPermissionDirective } from './directives';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule
  ],
  declarations: [
    CommonHasPermissionDirective
  ],
  exports: [
    CommonHasPermissionDirective
  ]
})
export class CommonAngularPermissionsModule {}
