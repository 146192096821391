import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnterpriseUserInviteDto, EnterpriseUserListItemDto, EnterpriseUserUpdateDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class EnterpriseUserService {
  constructor(@Inject('apiUrl') private apiUrl: string, private http: HttpClient) {
  }

  getList(): Observable<EnterpriseUserListItemDto[]> {
    return this.http.get<EnterpriseUserListItemDto[]>(`${this.apiUrl}/Enterprise/Users/List`);
  }

  invite(enterpriseUserUpdateDto: EnterpriseUserInviteDto): Observable<EnterpriseUserListItemDto[]> {
    return this.http.put<EnterpriseUserListItemDto[]>(`${this.apiUrl}/Enterprise/Users/Invite`, enterpriseUserUpdateDto);
  }

  revoke(enterpriseUserId: string): Observable<EnterpriseUserListItemDto[]> {
    return this.http.put<EnterpriseUserListItemDto[]>(`${this.apiUrl}/Enterprise/Users/${enterpriseUserId}/Revoke`, {});
  }

  update(enterpriseUserUpdateDto: EnterpriseUserUpdateDto): Observable<EnterpriseUserListItemDto[]> {
    return this.http.put<EnterpriseUserListItemDto[]>(`${this.apiUrl}/Enterprise/Users`, enterpriseUserUpdateDto);
  }

  isEmailUnique(email: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { email }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Enterprise/Users/IsEmailUnique`, options);
  }
}
