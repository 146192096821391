<div class="group-wrapper" [class.no-spacing]="props['noSpacing']">
    <label class="sersi-group-label">
        {{props['label']}}
        <span *ngIf="props['required']"
            style="color: red"
            aria-hidden="true">
            *
        </span>

    </label>
    <div [class.group-wrapper-border]="props['showBorder']">
        <ng-container #fieldComponent></ng-container>
    </div>
</div>