import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthStorage } from 'angular-oauth2-oidc';

import { AUTH_CONFIG, AUTH_CONFIG_API_URLS, AuthServiceConfig } from '../interfaces';

@Injectable()
export class AuthRequestHeaderHttpInterceptor implements HttpInterceptor {
    
  constructor(
    private authStorage: OAuthStorage,
    @Inject(AUTH_CONFIG) private readonly authConfig: AuthServiceConfig,
    @Inject(AUTH_CONFIG_API_URLS) private readonly apiUrls: string|string[]
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    const url = req.url.toLowerCase();
    const isSecuredApiUrl = this.isSecuredUrl(url);
    const isTokenHeaderEnabled = this.authConfig.sendAccessToken;

    if (!isSecuredApiUrl || !isTokenHeaderEnabled) {
      return next.handle(req);
    }

    const authRequest = this.getRequestWithAuthHeader(req);

    return next.handle(authRequest);
  }

  private isSecuredUrl(url: string): boolean {
    const securedApiUrls = Array.isArray(this.apiUrls) ? this.apiUrls : [this.apiUrls];
    if (securedApiUrls[0]) {
      return securedApiUrls.some(apiUrl => url.toLowerCase().startsWith(apiUrl))
    }
    return false;
  }

  private getRequestWithAuthHeader(req: HttpRequest<unknown>): HttpRequest<unknown> {
    const token = this.authStorage.getItem('access_token');
    const authHeader = 'Bearer ' + token;
    const headers = req.headers.set('Authorization', authHeader);
    return req.clone({ headers });
  }
}
