import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
  LabourChargeDto,
  LabourChargeListItemDto,
  LabourChargeRequestDto
} from '@ifhms/models/admin';
import { dateToIsoStringEndOfDate, dateToIsoStringStartOfDate } from '@common/angular/utils';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ReferenceDataLabourChargesService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) { }

  add(feedlotId: string, labourCharge: LabourChargeDto): Observable<LabourChargeDto> {
    const updatedLabourCharge = this.adjustDates(labourCharge);
    return this.http.post<LabourChargeDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/LabourCharges`, updatedLabourCharge);
  }

  get(feedlotId: string, labourChargeId: string): Observable<LabourChargeDto> {
    return this.http.get<LabourChargeDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/LabourCharges/${labourChargeId}`).pipe(
      map((x: LabourChargeDto) => {

        for (const detail of x.details) {
          detail.effectiveFromDate = new Date(detail.effectiveFromDate);
          detail.effectiveToDate &&= new Date(detail.effectiveToDate as unknown as Date);
          detail.lastBilled &&= new Date(detail.lastBilled as unknown as Date);
        }

        return x;
      })
    );
  }

  getList(feedlotId: string): Observable<LabourChargeListItemDto[]> {
    return this.http.get<LabourChargeListItemDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/LabourCharges/List`);
  }

  delete(feedlotId: string, labourChargeId: string): Observable<unknown> {
    return this.http.delete(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/LabourCharges/${labourChargeId}/Delete`);
  }

  update(feedlotId: string, labourCharge: LabourChargeDto): Observable<LabourChargeDto> {
    const updatedLabourCharge = this.adjustDates(labourCharge);
    return this.http.put<LabourChargeDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/LabourCharges`, updatedLabourCharge);
  }

  activate(feedlotId: string, labourChargeId: string): Observable<LabourChargeDto> {
    return this.http.put<LabourChargeDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/LabourCharges/${labourChargeId}/Activate`, null);
  }

  deactivate(feedlotId: string, labourChargeId: string): Observable<LabourChargeDto> {
    return this.http.put<LabourChargeDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/LabourCharges/${labourChargeId}/Deactivate`, null);
  }

  isCodeUnique(feedlotId: string, code: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { code }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/LabourCharges/IsCodeValid`, options);
  }

  private adjustDates(labourCharge: LabourChargeDto): LabourChargeRequestDto {
    return {
      ...labourCharge,
      details: labourCharge.details.map((detail) => ({
        ...detail,
        effectiveFromDate: dateToIsoStringStartOfDate(detail.effectiveFromDate || new Date())!,
        effectiveToDate: dateToIsoStringEndOfDate(detail.effectiveToDate)
      }))
    };
  }
}
