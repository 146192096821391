import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  CompanyPermissionsEffects,
  CompanyPermissionsFacade,
  EnterprisePermissionsEffects,
  EnterprisePermissionsFacade,
  OperationPermissionsEffects,
  OperationPermissionsFacade
} from './+state';
import { FEATURE_NAME, reducer } from './+state/permissions.reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      EnterprisePermissionsEffects,
      CompanyPermissionsEffects,
      OperationPermissionsEffects
    ]),
    StoreModule.forFeature(FEATURE_NAME, reducer)
  ],
  providers: [
    EnterprisePermissionsEffects,
    EnterprisePermissionsFacade,
    CompanyPermissionsEffects,
    CompanyPermissionsFacade,
    OperationPermissionsEffects,
    OperationPermissionsFacade
  ]
})
export class IfhmsAdminWebDomainStatePermissionsModule {
}
