import { Inject, Injectable } from '@angular/core';
import { Route, Router, UrlTree } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { AuthFacade, AuthState } from '../+state';
import { AUTH_CONFIG, AuthServiceConfig, ContextLevelConfig } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthContextLevelGuard {

  constructor(
    private readonly authFacade: AuthFacade,
    private readonly router: Router,
    @Inject(AUTH_CONFIG) private readonly authConfig: AuthServiceConfig
  ) {
  }

  canMatch(route: Route): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authFacade.state$.pipe(
      filter(({ isAuthenticated }) => isAuthenticated),
      map((state: AuthState) => state.authUser!.role),
      map((userRoles: string[]) => {

        const { restrictedRoleAccess } = this.authConfig;

        if (!restrictedRoleAccess) return true;

        const accessDeniedPath = this.router.parseUrl(restrictedRoleAccess.accessDeniedPath);

        if (!userRoles.length) return accessDeniedPath;

        const targetRouteLevel = route.data?.[restrictedRoleAccess.levelDataKey];
        const routeAccessConfig = restrictedRoleAccess.levelsMap.find(routeConfig => routeConfig.level === targetRouteLevel);

        if (!routeAccessConfig?.requiredRoles.length) return true;

        if (this.isLevelAccessible(routeAccessConfig, userRoles)) {
          return true;
        }

        const fallbackRouteAccessConfig = restrictedRoleAccess.levelsMap.find(routeConfig => {
          return this.isLevelAccessible(routeConfig, userRoles);
        })

        if (fallbackRouteAccessConfig) {
          return this.router.parseUrl(fallbackRouteAccessConfig.path);
        }

        // Access denied
        return accessDeniedPath;
      })
    );
  }

  private isLevelAccessible(levelConfig: ContextLevelConfig, userRoles: string[]): boolean {
    return levelConfig.requiredRoles.some(requiredRole => userRoles.includes(requiredRole));
  }
}
