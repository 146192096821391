import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { RouteHistoryState } from './url-route-history.reducer';
import { UrlRouteHistorySelectors } from './url-route-history.selectors';
import { UrlRouteHistoryRecord } from '../../models';

@Injectable()
export class UrlRouteHistoryFacade {

  routes$ = this.store.select(UrlRouteHistorySelectors.selectRoutes);

  constructor(private store: Store<RouteHistoryState>) {}

  getLastHistoryRecordByPartialPath(path: string[]): Observable<UrlRouteHistoryRecord | null> {
    return this.routes$.pipe(
      // @ts-ignore TODO: remove me after TS release of https://github.com/microsoft/TypeScript/issues/48829
      map(routes => routes.findLast(route => {
        const { url } = route;
        return path.some(p => url.includes(p));
      }) || null)
    )
  }

}
