import { flatten } from 'flat';

import { CommonPermissionsMap } from '@common/angular/permissions';
import { HashMap } from '@common/angular/interfaces';

// TODO: Move ReportsExportWeightByWorkOrder override to the ifhms lib
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ReportPermissionsEnum } from '@ifhms/common/angular/interfaces';

export function transformPermission(permissionsMap: HashMap | null): CommonPermissionsMap | null {
  if (!permissionsMap) return null;

  const defaultPermissions = {
    [ReportPermissionsEnum.ReportsExportWeightByWorkOrder]: true
  }

  return { ...defaultPermissions, ...flatten(permissionsMap) };
}