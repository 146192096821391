import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import { LotsActions } from './lots.actions';
import * as reducer from './lots.reducer';
import { LotsSelectors } from './lots.selectors';

@Injectable()
export class LotsFacade {

  items$: Observable<SersiSelectListItem[]> = this.store.select(LotsSelectors.selectItems);

  constructor(private store: Store<reducer.State>) {
  }
  
  get(levelId?: string): void {
    this.store.dispatch(LotsActions.get( { levelId }));
  }
}
