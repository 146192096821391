import { createSelector } from '@ngrx/store';

import { selectFeatureState, State } from '../reducer';
import * as reducer from './pens.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.pens
);

const selectItems = createSelector(selectState, reducer.getAll);

export const PensSelectors = {
  selectItems
};
