import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as reducer from './default-values.reducer';
import { Observable } from 'rxjs';
import { FeedlotDefaultValuesSettingsDto } from '@ifhms/models/admin';
import { DefaultValuesSettingsActions } from './default-values.actions';
import { DefaultValuesSettingsSelectors } from './default-values.selectors';

@Injectable()
export class DefaultValuesSettingsFacade {

  loading$: Observable<boolean> = this.store.select(DefaultValuesSettingsSelectors.selectLoading);
  loaded$: Observable<boolean> = this.store.select(DefaultValuesSettingsSelectors.selectLoaded);
  updating$: Observable<boolean> = this.store.select(DefaultValuesSettingsSelectors.selectUpdating);
  updated$: Observable<boolean> = this.store.select(DefaultValuesSettingsSelectors.selectUpdated);
  defaultValuesSettings$: Observable<FeedlotDefaultValuesSettingsDto | null> = this.store.select(DefaultValuesSettingsSelectors.selectDefaultValuesSettings);

  constructor(private store: Store<reducer.State>) {}
  
  getDefaultValuesSettings(): void {
    this.store.dispatch(DefaultValuesSettingsActions.getDefaultValuesSettings());
  }

  updateDefaultValuesSettings(settings: FeedlotDefaultValuesSettingsDto): void {
    this.store.dispatch(DefaultValuesSettingsActions.updateDefaultValuesSettings({ settings }));
  }

  resetDefaultValuesSettings(): void {
    this.store.dispatch(DefaultValuesSettingsActions.resetDefaultValuesSettings());
  }
}
