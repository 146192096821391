import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { DentitionsActions } from './dentitions.actions';
import * as reducer from './dentitions.reducer';
import { DentitionsSelectors } from './dentitions.selectors';

@Injectable()
export class DentitionsFacade {

  loaded$ = this.store.select(DentitionsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(DentitionsSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}

  get(): void {
    this.store.dispatch(DentitionsActions.get());
  }
}
