import { HttpClient, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  OperationDto,
  OperationSettingsTagsDto,
  TagSequenceListItemDto,
  TagSequenceUpdateDto
} from '@ifhms/models/admin';
import { Observable } from 'rxjs';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class FeedlotTagSettingsService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  getList(operationId: string): Observable<TagSequenceListItemDto[]> {
    return this.http.get<TagSequenceListItemDto[]>(
      `${this.apiUrl}/Operations/${operationId}/Settings/Tag/TagSequenceList`
    );
  }

  update(operationId: string, tagSequenceUpdateDto: TagSequenceUpdateDto): Observable<TagSequenceListItemDto[]> {
    return this.http.put<TagSequenceListItemDto[]>(
      `${this.apiUrl}/Operations/${operationId}/Settings/Tag/TagSequenceList`, tagSequenceUpdateDto);
  }

  isNextTagNumberValid(operationId: string, code: string, nextTagNumber: number, tagColorId: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        code: code,
        nextTagNumber: nextTagNumber,
        tagColorId: tagColorId
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(
      `${this.apiUrl}/Operations/${operationId}/Settings/Tag/IsNextTagNumberValid`,
      options
    );
  }

  updateTagSettings(
    operationId: string,
    dto: OperationSettingsTagsDto
  ): Observable<OperationDto> {
    return this.http.put<OperationDto>(
      `${this.apiUrl}/Operations/${operationId}/Settings/Tag/TagFormat`,
      dto
    );
  }
}
