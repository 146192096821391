import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FeedlotRoleDto, FeedlotRoleUpdateDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class FeedlotRolePermissionsService {
  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient
  ) {
  }

  get(feedlotId: string, id: string): Observable<FeedlotRoleDto> {
    return this.http.get<FeedlotRoleDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Permissions/${id}`);
  }

  update(feedlotId: string, roleUpdate: FeedlotRoleUpdateDto): Observable<FeedlotRoleDto> {
    return this.http.put<FeedlotRoleDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Permissions`, roleUpdate);
  }
}
