import { Component, HostBinding } from '@angular/core';
import { FieldArrayType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-repeat-section-type',
  templateUrl: './repeat-section-type.component.html'
})
export class RepeatSectionTypeComponent extends FieldArrayType<FieldTypeConfig> {
  @HostBinding('class.sersi-repeat-section') hostClass = true;
}
