import { FormlyFieldConfig, FormlyExtension } from '@ngx-formly/core';
import { camelCaseToKebab } from '@common/angular/utils';

export const defaultFieldClassnameExtension: FormlyExtension = {
  onPopulate(field: FormlyFieldConfig): void {
    const className = field.className || 'formly-ctrl';
    const fieldClassPrefix = 'formly-field-';
    if (!field.key || className.includes(fieldClassPrefix)) return;
    const fieldKey = field.key.toString().replace('.', '-');
    const fieldKeyClass = camelCaseToKebab(fieldKey);
    field.className = `${className} ${fieldClassPrefix}${fieldKeyClass}`;
  }
};
