import { inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';

import { ErrorHandlingService } from '../services';
import { USE_DEFAULT_ERROR_HANDLER } from '../interfaces';
import { isCustomStatusStatus } from '../utils';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

  private readonly errorHandlingService = inject(ErrorHandlingService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<unknown>) => {
        if (!(event instanceof HttpResponse)) return event;

        const isCustomError = isCustomStatusStatus(event.status);

        // Throw a custom error
        if (isCustomError) {
          throw new HttpErrorResponse({
            error: event.body,
            status: event.status
          });
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorHandlingService.handleError(error, request.context.get(USE_DEFAULT_ERROR_HANDLER));
        return throwError(error);
      })
    );
  }

}
