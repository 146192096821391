import { Inject, Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TargetWeightListItemDto, TargetWeightUpdateDto } from '@ifhms/models/admin';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class FeedlotSettingsTargetWeightService {

  constructor(@Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string,
    private http: HttpClient) {}

  getTargetWeightsList(operationId: string): Observable<TargetWeightListItemDto[]> {
    return this.http.get<TargetWeightListItemDto[]>(`${this.apiUrl}/Operations/${operationId}/Settings/TargetWeights/List`);
  }

  updateTargetWeights(operationId: string, settingsUpdateDto: TargetWeightUpdateDto): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/Operations/${operationId}/Settings/TargetWeights`, settingsUpdateDto);
  }

}