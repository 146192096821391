import { Injectable } from '@angular/core';
import { getLoadedSelectItems } from '@common/angular/utils';
import { Store } from '@ngrx/store';

import { SersiSelectListItem } from '@sersi/angular/formly/core';

import { Observable } from 'rxjs';
import { PricingMethodsActions } from './pricing-methods.actions';
import * as reducer from './pricing-methods.reducer';
import { PricingMethodsSelectors } from './pricing-methods.selectors';

@Injectable()
export class PricingMethodsFacade {

  loaded$ = this.store.select(PricingMethodsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(PricingMethodsSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(PricingMethodsActions.get());
    return this.items$;
  }
}
