<p
  class="text-readonly-type readonly-type__wrapper-margin-offset"
  [ngClass]="props['className']"
  [class.disabled]="props['disabled']"
>
 <ng-container *ngIf="props['useExpressionVal']; else modelVal">{{ props['value'] }}</ng-container>
  <ng-template #modelVal>
    <!-- TODO: remove items$, text input should not have any connections with select   -->
    {{
      props['items$']
          ? 
          props['selectedItemLabel'] === 'CODE' ? 
          (formControl.value
              | selectListItemToCode: (props['items$'] | async)!) :
          (formControl.value
              | selectListItemToDescription: (props['items$'] | async)!)
          : formControl.value || '-'
    }}
  </ng-template>
</p>
