import { filter, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { ChangeDetectorRef, Directive, inject, OnInit, Renderer2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import { ListInputHelper, SersiSelectListItem, SersiSelectListItemViewMode, SersiSelectListUpdateEventType } from '@sersi/angular/formly/core';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models/fieldconfig';
import { DOCUMENT } from '@angular/common';

@UntilDestroy()
@Directive()
export abstract class AbstractSelectType extends FieldType<FieldTypeConfig> implements OnInit {

  allItems: SersiSelectListItem[];
  activeItems$: Observable<SersiSelectListItem[]>;

  private fieldConfigUpdate$ = new Subject<void>();
  items$: Observable<SersiSelectListItem[]>;

  protected cdr = inject(ChangeDetectorRef);
  protected renderer = inject(Renderer2);
  protected document = inject(DOCUMENT);

  protected attachCustomOverlayEvents?(): void;
  protected detachCustomOverlayEvents?(): void;

  protected get panelElement(): HTMLElement | null {
    return this.document.querySelector('.ng-dropdown-panel');
  }

  constructor() {
    super();
    this.customSearchFn = this.customSearchFn.bind(this);
  }

  ngOnInit(): void {
    this.setFilteredItems();
    this.handleFieldConfigChange();
  }

  customSearchFn(term: string, item: SersiSelectListItem): boolean {
    const searchTerm = term.toLowerCase();

    switch (this.props['optionsLabel']) {
      case SersiSelectListItemViewMode.CODE:
        return this.isSearchCodeMatch(searchTerm, item);
      case SersiSelectListItemViewMode.CODE_DESCRIPTION:
        return this.isSearchCodeMatch(searchTerm, item) || this.isSearchDescriptionMatch(searchTerm, item);
      case SersiSelectListItemViewMode.DESCRIPTION:
        return this.isSearchDescriptionMatch(searchTerm, item);
      default:
        return false;
    }
  }

  onDropdownOpen(): void {
    this.renderer.addClass(this.document.body, 'dropdown-panel-open');
    this.handleCustomClassInDropdown();
    this.attachCustomOverlayEvents?.();
  }

  onDropdownClose(): void {
    this.renderer.removeClass(this.document.body, 'dropdown-panel-open');
    this.detachCustomOverlayEvents?.();
  }

  private handleCustomClassInDropdown(): void {
    const dropdownClassName = this.props['dropdownClassName'];
    if (!dropdownClassName) return;
    setTimeout(() => this.renderer.addClass(this.panelElement, dropdownClassName));
  }

  private setFilteredItems(): void {
    const excludeItems$: Observable<string[]> = this.props['excludeItems$'] || of([]);
    const filteredItems$ = ListInputHelper.getSelectListItemsAsync(
      this.formControl,
      excludeItems$,
      this.props['resetInvalidSelections'],
      this.props['items'],
      this.props['items$'],
      items => this.onOptionsLoaded(items)
    );

    this.items$ = filteredItems$.pipe(takeUntil(this.fieldConfigUpdate$));
    this.setActiveItems();
  }

  private onOptionsLoaded(allItems: SersiSelectListItem[]): void {
    this.allItems = allItems;
    this.autoSelectSingleOption(allItems)
    this.props['optionsLoaded']?.(allItems);
  }

  private autoSelectSingleOption(allItems: SersiSelectListItem[]): void {
    const activeItems = allItems.filter(item => !!item.isActive);
    const isRequiredCtrl = this.props?.required;
    const hasValue = !!this.formControl.value;
    if (!this.props['autoSelectSingleRequiredOption']) return;
  
    if (isRequiredCtrl && !hasValue && activeItems.length === 1) {
      this.formControl.setValue(activeItems[0].id);
    }
  }

  private handleFieldConfigChange(): void {
    this.options.fieldChanges?.pipe(
      filter((event: FormlyValueChangeEvent) => {
        const isListUpdate = event.type === SersiSelectListUpdateEventType.OPTIONS_LIST_UPDATE;
        const isSelf = this.field.key === event.field?.key;
        return isSelf && isListUpdate;
      }),
      untilDestroyed(this)
    ).subscribe(() => {
      this.fieldConfigUpdate$.next();
      this.setFilteredItems();
      this.cdr.markForCheck()
    })
  }

  protected setActiveItems(): void {
    if (this.props['showAllItems']) {
      this.activeItems$ = this.items$
    } else {
      this.activeItems$ = this.items$.pipe(
        // false is added intentionally, to avoid filtering of items without isActive flag
        filter(items => items && (this.props['resetOnEmptyList'] || items.length > 0)),
        map(items => items.filter(item => item?.isActive !== false))
      )
    }
  }

  private isSearchCodeMatch(searchTerm: string, item: SersiSelectListItem): boolean {
    const itemCode = item.code || '';
    return itemCode.toLowerCase().includes(searchTerm);
  }

  private isSearchDescriptionMatch(searchTerm: string, item: SersiSelectListItem): boolean {
    const itemDesc = item.description || '';
    return itemDesc.toLowerCase().includes(searchTerm);
  }

}
