import { Injectable } from '@angular/core';

import { AuthFacade } from '@common/angular/auth';
import { UserProfileDto } from '@common/angular/core/models';
import { AuthAppLevelRoles } from '@ifhms/models/shared';

import { Store } from '@ngrx/store';
import { filter, map, Observable, withLatestFrom } from 'rxjs';
import { CommonUserProfileActions } from './common-user-profile.actions';
import { State } from './common-user-profile.reducer';
import { CommonUserProfileSelectors } from './common-user-profile.selectors';

@Injectable({ providedIn: 'root' })
export class CommonUserProfileFacade {

  state$: Observable<State> = this.store.select(CommonUserProfileSelectors.selectState);
  userId$: Observable<string | null> = this.store.select(CommonUserProfileSelectors.selectUserId);
  language$: Observable<string> = this.store.select(CommonUserProfileSelectors.selectLanguage);
  langCode$: Observable<string> = this.store.select(CommonUserProfileSelectors.selectLanguageCode);
  userProfile$: Observable<UserProfileDto | null> = this.store.select(CommonUserProfileSelectors.selectUserProfile);
  isEnterprise$: Observable<boolean> = this.authFacade.userRoles$.pipe(
    map((roles: string[] | null) => !!roles?.includes(AuthAppLevelRoles.Enterprise))
  );
  hasReleaseNotes$: Observable<boolean> = this.isEnterprise$.pipe(
    withLatestFrom(this.userProfile$),
    filter(([, userProfile]) => !!userProfile),
    map(([isEnterprise, userProfile]) => isEnterprise && this.hasReleaseNotesAccess(userProfile!))
  );

  displayName$: Observable<string> = this.userProfile$.pipe(
    map((userProfile: UserProfileDto | null) => `${userProfile?.firstName} ${userProfile?.lastName}`)
  );

  profileComplete$: Observable<boolean> = this.userProfile$.pipe(
    map((userProfile: UserProfileDto | null) => userProfile?.isComplete || false)
  );
  displayNameAbbreviation$: Observable<string> = this.userProfile$.pipe(
    map((userProfile: UserProfileDto | null) => `${userProfile?.firstName.charAt(0).toUpperCase()}${userProfile?.lastName.charAt(0).toUpperCase()}`)
  );

  constructor(
    private readonly store: Store<State>,
    private readonly authFacade: AuthFacade
  ) {
  }

  get(userId: string): void {
    this.store.dispatch(CommonUserProfileActions.get({ userId }));
  }

  updateLangSettings(langCode: string): void {
    this.store.dispatch(CommonUserProfileActions.updateLangSettings({ langCode }));
  }

  private hasReleaseNotesAccess(userProfile: UserProfileDto): boolean {
    const whiteListedEmails = ['josh.lynn@telusagcg.com', 'anu.john@telusagcg.com'];
    return whiteListedEmails.includes(userProfile.email);
  }
}