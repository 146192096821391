import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-password',
  templateUrl: './password-type.component.html',
  styleUrls: ['./password-type.component.scss']
})
export class PasswordTypeComponent extends FieldType<FieldTypeConfig> {

  override defaultOptions = {
    props: {
      showFeedback: true
    }
  };
}
