import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'sersi-default-horizontal-form-field-wrapper',
  templateUrl: './default-horizontal-form-field-wrapper.component.html',
  styleUrls: ['./default-horizontal-form-field-wrapper.component.scss']
})
export class DefaultHorizontalFormFieldWrapperComponent extends FieldWrapper {
  constructor() {
    super();
  }
}
