import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TreatmentProtocolSettingsDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class FeedlotTreatmentProtocolSettingsService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  get(operationId: string, treatmentProtocolId: string | null): Observable<TreatmentProtocolSettingsDto> {
    let url = `${this.apiUrl}/Operation/${operationId}/Settings/TreatmentProtocol`;
    if (treatmentProtocolId) {
      url += `/${treatmentProtocolId}`;
    }
    return this.http.get<TreatmentProtocolSettingsDto>(url);
  }

  update(operationId: string, treatmentProtocolDto: TreatmentProtocolSettingsDto): Observable<TreatmentProtocolSettingsDto> {
    return this.http.put<TreatmentProtocolSettingsDto>(
      `${this.apiUrl}/Operation/${operationId}/Settings/TreatmentProtocol`, treatmentProtocolDto);
  }

}
