import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { UrlRouterService } from './services';
import { UrlRouterCustomSerializer } from './models/url-router-custom-serializer';
import {
  UrlRouterEffects,
  RouterFacade,
  urlRouteHistoryReducer,
  UrlRouteHistoryFacade,
  UrlRouteHistoryEffects, ROUTES_HISTORY_FEATURE_NAME
} from './+state';

@NgModule({
  imports: [
    StoreModule.forFeature('router', routerReducer),
    StoreModule.forFeature(ROUTES_HISTORY_FEATURE_NAME, urlRouteHistoryReducer),
    EffectsModule.forFeature([UrlRouterEffects, UrlRouteHistoryEffects]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      navigationActionTiming: NavigationActionTiming.PostActivation
    })
  ],
  providers: [
    RouterFacade,
    UrlRouteHistoryFacade,
    UrlRouterEffects,
    UrlRouteHistoryEffects,
    UrlRouterService,
    {
      provide: RouterStateSerializer,
      useClass: UrlRouterCustomSerializer
    }
  ]
})
export class CommonAngularUrlRouterModule {
  constructor(
    routerFacade: RouterFacade,
    @Optional() @SkipSelf() parentModule: CommonAngularUrlRouterModule
  ) {
    if (parentModule) {
      throw new Error('SharedRouterModule is already loaded. Import only in AppModule');
    }
    routerFacade.init();
  }
}
