import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { isFunction } from 'lodash-es';

import { ComponentInitializedState } from '@sersi/angular/ui/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GridColumnDef, GridOptions, GridRendererCellType } from '@sersi/angular/formly/core';

@UntilDestroy()
@ComponentInitializedState()
@Component({
  selector: 'sersi-grid',
  templateUrl: './grid-type.component.html',
  styleUrls: ['./grid-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridTypeComponent extends FieldArrayType implements OnInit {
  readonly translateScope = 'work-orders-shared.formly.grid-formly';

  @HostBinding('class.grid-type-component') hostClass = true;
  @ViewChild('gridTable') private gridTableContainer: ElementRef<HTMLInputElement>;

  colDef: GridColumnDef[];
  GridRendererCellType = GridRendererCellType;
  isInitialized: boolean;

  protected get gridOptions(): GridOptions {
    return this.props['gridOptions'] || {};
  }

  private get initialModel(): Record<string, any> {
    const modelProp = this.props['initialModel'] || {};
    const initialModel = isFunction(modelProp) ? modelProp() : modelProp;
    return {
      ...structuredClone(initialModel)
    };
  }

  constructor(private renderer: Renderer2) {
    super();
  }

  ngOnInit(): void {
    // set grid options
    this.setGridOptions();
    this.isInitialized = true;
  }

  addRow(): void {
    const model = this.initialModel;
    this.add(undefined, model);
    this.scrollIntoView();
  }

  deleteRow(rowIndex: number): void {
    this.remove(rowIndex);
  }

  private setGridOptions(): void {
    this.setColumDef();
    this.setChildAddRowAction();
    this.setChildDeleteRowAction();
  }

  private setChildAddRowAction(): void {
    this.gridOptions.addRow.pipe(untilDestroyed(this)).subscribe(() => this.addRow());
  }

  private setChildDeleteRowAction(): void {
    this.gridOptions.deleteRow
      .pipe(untilDestroyed(this))
      .subscribe((rowIndex: number) => {
        this.deleteRow(rowIndex);
      });
  }

  private setColumDef(): void {
    this.colDef = this.gridOptions.columns.filter((col: GridColumnDef) => !col.hideCell);
  }

  private scrollIntoView(): void {
    const element = this.gridTableContainer?.nativeElement;
    // wait for the new row to render
    setTimeout(() => {
      // ignore scroll on auto height containers
      if (element.scrollHeight) {
        this.renderer.setProperty(element, 'scrollTop', element.scrollHeight);
      }
    }, 10);
  }

}
