import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-zip-code',
  templateUrl: './zip-code-type.component.html',
  styleUrls: ['./zip-code-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZipCodeTypeComponent extends FieldType<FieldTypeConfig> {
  override defaultOptions = {
    templateOptions: {
      label: '',
      placeholder: '',
      required: false,
      disabled: false,
      mask: '99999-9999',
      autoClear: false
    }
  };
}
