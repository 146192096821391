import { Component } from '@angular/core';
import { LayoutService } from '@ifhms/admin/web/domain/state/layout';

@Component({
  selector: 'ifhms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  constructor(private layoutService: LayoutService) {
    this.layoutService.init();
  }

}
