import { Pipe, PipeTransform } from '@angular/core';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

@Pipe({
  name: 'selectListItemToDescription'
})
export class SelectListItemToDescriptionPipe implements PipeTransform {
  transform(id: string | string[], list: SersiSelectListItem[]): string {
    if (!list) {
      return '';
    }

    const targetIds = Array.isArray(id) ? id : [id];
    const targetDescriptions = list
      //@ts-ignore
      .filter((item) => targetIds.includes(item.id))
      .map((item) => item.description);
    return targetDescriptions.length ? targetDescriptions.join(', ') : '-';
  }
}
