import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SersiSelectListItemDto } from '@ifhms/common/angular/data-access/report-api';
import {
  EnterpriseRoleCreateDto,
  EnterpriseRoleDto,
  EnterpriseRoleListRequestDto,
  EnterpriseRoleListResultDto,
  EnterpriseUserListItemDto
} from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class EnterpriseRoleService {
  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient
  ) {
  }

  getList(): Observable<SersiSelectListItemDto[]> {
    return this.http.get<SersiSelectListItemDto[]>(`${this.apiUrl}/Enterprise/Roles/SelectList`);
  }

  getTable(request: EnterpriseRoleListRequestDto): Observable<EnterpriseRoleListResultDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...request
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<EnterpriseRoleListResultDto>(`${this.apiUrl}/Enterprise/Roles/Table`, options);
  }

  get(id: string): Observable<EnterpriseRoleDto> {
    return this.http.get<EnterpriseRoleDto>(`${this.apiUrl}/Enterprise/Roles/${id}`);
  }

  getRoleUsers(roleId: string): Observable<EnterpriseUserListItemDto[]> {
    return this.http.get<EnterpriseUserListItemDto[]>(`${this.apiUrl}/Enterprise/Roles/Users/${roleId}`);
  }

  create(roleCreate: EnterpriseRoleCreateDto): Observable<EnterpriseRoleDto> {
    return this.http.post<EnterpriseRoleDto>(`${this.apiUrl}/Enterprise/Roles`, roleCreate);
  }

  update(roleUpdate: EnterpriseRoleDto): Observable<EnterpriseRoleDto> {
    console.log('update')
    return this.http.put<EnterpriseRoleDto>(`${this.apiUrl}/Enterprise/Roles`, roleUpdate);
  }

  isNameUnique(name: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { name }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Enterprise/Roles/IsNameUnique`, options);
  }
}
