import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { BreedsActions } from './breeds.actions';
import * as reducer from './breeds.reducer';
import { BreedsSelectors } from './breeds.selectors';

@Injectable()
export class BreedsFacade {

  loaded$ = this.store.select(BreedsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(BreedsSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}

  get(): void {
    this.store.dispatch(BreedsActions.get());
  }
}
