import * as reducer from './layout.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_NAME } from './index';

const selectState = createFeatureSelector<reducer.State>(FEATURE_NAME);

const selectMenuType = createSelector(selectState, reducer.getMenuType);

export const LayoutSelectors = {
  selectMenuType
};
