import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProductCreateDto, ProductDto, ProductListRequestDto, ProductListResultDto, ProductToggleActiveRequestDto, ProductUpdateDto } from '@ifhms/models/admin';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { map, Observable } from 'rxjs';

@Injectable()
export class EnterpriseProductManagementService {
  constructor(private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string) {
  }

  get(productId: string): Observable<ProductDto> {
    return this.http.get<ProductDto>(`${this.apiUrl}/Products/${productId}`).pipe(
      map((x: ProductDto) => {

        for (const route of x.routes) {
          for (const detail of route.productRouteDetails) {
            detail.effectiveFrom = new Date(detail.effectiveFrom);
            if (detail.effectiveTo) { // Nullish coalescing assignment operator did not work here.
              detail.effectiveTo = new Date(detail.effectiveTo as unknown as Date);
            }
          }
        }
        return x;
      })
    );
  }

  create(productDto: ProductCreateDto): Observable<ProductDto> {
    return this.http.post<ProductDto>(`${this.apiUrl}/Products`, productDto );
  }

  update(productDto: ProductUpdateDto): Observable<ProductDto> {
    return this.http.put<ProductDto>(`${this.apiUrl}/Products`, productDto);
  }

  getSelectList(): Observable<SelectListItemWithTranslationDto[]> {
    return this.http.get<SelectListItemWithTranslationDto[]>(`${this.apiUrl}/Products/SelectList`);
  }

  getTable(request: ProductListRequestDto): Observable<ProductListResultDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...request
      }
    };
    
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<ProductListResultDto>(`${this.apiUrl}/Products/Page`, options);
  }

  toggleActiveStatus(toggleActiveRequest: ProductToggleActiveRequestDto): Observable<ProductListResultDto> {
    return this.http.put<ProductListResultDto>(`${this.apiUrl}/Products/ToggleActiveStatus`, toggleActiveRequest);
  }

  isCodeUnique(code: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        code
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Products/IsCodeUnique`, options);
  }
}