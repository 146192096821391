import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ArrivalProtocolImportValidationResultDto,
  TreatmentProtocolImportRecordDto,
  TreatmentProtocolImportValidationResultDto
} from '@ifhms/models/admin';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable({
  providedIn: 'root'
})
export class ImportTreatmentProtocolService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  validateImportFile(
    operationId: string,
    file: File
  ): Observable<TreatmentProtocolImportValidationResultDto> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<TreatmentProtocolImportValidationResultDto>(
      `${this.apiUrl}/Operation/${operationId}/ProtocolImport/Validate`,
      formData
    );
  }

  importTreatmentProtocols(
    operationId: string,
    treatmentProtocols: TreatmentProtocolImportRecordDto[]
  ): Observable<TreatmentProtocolImportValidationResultDto> {
    return this.http.post<TreatmentProtocolImportValidationResultDto>(
      `${this.apiUrl}/Operation/${operationId}/ProtocolImport/Process`,
      treatmentProtocols
    );
  }

  exportSettings(operationId: string): Observable<any> {
    const options: object = {
      responseType: 'arraybuffer'
    };
    return this.http.get<Observable<any>>(
      `${this.apiUrl}/Operation/${operationId}/ProtocolImport/Overrides`,
      options
    );
  }

  exportArrivalSettings(operationId: string): Observable<any> {
    const options: object = {
      responseType: 'arraybuffer'
    };
    return this.http.get<Observable<any>>(
      `${this.apiUrl}/Operation/${operationId}/ProtocolImport/ArrivalProtocols`,
      options
    );
  }

  uploadArrivalImportFile(
    operationId: string,
    file: File
  ): Observable<ArrivalProtocolImportValidationResultDto> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<ArrivalProtocolImportValidationResultDto>(
      `${this.apiUrl}/Operation/${operationId}/ProtocolImport/UpdateArrivalProtocols`,
      formData
    );
  }
}
