import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'sersi-bulleted-list',
  templateUrl: './bulleted-list-type.component.html',
  styleUrls: ['./bulleted-list-type.component.scss']
})
export class BulletedListTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {

  override defaultOptions = {
    props: {
      list: [],
      list$: of([])
    }
  };

  list$: Observable<string[]>;

  ngOnInit(): void {
    this.props['list$'].subscribe((x: any) => console.log('list$', x))
    this.list$ = this.props['list$'] || of(this.props['list']) as Observable<string[]>;
  }

}
