import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import {
  TreatmentProtocolDefinitionCreateDto,
  TreatmentProtocolDefinitionDto,
  TreatmentProtocolDefinitionUpdateDto,
  TreatmentProtocolListRequestDto,
  TreatmentProtocolListResultDto
} from '@ifhms/models/admin';
import { Observable } from 'rxjs';
import { SelectListItemDto } from '@common/angular/core/models';

@Injectable()
export class EnterpriseTreatmentProtocolManagementService {
  constructor(private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string) {
  }

  getTable(request: TreatmentProtocolListRequestDto): Observable<TreatmentProtocolListResultDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...request
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<TreatmentProtocolListResultDto>(`${this.apiUrl}/TreatmentProtocols/Page`, options);
  }

  getDefinition(txGroupId: string): Observable<TreatmentProtocolDefinitionDto> {
    return this.http.get<TreatmentProtocolDefinitionDto>(`${this.apiUrl}/TreatmentProtocols/${txGroupId}/Definition`);
  }

  getSelectList(levelId: string): Observable<SelectListItemDto[]> {
    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/TreatmentProtocols/SelectList/${levelId}`);
  }

  updateDefinition(updateDto: TreatmentProtocolDefinitionUpdateDto): Observable<TreatmentProtocolDefinitionDto> {
    return this.http.put<TreatmentProtocolDefinitionDto>(`${this.apiUrl}/TreatmentProtocols/Definition`, updateDto);
  }

  create(createDto: TreatmentProtocolDefinitionCreateDto): Observable<TreatmentProtocolDefinitionDto> {
    return this.http.post<TreatmentProtocolDefinitionDto>(`${this.apiUrl}/TreatmentProtocols/Definition`, createDto);
  }
}