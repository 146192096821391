<span class="p-fluid">
  <textarea
    type="text"
    [ngClass]="props['inputClassNames']"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [attr.data-testid]="model.value"
    [attr.data-label-ele]="to.label"
    [attr.data-ele]="key"
    [rows]="props.rows"
    [id]="id + '_input'"
    pInputTextarea >
  </textarea>
</span>
