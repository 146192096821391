import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  FeedlotRoleCreateDto,
  FeedlotRoleDto,
  FeedlotRoleListRequestDto,
  FeedlotRoleListResultDto,
  FeedlotRoleUpdateDto,
  FeedlotUserListItemDto,
  FeedlotUserRoleListItemDto
} from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class FeedlotRoleService {
  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient
  ) {
  }

  getList(feedlotId: string): Observable<FeedlotUserRoleListItemDto[]> {
    return this.http.get<FeedlotUserRoleListItemDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles/SelectList`);
  }

  getTable(feedlotId: string, request: FeedlotRoleListRequestDto): Observable<FeedlotRoleListResultDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...request
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<FeedlotRoleListResultDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles/Table`, options);
  }

  get(feedlotId: string, id: string): Observable<FeedlotRoleDto> {
    return this.http.get<FeedlotRoleDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles/${id}`);
  }

  getRoleUsers(feedlotId: string, roleId: string): Observable<FeedlotUserListItemDto[]> {
    return this.http.get<FeedlotUserListItemDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles/Users/${roleId}`);
  }

  create(feedlotId: string, roleCreate: FeedlotRoleCreateDto): Observable<FeedlotRoleDto> {
    return this.http.post<FeedlotRoleDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles`, roleCreate);
  }

  update(feedlotId: string, roleUpdate: FeedlotRoleUpdateDto): Observable<FeedlotRoleDto> {
    return this.http.put<FeedlotRoleDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles`, roleUpdate);
  }

  isNameUnique(feedlotId: string, name: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { name }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles/IsNameUnique`, options);
  }
}
