import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import * as reducer from './labour-charge-types.reducer';
import { LabourChargeTypesSelectors } from './labour-charge-types.selectors';

@Injectable()
export class LabourChargeTypesFacade {
  loaded$ = this.store.select(LabourChargeTypesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(LabourChargeTypesSelectors.selectItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}
}

