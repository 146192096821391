import { Pipe, PipeTransform } from '@angular/core';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable, map, take } from 'rxjs';

@Pipe({
  name: 'selectListItemToCode'
})
export class SelectListItemToCodePipe implements PipeTransform {

  transform(id: string, list: SersiSelectListItem[]): string {
    if (!list?.length) return '-'; 
    const item = list.find(x => x.id === id);
    return item?.code || '-';
  }

  transformObservable(id: string, list: Observable<SersiSelectListItem[]>): Observable<string> {
    return list.pipe(
      map(list => this.transform(id, list)),
      take(1)
    );
  }

  transformToSersi(id: string, list: Observable<SersiSelectListItem[]>): Observable<SersiSelectListItem | null> {
    return list.pipe(
      map(list => list.find(x => x.id === id) ?? null),
      take(1)
    );
  }
}