import { Component, Input } from '@angular/core';

import { SersiFormlyFieldProps } from '../../interfaces';

@Component({
  selector: 'sersi-form-text-label',
  templateUrl: './form-text-label.component.html'
})
export class FormTextLabelComponent {
  @Input() props: SersiFormlyFieldProps;
}
