import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  RefDataCreateDto,
  RefDataDto,
  RefDataToggleActiveDto,
  RefDataUpdateDto,
  ReferenceDataType
} from '@ifhms/models/admin';
import { AppContextDto, ContextLevel } from '@ifhms/models/shared';
import { Observable } from 'rxjs';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ReferenceDataManagementService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  get(type: ReferenceDataType, appContext: AppContextDto): Observable<RefDataDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        contextLevel: appContext.contextLevel,
        levelId: appContext.levelId || '',
        type
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    const endpoint = this.getApiFromLevel(appContext);

    return this.http.get<RefDataDto[]>(`${this.apiUrl}/${endpoint}/List`, options);
  }

  toggleActiveStatus(type: ReferenceDataType, appContext: AppContextDto, id: string): Observable<RefDataDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        contextLevel: appContext.contextLevel,
        levelId: appContext.levelId || ''
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    const refDataToggleActiveDto: RefDataToggleActiveDto = { id, type };

    const endpoint = this.getApiFromLevel(appContext);

    return this.http.put<RefDataDto[]>(`${this.apiUrl}/${endpoint}/ToggleActiveStatus`, refDataToggleActiveDto, options);
  }

  create(refDataCreateDto: RefDataCreateDto, type: ReferenceDataType, appContext: AppContextDto): Observable<RefDataDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        contextLevel: appContext.contextLevel,
        levelId: appContext.levelId || '',
        type
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    const endpoint = this.getApiFromLevel(appContext);

    return this.http.post<RefDataDto[]>(`${this.apiUrl}/${endpoint}`, refDataCreateDto, options);
  }

  update(refDataUpdateDto: RefDataUpdateDto, type: ReferenceDataType, appContext: AppContextDto): Observable<RefDataDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        contextLevel: appContext.contextLevel,
        levelId: appContext.levelId || '',
        type
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    const endpoint = this.getApiFromLevel(appContext);

    return this.http.put<RefDataDto[]>(`${this.apiUrl}/${endpoint}`, refDataUpdateDto, options);
  }

  isCodeUnique(code: string, type: ReferenceDataType, appContext: AppContextDto): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        contextLevel: appContext.contextLevel,
        levelId: appContext.levelId || '',
        type,
        code
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    const endpoint = this.getApiFromLevel(appContext);

    return this.http.get<boolean>(`${this.apiUrl}/${endpoint}/IsCodeUnique`, options);
  }

  private getApiFromLevel(appContext: AppContextDto): string {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return 'EnterpriseReferenceData';
      case ContextLevel.Company:
        return `CompanyReferenceData/${appContext.levelId}`;
      case ContextLevel.Operation:
      default:
        return `OperationReferenceData/${appContext.levelId}`;
    }
  }
}