import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-info-icon',
  templateUrl: './info-icon-type.component.html',
  styleUrls: ['./info-icon-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoIconTypeComponent extends FieldType<FieldTypeConfig> {

  override defaultOptions = {
    props: {
      icon: 'info',
      message: ''
    }
  };

}

