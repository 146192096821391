import { createAction, props } from '@ngrx/store';
import { MenuType } from '../models/menu-type.enum';

const NAMESPACE = '[LAYOUT]';

const setMenu = createAction(
  `${NAMESPACE} Set Menu`,
  props<{ menuType: MenuType }>()
);

export const LayoutActions = {
  setMenu
};
