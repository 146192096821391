import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { MAX_NUMBER_INPUT } from '@sersi/angular/formly/core';
import { debounceTime, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InputNumber } from 'primeng/inputnumber/inputnumber';

@UntilDestroy()
@Component({
  selector: 'sersi-number-input',
  templateUrl: './number-input-type.component.html',
  styleUrls: ['./number-input-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberInputTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {

  @ViewChild('inputNumber') numberInput: InputNumber | null = null;

  readonly maxInputLength = 16;

  private refocus$ = new Subject<void>();

  override defaultOptions = {
    props: {
      label: '',
      placeholder: '',
      required: false,
      disabled: false,
      useGrouping: false,
      maxFractionDigits: 4,
      minFractionDigits: 0,
      showButtons: false,
      min: -MAX_NUMBER_INPUT,
      max: MAX_NUMBER_INPUT,
      allowInvalidInput: true,
      updateOnChange: false
    }
  };

  ngOnInit(): void {
    this.refocus$
      .pipe(
        debounceTime(200),
        untilDestroyed(this)
      )
      .subscribe(() => this.refocusInput())
  }

  onInput(): void{
    // https://github.com/primefaces/primeng/issues/12683
    if (!this.formControl.disabled && this.props['updateOnChange']) {
      this.refocus$.next();
    }
  }

  private refocusInput(): void {
    const input = this.numberInput?.input.nativeElement;
    input?.blur();
    input?.focus();
  }
}

