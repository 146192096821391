import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as reducer from './post-mortem.reducer';
import { Observable } from 'rxjs';
import { FeedlotPostMortemSettingsDto } from '@ifhms/models/admin';
import { PostMortemSettingsActions } from './post-mortem.actions';
import { PostMortemSettingsSelectors } from './post-mortem.selectors';

@Injectable()
export class PostMortemSettingsFacade {

  loading$: Observable<boolean> = this.store.select(PostMortemSettingsSelectors.selectLoading);
  loaded$: Observable<boolean> = this.store.select(PostMortemSettingsSelectors.selectLoaded);
  updating$: Observable<boolean> = this.store.select(PostMortemSettingsSelectors.selectUpdating);
  updated$: Observable<boolean> = this.store.select(PostMortemSettingsSelectors.selectUpdated);
  postMortemSettings$: Observable<FeedlotPostMortemSettingsDto | null> = this.store.select(PostMortemSettingsSelectors.selectPostMortemSettings);

  constructor(private store: Store<reducer.State>) { }

  getPostMortemSettings(): void {
    this.store.dispatch(PostMortemSettingsActions.getPostMortemSettings());
  }

  updatePostMortemSettings(settings: FeedlotPostMortemSettingsDto): void {
    this.store.dispatch(PostMortemSettingsActions.updatePostMortemSettings({ settings }));
  }

  resetPostMortemSettings(): void {
    this.store.dispatch(PostMortemSettingsActions.resetPostMortemSettings());
  }
}
