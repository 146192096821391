import { DestroyRef, inject } from '@angular/core';
import { HashMap, ProviderScope } from '@jsverse/transloco';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateService } from './translate.service';
import { TRANSLOCO_NAV_SCOPE_TOKEN } from '../interfaces';

export abstract class AbstractTranslationService {

  protected abstract translationScope: string;
  protected abstract translationNamespace: string;
  protected translationReady$ = new ReplaySubject<void>(1);

  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private translateService = inject<TranslateService>(TranslateService);
  private providerScope = inject<ProviderScope>(TRANSLOCO_NAV_SCOPE_TOKEN);
  private translationSubscription: Subscription;

  protected getTranslation(key: string, params?: HashMap<string | number>): string {
    return this.translateService.getTranslation(`${this.translationScope}.${this.translationNamespace}`, key, params);
  }

  protected getTranslation$(key: string, params?: HashMap): Observable<string> {
    return this.translateService.getTranslation$(`${this.translationScope}.${this.translationNamespace}`, key, params);
  }

  protected getTranslationObject(objectKey: string): HashMap {
    return this.translateService.translateObject(`${this.translationScope}.${this.translationNamespace}`, objectKey);
  }

  protected loadTranslations(): void {
    if (this.translationSubscription) return;
    this.translationSubscription = this.translateService
      .lazyLoadScope(this.providerScope)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.translationReady$.next());
  }

}
