import { Injectable } from '@angular/core';
import { ReferenceDataService } from '@ifhms/common/angular/data-access/admin-api';
import { ReferenceDataType } from '@ifhms/models/admin';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';
import { FeedingProgramsActions } from './feeding-programs.actions';
import { ContextService } from '@ifhms/common/angular/utils';

@Injectable()
export class FeedingProgramsEffects {

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedingProgramsActions.get),
      withLatestFrom(this.contextService.appContext$),
      exhaustMap(([, appContext]) => {
        return this.referenceDataService.getSelectListByType(
          appContext,
          ReferenceDataType.FeedingPlan
        ).pipe(
          map((items: SelectListItemWithTranslationDto[]) => {
            if (!items) FeedingProgramsActions.error();
            return FeedingProgramsActions.getSuccess({ items });
          }),
          catchError(() => of(FeedingProgramsActions.error()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private contextService: ContextService,
    private referenceDataService: ReferenceDataService
  ) {
  }
}
