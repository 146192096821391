export enum BillGenerationListSortBy {
  LotNumberDescending = 1,
  LotNumberAscending = 2,
  LastBillingFromDescending = 3,
  LastBillingFromAscending = 4,
  LastBillingToDescending = 5,
  LastBillingToAscending = 6,
  InvoiceDateDescending = 7,
  InvoiceDateAscending = 8
}
