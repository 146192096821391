<ng-container *ngIf="isInitialized">
  <!-- HEADER -->
  <div class="grid-header">
    <div
      *ngFor="let col of colDef; let i = index;"
      [style.width]="col.width"
      [style.min-width.px]="col.minWidth"
      [ngClass]="col.className || ''"
      class="flex-none"
    >
      <ng-container [ngSwitch]="col.cellRendererParams?.type">
        <sersi-grid-add-button-renderer
          *ngSwitchCase="[GridRendererCellType.Add, GridRendererCellType.ADD_DELETE] | ngSwitchMultiCase : col.cellRendererParams?.type"
          [disabled]="formControl.disabled"
          [hotKey]="col.hotKey"
          (addNewRow)="addRow()"
        />
        <ng-container *ngSwitchDefault>
          @if (col.headerName$) {
            <span class="column-name">{{ col.headerName$ | async }}</span>
          } @else {
            <span class="column-name">{{ col.headerName }}</span>
          }
          <span *ngIf="col.required" class="required-indicator ml-1">*</span>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <!-- MAIN ROWS -->
  <div class="grid-table" #gridTable>
    <ng-container *ngFor="let field of field.fieldGroup">
      <formly-field [field]="field" />
    </ng-container>
    <div
      *ngIf="!field.fieldGroup?.length"
      class="text-center grid-no-data-message"
    >
      {{ props['noDataMessage$'] | async }}
    </div>
  </div>

  <!-- FOOTER -->
  <div *ngIf="props['isFooterAddButtonEnabled']" class="footer-actions">
    <button
      pButton
      type="button"
      class="p-button-text px-1 py-2 font-semibold"
      data-ele="grid-type-add-footer-btn"
      (click)="addRow()"
    >
      <i class="material-icons-outlined mr-1">add_circle_outline</i>
      {{ (props['footerAddButtonLabel$'] | async) }}
    </button>
  </div>
</ng-container>
