import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AnimalImportRequestDto, AnimalImportValidationResultDto } from '@ifhms/models/admin';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ImportAnimalsUploadService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  parseImportFile(
    operationId: string,
    file: File
  ): Observable<AnimalImportValidationResultDto> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<AnimalImportValidationResultDto>(
      `${this.apiUrl}/AnimalsImport/Feedlot/${operationId}/Validate`,
      formData
    );
  }

  importAnimals(
    operationId: string,
    importRequest: AnimalImportRequestDto
  ): Observable<string> {
    return this.http.post<string>(
      `${this.apiUrl}/AnimalsImport/Feedlot/${operationId}/Process`,
      importRequest
    );
  }
}
