import { Injectable } from '@angular/core';

import { AUTH_DEFAULT_CONFIG } from '../interfaces';

@Injectable()
export class AuthErrorHandlingService {
  private readonly authErrorRetryCountKey = 'auth-error-retry-count';
  private readonly maxAuthRetries = 3;

  handleAuthError(nameSpace: string): void {
    const retryCount = this.getAuthRetryCount(nameSpace);

    if (retryCount > this.maxAuthRetries) {
      this.clearAuthErrorState(nameSpace);
      this.clearLocalCache();
      location.pathname = AUTH_DEFAULT_CONFIG.loginRoutePath;
      return;
    }

    // Clear all local cache before next retry
    if (retryCount > 1) {
      this.clearLocalCache();
    }

    this.setAuthErrorRetryCount(nameSpace, retryCount);
    location.reload();
  }

  clearAuthErrorState(nameSpace: string): void {
    const storageKey = this.getStorageKey(nameSpace);
    sessionStorage.removeItem(storageKey);
  }

  private setAuthErrorRetryCount(nameSpace: string, currentRetryCount: number): void {
    const storageKey = this.getStorageKey(nameSpace);
    currentRetryCount++;
    sessionStorage.setItem(storageKey, currentRetryCount.toString());
  }

  private getAuthRetryCount(nameSpace: string): number {
    const storageKey = this.getStorageKey(nameSpace);
    const storedRetryCount = sessionStorage.getItem(storageKey);
    return storedRetryCount ? +storedRetryCount : 1;
  }

  private getStorageKey(nameSpace: string): string {
    return `${this.authErrorRetryCountKey}-${nameSpace}`;
  }

  private clearLocalCache(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

}
