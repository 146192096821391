import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { RouterState } from '../../models';
import { urlRouterActions } from './url-router.actions';
import { routerSelectors } from './url-router.selectors';
import { NavigationExtras, Router } from '@angular/router';

@Injectable()
export class RouterFacade {

  currentRoute$ = this.store.select(routerSelectors.selectUrl);

  selectRoutesState$ = this.store.select(routerSelectors.selectRouteState);

  constructor(
    private store: Store<RouterState>,
    private router: Router
  ) {}

  init(): void {
    this.store.dispatch(urlRouterActions.init());
  }

  navigate(path: any[], extras?: NavigationExtras, query?: Record<string, unknown>): void {
    this.store.dispatch(urlRouterActions.go({ path, query }));
    this.router.navigate(path, { queryParams: query, ...extras })
  }

  navigateByUrl(url: string, extras?: NavigationExtras): void {
    this.store.dispatch(urlRouterActions.goByUrl({ url, extras }));
  }

}
