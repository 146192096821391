import { createReducer, on } from '@ngrx/store';
import { FeedlotPostMortemSettingsDto } from '@ifhms/models/admin';
import { PostMortemSettingsActions } from './post-mortem.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  updating: boolean;
  updated: boolean;
  postMortemSettings: FeedlotPostMortemSettingsDto | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  updating: false,
  updated: false,
  postMortemSettings: null
};

export const reducer = createReducer(
  initialState,

  on(PostMortemSettingsActions.getPostMortemSettings,
    (state) => ({
      ...state,
      loading: false,
      loaded: true,
      updating: false,
      updated: false
    })
  ),

  on(PostMortemSettingsActions.getPostMortemSettingsSuccess,
    (state, action) => ({
      ...state,
      postMortemSettings: action.settings,
      loading: false,
      loaded: true,
      updating: false,
      updated: false
    })
  ),

  on(
    PostMortemSettingsActions.updatePostMortemSettings,
    state => ({
      ...state,
      updating: true,
      updated: false
    })
  ),

  on(PostMortemSettingsActions.updatePostMortemSettingsSuccess,
    (state, action) => (
      {
        ...state,
        postMortemSettings: action.settings,
        updating: false,
        updated: true
      })
  ),

  on(PostMortemSettingsActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false,
    updating: false,
    updated: false
  })),

  on(PostMortemSettingsActions.resetPostMortemSettings,
    (state) => ({
      ...state,
      postMortemSettings: null
    })
  )
);

export const getPostMortemSettings = (state: State): FeedlotPostMortemSettingsDto | null => state.postMortemSettings;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getUpdating = (state: State): boolean => state.loading;
export const getUpdated = (state: State): boolean => state.loaded;
