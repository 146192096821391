
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { LotsActions } from './lots.actions';

export interface State extends EntityState<SersiSelectListItem> {
  loading: boolean;
  loaded: boolean;
}

const adapter: EntityAdapter<SersiSelectListItem> =
  createEntityAdapter<SersiSelectListItem>({
    sortComparer: false
  });

const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false
});

export const reducer = createReducer(
  initialState,

  on(LotsActions.getSuccess, (state, { items }) =>
    adapter.setAll(items, {
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(LotsActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  }))
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const getEntities = selectEntities;

export const getAll = selectAll;

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;

