<span class="p-fluid range-slider">
  <div class="range-inputs">
    <p-inputNumber
      [attr.data-ele]="'min'"
      class="form-control input-min"
      inputId="Min"
      type="number"
      (ngModelChange)="onInputChangeLeft($event)"
      [(ngModel)]="rangeValueLeft"
      [min]="rangeMin"
      [max]="rangeMax" />
    <p-inputNumber
      [attr.data-ele]="'max'"
      class="form-control input-max"
      inputId="Max"
      type="number"
      (ngModelChange)="onInputChangeRight($event)"
      [(ngModel)]="rangeValueRight"
      [min]="rangeMin"
      [max]="rangeMax"
      />
  </div>
  <p-slider [(ngModel)]="rangeValues" [formControl]='formControl' (ngModelChange)="onSlidersChange($event)" [range]="true" [min]="rangeMin"
    [max]="rangeMax"></p-slider>
</span>