import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldArrayType } from '@ngx-formly/core';
import { Message } from 'primeng/api';

import { TranslateService } from '@common/angular/translation';

@UntilDestroy()
@Component({
  selector: 'ifhms-charges-repeating-section-type',
  templateUrl: './charges-repeating-section-type.component.html',
  styleUrls: ['./charges-repeating-section-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChargesRepeatingSectionTypeComponent extends FieldArrayType implements OnInit {
  translateScope = 'labour-charges.formly.types.charges-repeating-section';
  displayActionButton = true;
  emptyMessage: Message[];

  constructor(
    public translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.formControl.length > 0) {
      this.disableAllRows();
      this.setLastRowLogic();
    }
    this.disableRepeatingSection();
    this.emptyMessage = [
      { severity: 'warn', summary: this.getTranslation('warning'), detail: this.getTranslation('emptyCharges') }
    ]
  }

  isDisabled(): boolean {
    if (this.form.disabled) {
      return true;
    }

    if (this.formControl.length === 0) {
      return false;
    }

    const lastRow = this.formControl.controls[this.formControl.length - 1];
    if (!lastRow) {
      return false;
    }

    return lastRow.enabled && !lastRow.valid;
  }

  disableAllRows(): void {
    for (let i = 0; i < this.formControl.length; i++) {
      this.formControl.controls[i].disable();
    }
  }

  addNewCharge(): void {
    if (this.formControl.length > 0) {
      this.disableAllRows();
    }

    this.add(undefined,
      {
        effectiveFromDate: undefined,
        effectiveToDate: undefined,
        charge: undefined
      },
      { markAsDirty: false }
    );

    this.setLastRowLogic();
  }

  removeCharge(index: number): void {
    if (index !== this.formControl.length - 1) return;

    this.remove(index);

    if (this.formControl.length > 0) {
      this.setLastRowLogic();
    }
  }

  getLastBilled(index: number): Date {
    return this.model[index]?.lastBilled;
  }

  private setLastRowLogic(): void {
    const lastRowIndex = this.formControl.length - 1;

    const lastRow = this.formControl.controls[lastRowIndex];
    const lastBilledValue = this.getLastBilled(lastRowIndex);

    if (!lastBilledValue) {
      lastRow.enable();
    }

    lastRow.get('effectiveToDate')?.setValue(undefined);
    lastRow.get('effectiveToDate')?.disable({ onlySelf: true });

    if (lastRowIndex > 0) {
      lastRow.get('effectiveFromDate')?.valueChanges.pipe(
        untilDestroyed(this)
      )
        .subscribe(
          (x: Date) => {
            const rowAbove = this.formControl.controls[lastRowIndex - 1];
            const previousEffectiveFromDate = rowAbove.get('effectiveFromDate')?.value as Date;

            if (x > previousEffectiveFromDate) {

              const date = new Date(x);
              date.setDate(date.getDate() - 1);

              rowAbove.get('effectiveToDate')?.setValue(date);
            }
          }
        )
    }
  }

  private disableRepeatingSection(): void {
    if (this.field.props.disabled) {
      this.disableAllRows();
      this.displayActionButton = false;
    }
  }

  private getTranslation(key: string): string {
    return this.translateService.getTranslation(this.translateScope, key);
  }
}
