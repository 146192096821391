import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../reducer';
import * as reducer from './default-values.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.defaultValuesSettings
);

const selectLoading = createSelector(selectState, reducer.getLoading);
const selectLoaded = createSelector(selectState, reducer.getLoaded);
const selectUpdating = createSelector(selectState, reducer.getUpdating);
const selectUpdated = createSelector(selectState, reducer.getUpdated);

const selectDefaultValuesSettings = createSelector(selectState, reducer.getDefaultValuesSettings);

export const DefaultValuesSettingsSelectors = {
  selectState,
  selectLoaded,
  selectLoading,
  selectUpdating,
  selectUpdated,
  selectDefaultValuesSettings
};