import { AbstractControl } from '@angular/forms';

export const EMAIL_VALIDATION_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const HEX_CODE_VALIDATION_REGEX = /^#[\da-fA-F]{6}$/;
export const DIN_NDC_VALIDATION_REGEX = /^(?:\d{4}-\d{4}-\d{2}|\d{5}-\d{3}-\d{2}|\d{5}-\d{4}-\d|\d{8})$/;
export const IP_VALIDATION_REGEX = /^\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b$/;
export const PHONE_NUMBER_VALIDATION_REGEX = /^\(\d{3}\) \d{3}-\d{4}$/;

export function validateEmailControl(control: AbstractControl): boolean {

  return EMAIL_VALIDATION_REGEX.test(control.value);
}

export function validateHexCodeControl(control: AbstractControl): boolean {
  return HEX_CODE_VALIDATION_REGEX.test(control.value);
}

export function validateDinNdc(control: AbstractControl): boolean {
  return DIN_NDC_VALIDATION_REGEX.test(control.value);
}

export const IpValidator = (control: AbstractControl): boolean | null => {
  return IP_VALIDATION_REGEX.test(control.value);
}

export function validatePhoneNumber(val: string): boolean {
  return PHONE_NUMBER_VALIDATION_REGEX.test(val);
}
