import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME, reducer } from './+state';
import { OperationsEffects } from './+state/operations/operations.effects';
import { OperationsFacade } from './+state/operations/operations.facade';
import { MessageService } from 'primeng/api';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([OperationsEffects])
  ],
  providers: [
    OperationsEffects,
    OperationsFacade,
    MessageService
  ]
})
export class IfhmsAdminWebDomainStateOperationsModule {}
