import { Inject, Injectable } from '@angular/core';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState
} from '@microsoft/signalr';
import { from, Observable, switchMap } from 'rxjs';
import { AH_SIGNALR_URL } from '../tokens';

@Injectable()
export class MessageHubService {
  public hubConnection: HubConnection;

  private hubConnecting: Observable<void> | null;

  constructor(@Inject(AH_SIGNALR_URL) private signalRUrl: string) {}

  receiveMessage<T>(method: string): Observable<T> {
    return this.ensureHubConnection().pipe(
      switchMap(() => this.registerMessage(method))
    ) as Observable<T>;
  }

  sendMessage<T>(method: string, data: T): Observable<void> {
    return this.ensureHubConnection().pipe(
      switchMap(() => from(this.hubConnection.invoke(method, data)))
    );
  }

  private registerMessage<T>(method: string): Observable<T> {
    return new Observable<T>((observer) => {
      this.hubConnection.on(method, (data: T) => {
        observer.next(data);
      });
    });
  }

  private initConnection(): Observable<void> {
    return from(this.hubConnection.start());
  }

  public ensureHubConnection(): Observable<void> {
    this.hubConnection = this.hubConnection || this.buildHubConnection();

    if (this.hubConnection?.state === HubConnectionState.Disconnected) {
      this.hubConnecting = null;
    }

    this.hubConnecting = this.hubConnecting || this.initConnection();

    return this.hubConnecting;
  }

  private buildHubConnection(): HubConnection {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.signalRUrl}`)
      .withAutomaticReconnect()
      .build();

    return this.hubConnection;
  }
}
