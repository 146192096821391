import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { ManufacturersActions } from './manufacturers.actions';
import * as reducer from './manufacturers.reducer';
import { ManufacturersSelectors } from './manufacturers.selectors';

@Injectable()
export class ManufacturersFacade {

  loaded$ = this.store.select(ManufacturersSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(ManufacturersSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}
  
  get(): void {
    this.store.dispatch(ManufacturersActions.get());
  }
}
