import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateService } from '@common/angular/translation';

import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';

// Enums
import { FormlyTypesEnum, FormlyWrappersEnum, sersiFormlyValidationConfig } from '@sersi/angular/formly/core';
import { defaultFieldClassnameExtension } from '@sersi/angular/formly/extensions';
// TYPES
import { AccordionTabTypeComponent } from '@sersi/angular/formly/types/accordion-tab';
import { AutoCompleteTypeComponent } from '@sersi/angular/formly/types/auto-complete';
import { BannerTypeComponent } from '@sersi/angular/formly/types/banner';
import { BulletedListTypeComponent } from '@sersi/angular/formly/types/bulleted-list';
import { ButtonTypeComponent } from '@sersi/angular/formly/types/button';
import { ButtonMenuTypeComponent } from '@sersi/angular/formly/types/button-menu';
import { CalenderTypeComponent } from '@sersi/angular/formly/types/calendar';
import { CheckboxTypeComponent } from '@sersi/angular/formly/types/checkbox';
import { CheckboxGroupTypeComponent } from '@sersi/angular/formly/types/checkbox-group';
import { ColorPickerTypeComponent } from '@sersi/angular/formly/types/color-picker';
import { CurrencyTypeComponent } from '@sersi/angular/formly/types/currency';
import { GridTypeComponent } from '@sersi/angular/formly/types/grid';
import { InfoIconTypeComponent } from '@sersi/angular/formly/types/info-icon';
import { NumberInputTypeComponent } from '@sersi/angular/formly/types/number-input';
import { PasswordTypeComponent } from '@sersi/angular/formly/types/password';
import { PhoneNumberTypeComponent } from '@sersi/angular/formly/types/phone-number';
import { PostalCodeTypeComponent } from '@sersi/angular/formly/types/postal-code';
import { RadioButtonTypeComponent } from '@sersi/angular/formly/types/radio-button';
import { RangeSliderTypeComponent } from '@sersi/angular/formly/types/range-slider';
import { RepeatSectionTypeComponent } from '@sersi/angular/formly/types/repeat-section';
import { SectionTitleTypeComponent } from '@sersi/angular/formly/types/section-title';
import { MultiSelectTypeComponent, SingleSelectTypeComponent } from '@sersi/angular/formly/types/select';
import { TextAreaTypeComponent } from '@sersi/angular/formly/types/text-area';
import { TextInputTypeComponent } from '@sersi/angular/formly/types/text-input';
import { TextReadonlyTypeComponent } from '@sersi/angular/formly/types/text-readonly';
import { TranslationInputTypeComponent } from '@sersi/angular/formly/types/translation-input';
import { ZipCodeTypeComponent } from '@sersi/angular/formly/types/zip-code';

// WRAPPERS
import {
  CheckboxTableRowWrapperComponent,
  CheckboxTableWrapperComponent
} from '@sersi/angular/formly/wrappers/checkbox-table-wrapper';
import {
  DefaultFormFieldWrapperComponent,
  DefaultHorizontalFormFieldWrapperComponent,
  FormFieldWithToggleWrapperComponent
} from '@sersi/angular/formly/wrappers/default';
import { GroupWrapperComponent } from '@sersi/angular/formly/wrappers/group';
import { SectionWrapperComponent } from '@sersi/angular/formly/wrappers/section';
import { ButtonModule } from 'primeng/button';

import { DividerModule } from 'primeng/divider';
import { MessagesModule } from 'primeng/messages';
import { TooltipModule } from 'primeng/tooltip';
import {
  ChargesRepeatingSectionTypeComponent,
  IfhmsAdminWebCoreFormlyTypesPricingSelectModule,
  PricingSelectTypeComponent
} from './components';
import { AdminFormlyTypesEnum } from './interfaces';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    TooltipModule,
    FormlyModule.forRoot({
      wrappers: [
        {
          name: FormlyWrappersEnum.CHECKBOX_TABLE,
          component: CheckboxTableWrapperComponent
        },
        {
          name: FormlyWrappersEnum.CHECKBOX_TABLE_ROW,
          component: CheckboxTableRowWrapperComponent
        },
        {
          name: FormlyWrappersEnum.DEFAULT_SERSI,
          component: DefaultFormFieldWrapperComponent
        },
        {
          name: FormlyWrappersEnum.DEFAULT_HORIZONTAL_WRAPPER,
          component: DefaultHorizontalFormFieldWrapperComponent
        },
        {
          name: FormlyWrappersEnum.FIELD_WITH_TOGGLE_WRAPPER,
          component: FormFieldWithToggleWrapperComponent
        },
        {
          name: FormlyWrappersEnum.GROUP,
          component: GroupWrapperComponent
        },
        {
          name: FormlyWrappersEnum.SECTION,
          component: SectionWrapperComponent
        }
      ],
      types: [
        {
          name: FormlyTypesEnum.ACCORDION,
          component: AccordionTabTypeComponent
        },
        {
          name: FormlyTypesEnum.AUTO_COMPLETE,
          component: AutoCompleteTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.BUTTON,
          component: ButtonTypeComponent
        },
        {
          name: FormlyTypesEnum.BUTTON_MENU,
          component: ButtonMenuTypeComponent
        },
        {
          name: FormlyTypesEnum.BANNER,
          component: BannerTypeComponent
        },
        {
          name: FormlyTypesEnum.BULLET_LIST,
          component: BulletedListTypeComponent
        },
        {
          name: AdminFormlyTypesEnum.CHARGES_REPEATING_SECTION,
          component: ChargesRepeatingSectionTypeComponent
        },
        {
          name: AdminFormlyTypesEnum.PRICING_SELECT,
          component: PricingSelectTypeComponent
        },
        {
          name: FormlyTypesEnum.CALENDAR_INPUT,
          component: CalenderTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.CHECKBOX,
          component: CheckboxTypeComponent
        },
        {
          name: FormlyTypesEnum.CHECKBOX_GROUP,
          component: CheckboxGroupTypeComponent
        },
        {
          name: FormlyTypesEnum.COLOUR_PICKER,
          component: ColorPickerTypeComponent
        },
        {
          name: FormlyTypesEnum.CURRENCY_INPUT,
          component: CurrencyTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.INFO_ICON,
          component: InfoIconTypeComponent
        },
        {
          name: FormlyTypesEnum.NUMBER_INPUT,
          component: NumberInputTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.PASSWORD,
          component: PasswordTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.PHONE_NUMBER,
          component: PhoneNumberTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.POSTAL_CODE,
          component: PostalCodeTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.RANGE_SLIDER,
          component: RangeSliderTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.RADIO_BUTTON,
          component: RadioButtonTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.REPEAT_SECTION_TYPE,
          component: RepeatSectionTypeComponent
        },
        {
          name: FormlyTypesEnum.REPEATING_GRID_TYPE,
          component: GridTypeComponent
        },
        {
          name: FormlyTypesEnum.SECTION_TITLE,
          component: SectionTitleTypeComponent
        },
        {
          name: FormlyTypesEnum.SINGLE_SELECT,
          component: SingleSelectTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.TRANSLATION,
          component: TranslationInputTypeComponent
        },
        {
          name: FormlyTypesEnum.MULTI_SELECT,
          component: MultiSelectTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.TEXT_AREA_INPUT,
          component: TextAreaTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.TEXT_INPUT,
          component: TextInputTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.TEXT_READONLY,
          component: TextReadonlyTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        },
        {
          name: FormlyTypesEnum.ZIP_CODE,
          component: ZipCodeTypeComponent,
          wrappers: [FormlyWrappersEnum.DEFAULT_SERSI]
        }
      ],
      extensions: [
        {
          name: 'default-classname',
          extension: defaultFieldClassnameExtension
        }
      ]
    }),
    IfhmsAdminWebCoreFormlyTypesPricingSelectModule,
    MessagesModule,
    DividerModule
  ],
  declarations: [
    ChargesRepeatingSectionTypeComponent
  ],
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: sersiFormlyValidationConfig,
      deps: [TranslateService]
    }
  ]
})
export class IfhmsAdminWebCoreFormlyModule {
  constructor(
  @Optional()
  @SkipSelf()
    parentModule: IfhmsAdminWebCoreFormlyModule
  ) {
    if (parentModule) {
      throw new Error(
        'IfhmsAdminWebCoreFormlyModule is already loaded. Import only in AppModule'
      );
    }
  }
}
