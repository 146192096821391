import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { ANIMALS_API_URL, AnimalSelectorResultDto, AnimalSelectorSearchParams } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class AnimalSelectorService {

  constructor(
    private http: HttpClient,
    @Optional() @Inject(ANIMALS_API_URL) private apiUrl: string
  ) {}

  search(request: AnimalSelectorSearchParams): Observable<AnimalSelectorResultDto> {
    const { operationId, ...restParams } = request;
    console.log(this.apiUrl, restParams.toString());
    return this.http.put<AnimalSelectorResultDto>(`${this.apiUrl}/Feedlot/${operationId}/Selector/Search`,  restParams );
  }

}
