import { Pipe, PipeTransform } from '@angular/core';
import { getLocalDateTimeFromUtcIsoString } from '../helpers';

@Pipe({
  name: 'sersiDate'
})
export class SersiDatePipe implements PipeTransform {

  readonly dateOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
    year: 'numeric'
  };

  readonly dateTimeOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit'
  };

  transform(date?: Date | string, showTime?: boolean, useLocalDate = false, locale = 'en'): string {
    if (date) {
      if (Object.prototype.toString.call(date) !== '[object Date]') {
        date = useLocalDate ? getLocalDateTimeFromUtcIsoString(date as string)! : new Date(date);
      }

      const options = showTime ? this.dateTimeOptions : this.dateOptions;
      return date.toLocaleString(locale, options)
    }
    else {
      return '-';
    }
  }
}
