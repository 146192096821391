export interface LotBillingManagementListItemDto {
  id: string;
  billNumber: number;
  billType: BillType;
  billToDate: string;
  invoiceDate: string;
  status: BillingStatus;
}

export enum BillingStatus {
  Completed = 106,
  Deleted = 103,
  Pending = 104,
  Generating = 105,
  Deleting = 107,
  Finalizing = 108
}

export enum BillType {
  Standard = 1,
  LotCloseout = 2
}