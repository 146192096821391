import { Injectable } from '@angular/core';

import { getLoadedStateData } from '@common/angular/utils';
import { OperationListItemDto } from '@ifhms/models/admin';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { OperationsActions } from './operations.actions';
import { State } from './operations.reducer';
import { OperationsSelectors } from './operations.selectors';

@Injectable()
export class OperationsFacade {
  state$ = this.store.select(OperationsSelectors.selectState);
  operations$ = this.store.select(OperationsSelectors.selectOperations);
  feedlotOperation$ = this.store.select(OperationsSelectors.selectFeedlotOperations);
  filter$ = this.store.select(OperationsSelectors.selectFilter);

  isCreating$: Observable<boolean> = this.store.select(OperationsSelectors.selectCreating);

  isLoading$: Observable<boolean> = this.store.select(OperationsSelectors.selectLoading);
  isLoaded$: Observable<boolean> = this.store.select(OperationsSelectors.selectLoaded);

  loadedOperations$ = getLoadedStateData<OperationListItemDto[]>(this.operations$, this.isLoaded$);

  selectList$ = this.store.select(OperationsSelectors.selectSelectList);

  constructor(private store: Store<State>) {
  }

  filter(filter: string): void {
    this.store.dispatch(OperationsActions.filter({ filter }));
  }

  create(code: string, name: string, countryId: string, operationTypeId: string): void {
    this.store.dispatch(OperationsActions.create({ code, name, countryId, operationTypeId }));
  }

  get(companyId: string, forceReload = true): void {
    this.store.dispatch(OperationsActions.get({ companyId, forceReload }));
  }

  resetFilters(): void {
    this.store.dispatch(OperationsActions.resetFilters());
  }

  reset(): void {
    this.store.dispatch(OperationsActions.reset());
  }

  refresh(): void {
    this.store.dispatch(OperationsActions.refresh());
  }

}
