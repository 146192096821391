import { HttpClient, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  SortGroupTemplateCreateDto,
  SortGroupTemplateDto,
  SortGroupTemplateRequestDto,
  SortGroupTemplateResultDto
} from '@ifhms/models/admin';
import { Observable } from 'rxjs';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class FeedlotSortGroupTemplatesSettingsService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  getList(
    request: SortGroupTemplateRequestDto,
    feedlotId: string
  ): Observable<SortGroupTemplateResultDto> {
    return this.http.put<SortGroupTemplateResultDto>(
      `${this.apiUrl}/Feedlots/${feedlotId}/Settings/ManagementGroups/List`,
      JSON.stringify(request),
      {
        headers: this.headers
      }
    );
  }

  get(
    feedlotId: string,
    managementGroupId: string
  ): Observable<SortGroupTemplateDto> {
    return this.http.get<SortGroupTemplateDto>(
      `${this.apiUrl}/Feedlots/${feedlotId}/Settings/ManagementGroups/${managementGroupId}`
    );
  }

  add(
    feedlotId: string,
    managementGroup: SortGroupTemplateCreateDto
  ): Observable<SortGroupTemplateDto> {
    return this.http.post<SortGroupTemplateDto>(
      `${this.apiUrl}/Feedlots/${feedlotId}/Settings/ManagementGroups`,
      JSON.stringify(managementGroup),
      {
        headers: this.headers
      }
    );
  }

  update(
    feedlotId: string,
    managementGroup: SortGroupTemplateDto
  ): Observable<SortGroupTemplateDto> {
    return this.http.put<SortGroupTemplateDto>(
      `${this.apiUrl}/Feedlots/${feedlotId}/Settings/ManagementGroups`,
      JSON.stringify(managementGroup),
      {
        headers: this.headers
      }
    );
  }

  isCodeValid(
    feedlotId: string,
    managementGroupId: string,
    code: string
  ): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        code,
        managementGroupId
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(
      `${this.apiUrl}/Feedlots/${feedlotId}/Settings/ManagementGroups/IsCodeValid`,
      options
    );
  }
}
