import { inject, Pipe, PipeTransform } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { TranslationJsonDto } from '@ifhms/models/shared';

import { TranslateService } from '../services';
import { getLocalizedLabel } from '../utils';

@Pipe({ name: 'descriptionTranslation' })
export class DescriptionTranslationPipe implements PipeTransform {

  private translateService = inject(TranslateService);

  transform(value: TranslationJsonDto): Observable<string> {

    if (value === null) {
      return of('-');
    }

    return this.translateService.langChanges$
      .pipe(map(lang => getLocalizedLabel(value, lang)));
  }

}
