import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppContextDto, ContextLevel } from '@ifhms/models/shared';

import { FieldType, FieldTypeConfig, FormlyExtension } from '@ngx-formly/core';
import { FormlyFieldConfig } from '@ngx-formly/core/lib/models/fieldconfig';
import { FormlyTypesEnum, SersiFormlyFieldProps } from '@sersi/angular/formly/core';
import { isEmpty } from 'lodash-es';

enum TranslationFieldProps {
  APP_CONTEXT = 'appContext',
  MAIN_LABEL = 'mainLabel',
  EN_LABEL = 'enLabel',
  ES_LABEL = 'esLabel',
  FR_LABEL = 'frLabel',
  RU_LABEL = 'ruLabel'
}

@Component({
  selector: 'sersi-translation-input-type',
  templateUrl: './translation-input-type.component.html',
  styleUrls: ['./translation-input-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('rotatedState', [
      state('true', style({ transform: 'rotate(0)' })),
      state('false', style({ transform: 'rotate(180deg)' })),
      transition('true => false', animate('120ms ease-out')),
      transition('false => true', animate('120ms ease-in'))
    ])
  ]
})
export class TranslationInputTypeComponent extends FieldType<FieldTypeConfig> implements FormlyExtension, OnInit {

  fields: FormlyFieldConfig[];

  private fieldConfig: FormlyFieldConfig;
  private appContext: AppContextDto;

  override defaultOptions = {
    props: {
      collapsed: true
    }
  }

  get fieldProps(): SersiFormlyFieldProps {
    if (!isEmpty(this?.fieldConfig?.props)) {
      return this?.fieldConfig?.props;
    }
    if (this.props) {
      return this.props
    }
    return {} as SersiFormlyFieldProps;
  }

  label = '';
  showBorder = false;

  onPopulate(field: FormlyFieldConfig): void {
    // skip if already initialized
    if (field.fieldGroup) return;
    this.fieldConfig = field;
    this.fieldConfig.fieldGroup = this.getFieldGroupConfig();

    this.appContext = this.fieldProps[TranslationFieldProps.APP_CONTEXT];
    this.label = this.fieldProps.label ?? '';
  }

  ngOnInit(): void {
    this.showBorder = !this.props['collapsed'];
  }

  toggleCollapsed(): void {
    this.fieldProps['collapsed'] = !this.fieldProps['collapsed'];
    this.showBorder = !this.fieldProps['collapsed'];
  }

  private getFieldGroupConfig(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'translation-group-container grid-column-layout-1',
        fieldGroup:
          [
            this.createEn(),
            this.createEs(),
            this.createFr(),
            this.createRu()
          ]
      }
    ]
  }

  getEnglishLabel(): string {
    const collapsed = this.isCollapsed();
    if (collapsed) return '';

    let label = this.fieldProps[TranslationFieldProps.EN_LABEL];
    if (!label) {
      label = this.props[TranslationFieldProps.EN_LABEL];
    }

    return label;
  }

  isCollapsed(): boolean {
    if (this.fieldProps && Object.prototype.hasOwnProperty.call(this.fieldProps, 'collapsed')) {
      return this.fieldProps['collapsed'];
    }
    if (this.props) {
      return this.props['collapsed'];
    }
    return true;
  }

  createEn(): FormlyFieldConfig {
    return {
      key: 'en',
      type: FormlyTypesEnum.TEXT_INPUT,
      props: {
        required: this.fieldProps['isRequired'] !== false && (this.appContext ? this.appContext.contextLevel === ContextLevel.Enterprise || this.appContext.contextLevel === ContextLevel.Company : true)
      },
      expressions: {
        'props.label': (): void => this.fieldProps['collapsed'] ? '' : this.fieldProps[TranslationFieldProps.EN_LABEL]
      }
    };
  }

  createEs(): FormlyFieldConfig {
    return {
      key: 'es',
      type: FormlyTypesEnum.TEXT_INPUT,
      props: {
        label: this.fieldProps[TranslationFieldProps.ES_LABEL]
      },
      expressions: {
        className: () => this.fieldProps['collapsed'] ? 'hidden' : ''
      }
    };
  }

  createFr(): FormlyFieldConfig {
    return {
      key: 'fr',
      type: FormlyTypesEnum.TEXT_INPUT,
      props: {
        label: this.fieldProps[TranslationFieldProps.FR_LABEL]
      },
      expressions: {
        className: () => this.fieldProps['collapsed'] ? 'hidden' : ''
      }
    };
  }

  createRu(): FormlyFieldConfig {
    return {
      key: 'kk',
      type: FormlyTypesEnum.TEXT_INPUT,
      props: {
        label: this.fieldProps[TranslationFieldProps.RU_LABEL]
      },
      expressions: {
        className: () => this.fieldProps['collapsed'] ? 'hidden' : ''
      }
    };
  }
}
