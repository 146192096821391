import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SelectListItemDto, SelectListItemWithTranslationDto, WorkOrderType } from '@ifhms/models/shared';

import { AH_FMS_REPORT_SELECT_LIST_API_URL } from '../tokens';

@Injectable()
export class ReportApiSelectListService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_REPORT_SELECT_LIST_API_URL) private apiUrl: string
  ) {
  }

  getOperationsSelectList(companyId: string): Observable<SelectListItemDto[]> {
    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Company/${companyId}/Operations`);
  }

  getFacilitiesSelectList(operationId: string): Observable<SelectListItemWithTranslationDto[]> {
    return this.http.get<SelectListItemWithTranslationDto[]>(`${this.apiUrl}/Operation/${operationId}/Facilities`);
  }

  getReconciliationDatesSelectList(operationId: string): Observable<SelectListItemDto[]> {
    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/ReconciliationDates`);
  }

  getLotsSelectList(operationId: string, showArchived: boolean): Observable<SelectListItemDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        showArchived
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/Lots`, options);
  }

  getLotSubGroupsSelectList(operationId: string, showArchived: boolean): Observable<SelectListItemDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        showArchived
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };
    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/LotSubGroups`, options);

  }

  getPensSelectList(operationId: string): Observable<SelectListItemDto[]> {
    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/Pens`);
  }

  getRiskCategoriesSelectList(operationId: string): Observable<SelectListItemDto[]> {
    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/RiskCategories`);
  }

  getWorkOrderList(operationId: string, workOrderType: WorkOrderType, includeArchived: boolean, searchTerm?: string): Observable<SelectListItemDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        workOrderType,
        includeArchived,
        ...(searchTerm ? { searchTerm } : {})
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/WorkOrders`, options);
  }

  getLotBillingList(operationId: string, includeArchived: boolean, searchTerm?: string): Observable<SelectListItemDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        includeArchived,
        ...(searchTerm ? { searchTerm } : {})
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/LotBilling`, options);
  }

  getTagsList(operationId: string, searchTerm?: string): Observable<SelectListItemDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...(searchTerm ? { searchTerm } : {})
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/AnimalTags`, options);
  }

  getPostMortemAnimalTagsList(operationId: string, searchTerm?: string): Observable<SelectListItemDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...(searchTerm ? { searchTerm } : {})
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/PostMortemAnimalTags`, options);
  }

  getNationalIdsList(operationId: string, searchTerm?: string): Observable<SelectListItemDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...(searchTerm ? { searchTerm } : {})
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/NationalId`, options);
  }
}
