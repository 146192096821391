import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ngSwitchMultiCase' })
export class NgSwitchMultiCasePipe implements PipeTransform {

  transform(multipleOptions: (string|number)[], selectedOption?: string|number) : string | number {
    return selectedOption && multipleOptions.includes(selectedOption) ? selectedOption : '';
  }

}
