import { Injectable } from '@angular/core';
import { AuthErrorHandlingService } from '@common/angular/auth';
import { UserProfileDto } from '@common/angular/core/models';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, tap, timeout, withLatestFrom } from 'rxjs';
import { CommonUserProfileActions } from './common-user-profile.actions';
import { CommonUserProfileFacade } from './common-user-profile.facade';
import { FEATURE_NAME } from './common-user-profile.reducer';
import { CommonUserProfileApiService } from '../services';

@Injectable()
export class CommonUserProfileEffects {

  private readonly profileLoadTimout = 20 * 1000; // 20 seconds

  get$ = createEffect(() => this.actions$.pipe(
    ofType(CommonUserProfileActions.get),
    exhaustMap(action =>
      this.userApiService.getAuthUserProfile(action.userId).pipe(
        map((userProfile: UserProfileDto) => CommonUserProfileActions.getSuccess({ userProfile })),
        timeout(this.profileLoadTimout),
        catchError((resp) => {
          this.authErrorHandlingService.handleAuthError(FEATURE_NAME);
          return of(CommonUserProfileActions.err(resp?.err))
        })
      )
    )
  ));

  updateLangSettings$ = createEffect(() => this.actions$.pipe(
    ofType(CommonUserProfileActions.updateLangSettings),
    withLatestFrom(this.userProfileFacade.userId$),
    exhaustMap(([{ langCode }, userId]) =>
      this.userApiService.updateUserLangSettings(userId!, langCode).pipe(
        map(() => CommonUserProfileActions.updateLangSettingsSuccess({ langCode })),
        catchError((resp) => of(CommonUserProfileActions.err(resp?.err)))
      )
    )
  ));

  getSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CommonUserProfileActions.getSuccess),
    tap(() => this.authErrorHandlingService.clearAuthErrorState(FEATURE_NAME))
  ), { dispatch: false });

  constructor(
    private readonly actions$: Actions,
    private userApiService: CommonUserProfileApiService,
    private userProfileFacade: CommonUserProfileFacade,
    private authErrorHandlingService: AuthErrorHandlingService
  ) {
  }

}