import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DisplayFieldComponent,
  SectionTitleComponent,
  SuffixButtonComponent,
  TagComponent,
  ColorBoxComponent
} from './components';
import { SersiAngularUiCoreModule } from '@sersi/angular/ui/core';
import { TagModule } from 'primeng/tag';

@NgModule({
  imports: [
    CommonModule,
    SersiAngularUiCoreModule,
    TagModule
  ],
  declarations: [
    DisplayFieldComponent,
    SectionTitleComponent,
    TagComponent,
    ColorBoxComponent,
    SuffixButtonComponent
  ],
  exports: [
    DisplayFieldComponent,
    SectionTitleComponent,
    TagComponent,
    ColorBoxComponent,
    SuffixButtonComponent
  ]
})
export class SersiAngularUiTypographyModule {
}
