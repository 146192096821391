<ng-container *ngLet="(isSpinning$ | async) as isSpinning">
  <ng-container *transloco="let t; read: translateScope">
    <div class="spinner" *ngIf="isSpinning">
      <p-progressSpinner></p-progressSpinner>
      <h4 *ngIf="loadingMessage$">{{ loadingMessage$ | async }}</h4>
      <h4 *ngIf="showLongLoadingMessage">{{ t('long-loading-message') }}</h4>
    </div>
    <div class="content" *ngIf="!(isSpinning && hideContent)">
      <ng-content></ng-content>
    </div>
  </ng-container>
</ng-container>