import { Component, ViewEncapsulation } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'sersi-section-wrapper',
  templateUrl: './section-wrapper.component.html',
  styleUrls: ['./section-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SectionWrapperComponent extends FieldWrapper {}
