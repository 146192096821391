import {
  SelectListItemDto,
  SelectListItemWithTranslationDto
} from '@common/angular/core/models';

import { getLocalizedLabel } from './translation-locale.utils';

export function convertSelectListItemDtoToSelectListItem(
  items: SelectListItemWithTranslationDto[],
  lang: string
): SelectListItemDto[] {
  return items.map((item: SelectListItemWithTranslationDto) => {
    return {
      id: item.id,
      isActive: item.isActive,
      attributes: item.attributes,
      code: item.code,
      description: getLocalizedLabel(item.description, lang)
    } as SelectListItemDto;
  });
}
