import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ItsGlobalSettingsDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class FeedlotItsGlobalSettingsService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string) {
  }

  get(operationId: string): Observable<ItsGlobalSettingsDto> {
    return this.http.get<ItsGlobalSettingsDto>(
      `${this.apiUrl}/Operations/${operationId}/Settings/ItsGlobal`
    );
  }

  update(operationId: string, settings: ItsGlobalSettingsDto)
    : Observable<ItsGlobalSettingsDto> {
    return this.http.put<ItsGlobalSettingsDto>(
      `${this.apiUrl}/Operations/${operationId}/Settings/ItsGlobal`,
      settings
    );
  }
}
