import { HashMap } from '@jsverse/transloco';

import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { map, Observable } from 'rxjs';

import { getLoadedStateData } from './state-data.helper';
import { camelCaseToKebab } from './string.helper';

export function getSelectListFromEnum<T extends {
  [key: string]: string | number
}>(selectableEnum: T, translations: HashMap, sortByVal = false, excludeValues?: (string | number)[]): SersiSelectListItem[] {
  return Object.keys(selectableEnum)
    .filter(key => isNaN(Number(key)) && (!excludeValues || !excludeValues.includes(selectableEnum[key])))
    .sort((a, b) => {
      if (sortByVal) {
        return <number>selectableEnum[a] - <number>selectableEnum[b];
      }
      return a.localeCompare(b);
    })
    .map((key: string) => {
      const langKey = camelCaseToKebab(key);
      return {
        id: selectableEnum[key],
        code: key,
        description: translations[langKey]
      };
    });
}

export function getLoadedSelectItems(items$: Observable<SersiSelectListItem[]>, loaded$: Observable<boolean>): Observable<SersiSelectListItem[]> {
  return getLoadedStateData(items$, loaded$);
}

export function filterSelectListByAttribute(
  selectList$: Observable<SersiSelectListItem[]>,
  attrKey: string,
  filterVal: string | number
): Observable<SersiSelectListItem[]> {
  return selectList$.pipe(
    map((items: SersiSelectListItem[]) =>
      items.filter((selectItem: SersiSelectListItem) => {
        const attr = selectItem?.attributes;
        return attr ? attr[attrKey] === filterVal : selectItem.code;
      })
    )
  );
}
