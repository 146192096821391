import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../reducer';
import * as reducer from './pricing.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.pricingSettings
);

const selectLoading = createSelector(selectState, reducer.getLoading);
const selectLoaded = createSelector(selectState, reducer.getLoaded);
const selectUpdating = createSelector(selectState, reducer.getUpdating);
const selectUpdated = createSelector(selectState, reducer.getUpdated);

const selectPricingSettings = createSelector(selectState, reducer.getPricingSettings);

export const PricingSettingsSelectors = {
  selectState,
  selectLoaded,
  selectLoading,
  selectUpdating,
  selectUpdated,
  selectPricingSettings
};