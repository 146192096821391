<p class="mt-0">{{ dialogData.message }}</p>
<div class="container-footer-buttons">
  <button
    pButton
    data-ele="ok-btn"
    iconPos="left"
    class="p-button-secondary"
    [text]="true"
    [rounded]="true"
    [label]="dialogData.acceptLabel!"
    (click)="onClose()"
  >
  </button>
</div>
