import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { RefDataDto, ReferenceDataType } from '@ifhms/models/admin';
import { AppContextDto, ContextLevel, SelectListItemDto, SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { Observable } from 'rxjs';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ReferenceDataService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  get(feedlotId: string): Observable<RefDataDto> {
    return this.http.get<RefDataDto>(
      `${this.apiUrl}/Reference/Feedlot/${feedlotId}`
    );
  }

  getSelectListByType(
    appContext: AppContextDto,
    type: ReferenceDataType
  ): Observable<SelectListItemWithTranslationDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        contextLevel: appContext.contextLevel,
        levelId: appContext.levelId,
        type
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.http.get<SelectListItemWithTranslationDto[]>(
          `${this.apiUrl}/EnterpriseReferenceData/SelectList/Type`,
          options
        );
      case ContextLevel.Company:
        return this.http.get<SelectListItemWithTranslationDto[]>(
          `${this.apiUrl}/CompanyReferenceData/${appContext.levelId}/SelectList/Type`,
          options
        );
      case ContextLevel.Operation:
      default:
        return this.http.get<SelectListItemWithTranslationDto[]>(
          `${this.apiUrl}/OperationReferenceData/${appContext.levelId}/SelectList/Type`,
          options
        );
    }
  }

  getOperationSelectListByType(feedlotId: string, type: string): Observable<SelectListItemDto[]> {
    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Reference/SelectLists/Feedlot/${feedlotId}/${type}`);
  }

}
