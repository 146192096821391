import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AnimalSelectorAnimalItem, AnimalSelectorSearchParams } from '../interfaces';

import { AnimalSelectorActions } from './animal-selector.actions';
import { AnimalSelectSelector } from './animal-selector.selectors';

@Injectable()
export class AnimalSelectorFacade {

  loading$: Observable<boolean> = this.store.select(AnimalSelectSelector.selectLoading);
  loaded$: Observable<boolean> = this.store.select(AnimalSelectSelector.selectLoaded);
  animals$: Observable<AnimalSelectorAnimalItem[]> = this.store.select(AnimalSelectSelector.selectAnimals);
  selectedAnimals$: Observable<AnimalSelectorAnimalItem[]> = this.store.select(AnimalSelectSelector.selectSelectedAnimals);

  constructor(private readonly store: Store) {
  }

  search(searchParams: AnimalSelectorSearchParams): void {
    this.store.dispatch(AnimalSelectorActions.search({ searchParams }));
  }

  setSelectedAnimals(animals: AnimalSelectorAnimalItem[]): void {
    this.store.dispatch(AnimalSelectorActions.setSelectedAnimals({ animals }));
  }

  error(): void {
    this.store.dispatch(AnimalSelectorActions.error());
  }

  reset(): void {
    this.store.dispatch(AnimalSelectorActions.reset());
  }

}
