<div class="grid-action-button-container">
  <button
    pButton
    pRipple
    data-ele="grid-add-btn"
    type="button"
    icon="pi pi-plus-circle"
    class="p-button-rounded p-button-text p-button-primary"
    [disabled]="disabled"
    (click)="addNewRow.emit()"
  ></button>
</div>
