import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RailDestinationCreateDto, RailDestinationDto, RailDestinationListItemDto } from '@ifhms/models/admin';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ReferenceDataRailDestinationService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) { }

  add(feedlotId: string, railDestination: RailDestinationCreateDto): Observable<RailDestinationDto> {
    return this.http.post<RailDestinationDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/RailDestinations`, railDestination);
  }

  get(feedlotId: string, railDestinationId: string): Observable<RailDestinationDto> {
    return this.http.get<RailDestinationDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/RailDestinations/${railDestinationId}`);
  }

  getList(feedlotId: string): Observable<RailDestinationListItemDto[]> {
    return this.http.get<RailDestinationListItemDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/RailDestinations/List`);
  }

  delete(feedlotId: string, railDestinationId: string): Observable<unknown> {
    return this.http.delete(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/RailDestinations/${railDestinationId}/Delete`);
  }

  update(feedlotId: string, railDestination: RailDestinationDto): Observable<RailDestinationDto> {
    return this.http.put<RailDestinationDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/RailDestinations`, railDestination);
  }

  activate(feedlotId: string, railDestinationId: string): Observable<RailDestinationDto> {
    return this.http.put<RailDestinationDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/RailDestinations/${railDestinationId}/Activate`, null);
  }

  deactivate(feedlotId: string, railDestinationId: string): Observable<RailDestinationDto> {
    return this.http.put<RailDestinationDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/RailDestinations/${railDestinationId}/Deactivate`, null);
  }

  isCodeUnique(feedlotId: string, code: string): Observable<boolean> {

    const httpParams: HttpParamsOptions = {
      fromObject: {
        code: code
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/RailDestinations/IsCodeValid`, options);
  }
}
