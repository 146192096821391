import { createAction, props } from '@ngrx/store';
import { CompanyDto, CompanyCreateDto } from '@ifhms/models/admin';

const NAMESPACE = '[COMPANIES]';

const create = createAction(`${NAMESPACE} Create`, props<{ companyCreateDto: CompanyCreateDto }>());
const createSuccess = createAction(`${NAMESPACE} Create Success`, props<{ companies: CompanyDto[] }>());

const get = createAction(
  `${NAMESPACE} Get All Companies`,
  props<{ forceReload: boolean }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get All Companies Success`,
  props<{ companies: CompanyDto[] }>()
);

const getSuccessCached = createAction(`${NAMESPACE} Get All Companies Success Cached`);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const CompaniesActions = {
  create,
  createSuccess,
  get,
  getSuccess,
  getSuccessCached,
  error,
  reset
};
