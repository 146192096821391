export enum AdgSettingsSortByEnum {
  WeightRangeDescending = 1,
  WeightRangeAscending = 2,
  GenderDescending = 3,
  GenderAscending = 4,
  AgeDescending = 5,
  AgeAscending = 6,
  AdgDescending = 7,
  AdgAscending = 8,
  MonthPlacedDescending = 9,
  MonthPlacedAscending = 10
}