import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SersiSelectListItem, SersiSelectListItemViewMode, sersiSelectOptionById } from '@sersi/angular/formly/core';

@Component({
  selector: 'sersi-select-item',
  templateUrl: './select-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectItemComponent {

  @Input() viewMode: SersiSelectListItemViewMode;
  @Input() item: SersiSelectListItem;
  @Input() disabled = true;
  @Input() className: string;

  @Input() set options(options: SersiSelectListItem[]) {
    if (!this.item?.code && options?.length) {
      const selectItem = sersiSelectOptionById(<string>this.item.id, options);
      this.item = selectItem || this.item;
    }
  }

  readonly SelectViewMode = SersiSelectListItemViewMode;

}
