import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';
import {
  TurnkeyListItemDto,
  TurnkeyModalSettingsDto,
  TurnkeySettingsDto
} from '@ifhms/models/admin';

@Injectable()
export class FeedlotSettingsTurnkeyService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  getTable(operationId: string): Observable<TurnkeyListItemDto[]> {
    return this.http.get<TurnkeyListItemDto[]>(
      `${this.apiUrl}/Operation/${operationId}/Turnkey/History`
    );
  }

  getModalSettings(operationId: string): Observable<TurnkeyModalSettingsDto> {
    return this.http.get<TurnkeyModalSettingsDto>(
      `${this.apiUrl}/Operation/${operationId}/Turnkey/Settings`
    );
  }

  updateModalSettings(
    modalSettings: TurnkeyModalSettingsDto,
    operationId: string
  ): Observable<TurnkeyModalSettingsDto> {
    return this.http.put<TurnkeyModalSettingsDto>(
      `${this.apiUrl}/Operation/${operationId}/Turnkey/Settings`,
      modalSettings
    );
  }

  getTurnkeySettings(operationId: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.apiUrl}/Operation/${operationId}/TurnKey/Turnkey`
    );
  }

  updateTurnkeyAccountSettings(
    operationId: string,
    settings: TurnkeySettingsDto
  ): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.apiUrl}/Operation/${operationId}/TurnKey/Turnkey/${settings.isTurnkeyEnabled}`,
      settings
    );
  }

  generateUsageReport(
    startDate: string,
    endDate: string,
    operationId: string
  ): Observable<any> {
    const options = {
      startDate,
      endDate
    };

    return this.http.put(
      `${this.apiUrl}/Operation/${operationId}/TurnKey/ExportVetUsage`,
      options,
      { responseType: 'arraybuffer', observe: 'response'  }
    );
  }

  generateMoveReport(
    startDate: string,
    endDate: string,
    operationId: string
  ): Observable<any> {
    const options = {
      startDate,
      endDate
    };
    return this.http.put(
      `${this.apiUrl}/Operation/${operationId}/TurnKey/ExportVetMoves`,
      options,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
}
