import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnimalSelectorAnimalItem } from '../interfaces';

import * as reducer from './animal-selector.reducer';

export const FEATURE_NAME = 'animal-selector';
export const selectState = createFeatureSelector<reducer.State>(FEATURE_NAME);

const selectLoading = createSelector(selectState, reducer.getLoading);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

const selectAnimals = createSelector(selectState, reducer.getAnimals);
const baseSelectedAnimals = createSelector(selectState, reducer.getSelectedAnimals);

const selectSelectedAnimals = createSelector(baseSelectedAnimals, sortSelectedAnimals);

function sortSelectedAnimals(animals: AnimalSelectorAnimalItem[]): AnimalSelectorAnimalItem[] {
  const collator = new Intl.Collator('en');
  return [...animals].sort((a, b) => collator.compare(a.tagId, b.tagId));
}

export const AnimalSelectSelector = {
  selectLoading,
  selectLoaded,
  selectAnimals,
  selectSelectedAnimals
}

