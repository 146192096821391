export enum OperationPermissionsReport {
  ReportsAnimalTreatmentHistory = 'reports.hasAnimalTreatmentHistory',
  ReportsDailyTreatmentHistoryByFacility = 'reports.hasDailyTreatmentHistoryByFacility',
  ReportsLotOtherDiseasesDetail = 'reports.hasLotOtherDiseasesDetail',
  ReportsLotUFDetail = 'reports.hasLotUFDetail',
  ReportsOtherDiseasesManagement = 'reports.hasOtherDiseasesManagement',
  ReportsPostMortemDetail = 'reports.hasPostMortemDetail',
  ReportsPostMortemDetailByDate = 'reports.hasPostMortemDetailByDate',
  ReportsUFManagement = 'reports.hasUFManagement',
  ReportsCattleMovement = 'reports.hasCattleMovement',
  ReportsEventDetails = 'reports.hasEventDetails',
  ReportsHospitalOccupancy = 'reports.hasHospitalOccupancy',
  ReportsLotExpensesSummary = 'reports.hasLotExpensesSummary',
  ReportsLotHealthSummary = 'reports.hasLotHealthSummary',
  ReportsPenAndLotInventory = 'reports.hasPenInventory',
  ReportsTagAvailability = 'reports.hasTagAvailability',
  ReportsWithdrawal = 'reports.hasWithdrawal',
  ReportsCurrentProductPricing = 'reports.hasCurrentProductPricing',
  ReportsProductOnHand = 'reports.hasProductOnHand',
  ReportsProductUsage = 'reports.hasProductUsage',
  ReportsReconciledPhysicalInventoryValue = 'reports.hasReconciledPhysicalInventoryValue',
  ReportsArrivalWorkOrder = 'reports.hasArrivalWorkOrder',
  ReportsLotTransferWorkOrder = 'reports.hasLotTransferWorkOrder',
  ReportsMovementWorkOrder = 'reports.hasMovementWorkOrder',
  ReportsRailWorkOrder = 'reports.hasRailWorkOrder',
  ReportsRehandlingWorkOrder = 'reports.hasRehandlingWorkOrder',
  ReportsShipmentWorkOrder = 'reports.hasShipmentWorkOrder'
}
