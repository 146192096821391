import { format, isAfter, isBefore, parse, parseISO, startOfDay } from 'date-fns';
import { isString } from 'lodash-es';

export function convertDateStringToDateObj(dateSource: Date): Date
export function convertDateStringToDateObj(dateSource: Date | undefined): Date | undefined
export function convertDateStringToDateObj(dateSource: string | Date, format?: string): Date

export function convertDateStringToDateObj(dateSource: string | Date | undefined, format?: string): Date | undefined {
  if (!dateSource) return void 0;
  if (dateSource instanceof Date) return dateSource;
  if (!format) return parseISO(dateSource);
  return parse(dateSource, format, new Date());
}

export function isDateBefore(sourceDate: Date, dateToCompare: Date): boolean {
  return isBefore(startOfDay(sourceDate), startOfDay(dateToCompare));
}

export function isDateTimeBefore(sourceDate: Date, dateToCompare: Date): boolean {
  return isBefore(sourceDate, dateToCompare);
}

export function isDateTimeAfter(sourceDate: Date, dateToCompare: Date): boolean {
  return isAfter(sourceDate, dateToCompare);
}

export function dateToIsoString(
  date: Date,
  includeMs = false,
  includeUTCSuffix = true,
  includeTime = true
): string {
  let targetFormat = 'yyyy-MM-dd';

  if (includeTime) {
    const baseTimeFormat = '\'T\'HH:mm:ss';
    targetFormat += includeMs ? `${baseTimeFormat}.SSS` : baseTimeFormat;
  }

  let isoStringDate = format(date, targetFormat);

  if (includeUTCSuffix) {
    isoStringDate += 'Z';
  }

  return isoStringDate;
}

export function localDateToUTCIsoString(date: Date): string {
  const isoDateString = date.toISOString();
  const isoDateWithoutMS = isoDateString.slice(0, -5);
  return `${isoDateWithoutMS}Z`;
}

export function dateToIsoStringEndOfDate(date: Date | null): string | null {
  if (!date) return null;
  const endOfDate = dateToEndOfDay(date);
  return dateToIsoString(endOfDate!, true);
}

export function dateToIsoStringMidDay(date: Date | null): string | null {
  if (!date) return null;

  // safe exit for broken types
  if (typeof date === 'string') return date;

  const localDateWithoutTime = resetDateTime(date);
  localDateWithoutTime.setUTCHours(12, 0, 0, 0);
  return dateToIsoString(localDateWithoutTime, false, false);
}

export function dateToEndOfDay(date: Date | null): Date | null {
  if (!date) return null;
  const endOfDate = new Date(date.getTime());
  endOfDate.setHours(23, 59, 59, 999);
  return endOfDate;
}

export function dateToIsoStringStartOfDate(
  date: Date | null,
  resetTime = false,
  includeMs = true,
  includeUTCSuffix = true
): string | null {
  if (!date) return null;

  if (resetTime) {
    date = resetDateTime(date);
  }

  const startOfDate = dateToStartOfDate(date);
  return dateToIsoString(startOfDate!, includeMs, includeUTCSuffix);
}

export function dateToIsoStringStartOfDateOnly(date: Date | null): string | null {
  if (!date) return null;

  const startOfDate = dateToStartOfDate(date);
  return trimTimeFromDateISOString(startOfDate!.toISOString());
}

export function dateToStartOfDate(date: Date | null): Date | null {
  if (!date) return null;
  const startOfDate = new Date(date.getTime());
  startOfDate.setHours(0, 0, 0, 0);
  return startOfDate;
}

export function getLocalDateFromUTCIsoString(utcIsoString: string, ignoreTimeStamp?: boolean): Date
export function getLocalDateFromUTCIsoString(utcIsoString: string | null, ignoreTimeStamp?: boolean): Date | null
export function getLocalDateFromUTCIsoString(utcIsoString: string | null, ignoreTimeStamp = false): Date | null {
  if (!utcIsoString) return null;

  const dateStr = ignoreTimeStamp ? utcIsoString.split('T')[0] : utcIsoString;
  // The parsed date in the local time zone
  const date = parseISO(dateStr);

  // Create new date without time
  const localDate = new Date();
  localDate.setFullYear(date.getFullYear());
  localDate.setMonth(date.getMonth(), 1);
  localDate.setDate(date.getDate());

  return resetDateTime(localDate);
}

export function resetDateTime(date: Date): Date {
  const dateClone = new Date(date.getTime());
  dateClone.setHours(12);
  dateClone.setMinutes(0);
  dateClone.setSeconds(0);
  dateClone.setMilliseconds(0);
  return dateClone;
}

export function getLocalDateTimeFromUtcIsoString(utcIsoString: string | null): Date | null {
  if (!utcIsoString) return null;

  // Check if time is zeroed out
  // If so, ignore adding timezone offset because this is a date only
  if (utcIsoString.includes('T00:00:00')) {
    return convertDateStringToDateObj(utcIsoString);
  }

  if (!utcIsoString.endsWith('Z')) {
    utcIsoString += 'Z';
  }

  return new Date(utcIsoString);
}

export function getLocalIsoStringFromUtcIsoString(utcIsoString: string): string {
  if (!utcIsoString.endsWith('Z')) {
    utcIsoString += 'Z';
  }
  return utcIsoString;
}

export function trimTimeFromDateISOString(dateString: string): string {
  return dateString.split('T')[0];
}

// Replace .Net empty date ( 2024-01-24T00:00:00 ) with empty string
export function normalizeEmptyDate(val: string | null): string | null {
  if (!val) return val;

  const emptyDateFormat = '0001-01-01';
  const isEmptyDateVal = isString(val) && val.startsWith(emptyDateFormat);
  return isEmptyDateVal ? '' : val;
}

export function getLocalDateStrWithoutTime(date: Date | null, formatStr = 'yyyy-MM-dd'): string {
  if (!date) return '';
  return format(date, formatStr);
}

export function isDate(date: unknown): boolean {
  return date instanceof Date;
}
