import { createReducer, on } from '@ngrx/store';

import { AnimalSelectorActions } from './animal-selector.actions';
import { AnimalSelectorAnimalItem } from '../interfaces';

export interface State {
  loading: boolean;
  loaded: boolean;
  animals: AnimalSelectorAnimalItem[];
  selectedAnimals: AnimalSelectorAnimalItem[];
}

const initialState: State = {
  loading: false,
  loaded: false,
  animals: [],
  selectedAnimals: []
};

export const reducer = createReducer(
  initialState,

  on(AnimalSelectorActions.search, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(AnimalSelectorActions.searchSuccess, (state, { animals }) => ({
    ...state,
    loading: false,
    loaded: true,
    animals: structuredClone(animals)
  })),

  on(AnimalSelectorActions.setSelectedAnimals, (state, { animals }) => ({
    ...state,
    loading: false,
    loaded: true,
    selectedAnimals: structuredClone(animals)
  })),

  on(AnimalSelectorActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(AnimalSelectorActions.reset, () => structuredClone(initialState))
);

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getAnimals = (state: State): AnimalSelectorAnimalItem[] => structuredClone(state.animals);
export const getSelectedAnimals = (state: State): AnimalSelectorAnimalItem[] => structuredClone(state.selectedAnimals);
