import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AdgBulkUpdateDto,
  AdgSettingsListDto,
  AdgSettingsListRequestDto,
  AdgSettingsUpdateSelectItemsDto
} from '@ifhms/models/admin';
import { AppContextDto, ContextLevel } from '@ifhms/models/shared';
import { FeedlotAdgSettingsManagementService } from './feedlot-settings-operation-adg-settings.service';
import { EnterpriseAdgSettingsManagementService } from './feedlot-settings-enterprise-adg-settings.service';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

@Injectable()
export class FeedlotSettingsAdgService {

  constructor(
    private enterpriseAdgSettingsManagementService: EnterpriseAdgSettingsManagementService,
    private feedlotAdgSettingsManagementService: FeedlotAdgSettingsManagementService
  ) {
  }

  getAdgSettingsList(appContext: AppContextDto, request: AdgSettingsListRequestDto): Observable<AdgSettingsListDto> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseAdgSettingsManagementService.getAdgSettingsList(request);
      case ContextLevel.Operation:
        return this.feedlotAdgSettingsManagementService.getAdgSettingsList(appContext.levelId, request);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  bulkUpdateAdgSettings(appContext: AppContextDto, settingsUpdateDto: AdgBulkUpdateDto): Observable<AdgSettingsUpdateSelectItemsDto> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseAdgSettingsManagementService.bulkUpdateAdgSettings(settingsUpdateDto);
      case ContextLevel.Operation:
        return this.feedlotAdgSettingsManagementService.bulkUpdateAdgSettings(appContext.levelId, settingsUpdateDto);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  updateSelectedAdgSettings(appContext: AppContextDto, settingsUpdateDto: AdgSettingsUpdateSelectItemsDto): Observable<AdgSettingsUpdateSelectItemsDto> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseAdgSettingsManagementService.updateSelectedAdgSettings(settingsUpdateDto);
      case ContextLevel.Operation:
        return this.feedlotAdgSettingsManagementService.updateSelectedAdgSettings(appContext.levelId, settingsUpdateDto);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  getAdgWeightRangeSelectList(appContext: AppContextDto): Observable<SersiSelectListItem[]> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseAdgSettingsManagementService.getAdgWeightRangeSelectList();
      case ContextLevel.Operation:
        return this.feedlotAdgSettingsManagementService.getAdgWeightRangeSelectList(appContext.levelId);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }

  }
}