import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { GendersActions } from './genders.actions';
import * as reducer from './genders.reducer';
import { GendersSelectors } from './genders.selectors';

@Injectable()
export class GendersFacade {

  loaded$ = this.store.select(GendersSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(GendersSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}

  get(): void {
    this.store.dispatch(GendersActions.get());
  }
}
