import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, withLatestFrom } from 'rxjs';
import { catchError, exhaustMap, map, tap, timeout } from 'rxjs/operators';

import { HeapService } from '@common/angular/integrations/heap';

import { AUTH_SERVICE_TOKEN, CommonAuthService } from '../services/auth.service';
import { authActions } from './auth.actions';
import { AuthFacade } from './auth.facade';
import { AuthErrorHandlingService } from '../services';
import { FEATURE_NAME } from './auth.reducer';

@Injectable()
export class AuthEffects {

  private readonly loginTimeout= 10 * 1000; // 10 seconds

  constructor(
    private readonly actions$: Actions,
    private readonly authFacade: AuthFacade,
    private readonly heapService: HeapService,
    private authErrorHandlingService: AuthErrorHandlingService,
    @Inject(AUTH_SERVICE_TOKEN) private readonly authService: CommonAuthService
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.init),
      withLatestFrom(this.authFacade.state$),
      exhaustMap(() =>
        this.authService.init().pipe(
          map(() => authActions.initSuccess()),
          timeout(this.loginTimeout),
          catchError(err => of(authActions.initFailed(err)))
        )
      )
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.login),
      exhaustMap((action) =>
        this.authService.login(action.redirectUrl).pipe(
          map(authUser => {
            return authUser
              ? authActions.loginSuccess({ authUser })
              : authActions.loginFailed({ error: 'login failed' });
          }),
          timeout(this.loginTimeout),
          catchError(err => of(authActions.loginFailed({ error: err.message?.toString() })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.loginSuccess),
        tap(action => this.heapService.identify(action.authUser.id)),
        tap(() => this.authErrorHandlingService.clearAuthErrorState(FEATURE_NAME))
      ),
    { dispatch: false }
  );

  loginFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          authActions.loginFailed,
          authActions.initFailed
        ),
        tap(() => this.authErrorHandlingService.handleAuthError(FEATURE_NAME))
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.logout),
        exhaustMap(() => {
          return this.authService.logout().pipe(
            map(() => authActions.logoutSuccess())
          );
        })
      )
  );

  clearSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.clearSession),
        exhaustMap(() => {
          return this.authService.clearSession().pipe(
            map(() => authActions.clearSessionSuccess())
          );
        })
      )
  );

  refreshSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.refreshSession),
        tap(() => this.authService.refreshSession())
      ),
    { dispatch: false }
  );

}
