import { AppConfig } from '@ifhms/admin/web/core/config';
import { APP_CONFIG_BASE } from './app-config-base';

export const APP_CONFIG_FMS_DEV: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'dev',
  production: false,
  apiUrl: 'https://admin-api.dev.fms.feedlothealth.com/api',
  adminApiUrl: 'https://admin-api.dev.fms.feedlothealth.com/api',
  signalRUrl: 'https://admin-api.dev.fms.feedlothealth.com/PushNotification',
  showDevFeature: true,
  authServerUrl: 'https://identity.dev.fms.feedlothealth.com',
  feedlotAppUrl: 'https://feedlot.dev.fms.feedlothealth.com'
};
