<ng-select
  #select
  class="sersi-single-select ng-select-control"
  [ngClass]="props['className']"
  [appendTo]="props['appendTo'] === null ? null : props['appendTo'] || 'body'"
  [bindValue]="props['bindValue']"
  [virtualScroll]="props['virtualScroll']"
  [items]="props['showInactiveItems'] ? (items$ | async)! : (activeItems$ | async)!"
  [attr.data-ele]="props['attrDataEle'] || key"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [readonly]="props['readonly'] ?? false"
  [bindLabel]="props['optionLabel']"
  [placeholder]="(props['placeholder$'] | async) || ''"
  [clearable]="props['showClear']"
  [searchFn]="customSearchFn"
  [labelForId]="id + '_input'"
  (open)="onDropdownOpen()"
  (close)="onDropdownClose()"
  [selectOnTab]="props['selectOnTab'] ?? true"
>
  <!-- Selected value template -->
  <ng-template ng-label-tmp let-item="item">
    <sersi-select-item 
      [className]="props['valueClassName']"
      [item]="item"
      [options]="allItems"
      [viewMode]="props['selectedItemLabel']"
    />
  </ng-template>

  <!-- Option item template -->
  <ng-template ng-option-tmp let-item="item">
    <sersi-select-item 
      [className]="props['optionClassName']"
      [disabled]="!!props.disabled"
      [item]="item"
      [viewMode]="props['optionsLabel']"
    />
  </ng-template>

  <ng-container *ngLet="props['emptyListText$'] | async as emptyListText">
    <ng-template ng-notfound-tmp>
      <div class="ng-option ng-option-disabled" [attr.title]="emptyListText">
        {{ (items$ | async)! ? emptyListText || 'No Items Found' : '' }}
      </div>
    </ng-template>
  </ng-container>
</ng-select>