import { createAction, props } from '@ngrx/store';

import { AnimalSelectorAnimalItem, AnimalSelectorSearchParams } from '../interfaces';

const NAMESPACE = '[Animal Selector]';

const search = createAction(
  `${NAMESPACE} Search`,
  props<{ searchParams: AnimalSelectorSearchParams }>()
);

const searchSuccess = createAction(
  `${NAMESPACE} Search Success`,
  props<{ animals: AnimalSelectorAnimalItem[] }>()
);

const setSelectedAnimals = createAction(
  `${NAMESPACE} Set Selected Animals`,
  props<{ animals: AnimalSelectorAnimalItem[] }>()
);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const AnimalSelectorActions = {
  search,
  searchSuccess,
  setSelectedAnimals,
  error,
  reset
};
