import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, CommonAppConfig } from '@common/angular/config';

import { Heap } from '../interfaces';

declare const heap: Heap;

@Injectable({ providedIn: 'root' })
export class HeapService {

  private isInitialized: boolean;

  constructor(@Inject(APP_CONFIG) private appConfig: CommonAppConfig) {}

  init(): void {
    const heapAppId = this.appConfig.integrations?.heapAppId;

    if (!heapAppId) return;
    heap.load(heapAppId);
    this.isInitialized = true;
  }

  track(event: string): void {
    if (!this.isInitialized) return;
    heap.track(event);
  }

  identify(userId: string): void {
    if (!this.isInitialized) return;
    heap.identify(userId);
  }

}
