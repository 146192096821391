import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { AuthFacade, FEATURE_NAME, reducer } from './+state';
import { AuthEffects } from './+state/auth.effects';
import {
  AUTH_CONFIG,
  AUTH_DEFAULT_CONFIG,
  AUTH_STORAGE,
  AuthServiceConfig,
  AuthModuleConfig,
  AuthStorage
} from './interfaces';
import { AUTH_SERVICE_TOKEN, AuthErrorHandlingService } from './services';
import { CommonOAuthService } from './services/oauth';
import {
  AuthErrHttpInterceptor,
  AuthRequestHeaderHttpInterceptor
} from './interceptors';

@NgModule({
  imports: [
    OAuthModule.forRoot(),
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([AuthEffects])
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class CommonAngularAuthModule {
  static forRoot(
    config: AuthModuleConfig
  ): ModuleWithProviders<CommonAngularAuthModule> {
    const authModuleConfig = {
      ...AUTH_DEFAULT_CONFIG,
      ...config
    } as AuthServiceConfig;
    return {
      ngModule: CommonAngularAuthModule,
      providers: [
        AuthEffects,
        AuthFacade,
        AuthErrorHandlingService,
        {
          provide: AUTH_SERVICE_TOKEN,
          useExisting: CommonOAuthService
        },
        {
          provide: AUTH_CONFIG,
          useValue: authModuleConfig
        },
        {
          provide: AUTH_STORAGE,
          useFactory: storageFactory,
          deps: [AUTH_CONFIG]
        },
        {
          provide: OAuthStorage,
          useFactory: storageFactory,
          deps: [AUTH_CONFIG]
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthRequestHeaderHttpInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthErrHttpInterceptor,
          multi: true
        }
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule?: CommonAngularAuthModule) {
    if (parentModule) {
      throw new Error(
        'AuthModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}

export function storageFactory(
  authModuleConfig?: AuthModuleConfig
): AuthStorage {
  return authModuleConfig?.useLocaleStorage ? localStorage : sessionStorage;
}
