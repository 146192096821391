import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Confirmation } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@UntilDestroy()
@Component({
  selector: 'ah-utils-common-error-dialog',
  templateUrl: 'common-error-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonErrorDialogComponent {
  translationNamespace = 'components.dialog';

  dialogData: Confirmation;

  constructor(
    private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.dialogData = this.config.data;
  }

  onClose(): void {
    this.dialogRef.close();
  }

}