import { MenuType } from '../models/menu-type.enum';
import { createReducer, on } from '@ngrx/store';
import { LayoutActions } from './layout.actions';

export interface State {
  menuType: MenuType;
}

export const initialState: State = {
  menuType: MenuType.MAIN
};

export const reducer = createReducer(
  initialState,

  on(LayoutActions.setMenu, (state, action) => ({
    ...state,
    menuType: action.menuType
  }))
);

export const getMenuType = (state: State): MenuType => state.menuType;
