import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyGroupListItemDto, CompanyGroupDto, CompanyGroupCreateDto } from '@ifhms/models/admin';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';

@Injectable()
export class CompanyGroupService {
  constructor(@Inject('apiUrl') private apiUrl: string, private http: HttpClient) {
  }

  get(companyId: string, groupId: string): Observable<CompanyGroupDto> {
    return this.http.get<CompanyGroupDto>(`${this.apiUrl}/Company/${companyId}/Groups/${groupId}`);
  }

  delete(companyId: string, groupId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/Company/${companyId}/Groups/${groupId}`);
  }

  create(companyId: string, group: CompanyGroupCreateDto): Observable<CompanyGroupDto> {
    return this.http.post<CompanyGroupDto>(`${this.apiUrl}/Company/${companyId}/Groups`, group);
  }
 
  addFeedlot(companyId: string, groupId: string, feedlotId: string): Observable<CompanyGroupDto> {
    return this.http.post<CompanyGroupDto>(`${this.apiUrl}/Company/${companyId}/Groups/${groupId}/AddFeedlot/${feedlotId}`, {});
  }

  deleteFeedlot(companyId: string, groupId: string, feedlotId: string): Observable<CompanyGroupDto> {
    return this.http.put<CompanyGroupDto>(`${this.apiUrl}/Company/${companyId}/Groups/${groupId}/RemoveFeedlot/${feedlotId}`, {} );
  }

  getList(companyId: string): Observable<CompanyGroupListItemDto[]> {
    return this.http.get<CompanyGroupListItemDto[]>(`${this.apiUrl}/Company/${companyId}/Groups/List`);
  }

  getGroupFeedlotList(companyId: string): Observable<SelectListItemWithTranslationDto[]> {
    return this.http.get<SelectListItemWithTranslationDto[]>(`${this.apiUrl}/Company/${companyId}/Groups/FeedlotSelectList`);
  }

  isFeedlotAvailable(companyId: string, groupId: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.apiUrl}/Company/${companyId}/Groups/IsFeedlotAvailable/${groupId}`, {} 
    );
  }

}

