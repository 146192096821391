import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TranslateService } from './translate.service';

@Injectable()
export class TranslationHttpInterceptor implements HttpInterceptor {

  private translateService = inject<TranslateService>(TranslateService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const localizedRequest = this.getRequestWithLocaleHeader(req);
    return next.handle(localizedRequest);
  }

  private getRequestWithLocaleHeader(req: HttpRequest<unknown>): HttpRequest<unknown> {
    const lang = this.translateService.activeLang;
    const headers = req.headers.set('Accept-Language', lang);
    return req.clone({ headers });
  }
}
