import * as Sentry from '@sentry/angular';
import { BuildConfigHelper } from '@common/angular/config';

export async function initSentry(dsn?: string, config: Record<string, any> = {}): Promise<void> {
  if (!dsn) return;

  const buildVersion = await BuildConfigHelper.getInstance().getBuildVersion();
  const isProfilingEnabled = config['environmentName'] === 'staging';
  const integrations = [ Sentry.browserTracingIntegration() ];

  if (isProfilingEnabled) {
    integrations.push(Sentry.browserProfilingIntegration());
  }

  Sentry.init({
    dsn,
    release: buildVersion?.toString(),
    integrations,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // TracePropagationTargets controls for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/feedlot-api\.frm\.feedlothealth\.com/,
      /^https:\/\/admin-api\.frm\.feedlothealth\.com/,
      /^https:\/\/identity\.frm\.feedlothealth\.com/,
      /^https:\/\/system-api\.frm\.feedlothealth\.com/
    ],
    // Session Replay
    replaysSessionSampleRate: 0.0, // Disable default replay sessions
    replaysOnErrorSampleRate: 0.0, // Disable error replay sessions
    // Profiling
    profilesSampleRate: isProfilingEnabled ? 1.0 : 0,
    // Client configuration overrides
    ...config
  });
}
