<div class="p-field">
  <label
    *ngIf="to.label && to['hideLabel'] !== true"
    [for]="id + '_input'"
    class="sersi-label"
  >
    {{ to.label }}
    <span
      *ngIf="to.required && to['hideRequiredMarker'] !== true"
      style="color: red;"
      aria-hidden="true"
      >*</span>
  </label>
  <ng-container #fieldComponent></ng-container>
  <small *ngIf="to['hint']" class='sersi-hint'>{{to['hint']}}</small>
</div>
<small *ngIf="showError" class="p-error">
  <formly-validation-message
    data-ele="validation-msg"
    class="ui-message-text"
    [field]="field"
  ></formly-validation-message>
</small>
