import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME, reducer } from './+state';
import { CompanyFacade } from './+state/company/company.facade';
import { CompanyEffects } from './+state/company/company.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([CompanyEffects])
  ],
  providers: [CompanyEffects, CompanyFacade]
})
export class IfhmsAdminWebDomainStateCompanyModule {}
