import { del, get, set } from 'idb-keyval';
import { downloadFile } from './file-download.helper';
import { isMacOS } from './browser.utils';

declare global {
  interface Window {
    showDirectoryPicker: (args: any) => void;
  }
}

export async function saveFileAtCustomPath(
  file: any,
  fileSaveName: string,
  fileType: string
): Promise<void> {
  try {
    const isMac = isMacOS();

    // Disable folder picker for Mac users
    if (isMac) {
      downloadFile(file, fileSaveName, fileType);
      return;
    }

    const directoryHandle = await getDirectoryHandle();

    const allowed = await verifyPermission(directoryHandle, true);

    if (allowed) {
      await writeFile(directoryHandle, file, fileSaveName, fileType);
      await persistDirectoryHandle(directoryHandle);
    } else {
      downloadFile(file, fileSaveName, fileType);
    }
  } catch {
    downloadFile(file, fileSaveName, fileType);
  }
}

async function getDirectoryHandle(): Promise<any> {
  const storedHandle = await getStoredDirectoryHandle();
  return !storedHandle ? showDirectoryHandleDialog() : storedHandle;
}

export async function getStoredDirectoryHandle(): Promise<any> {
  return await get('directoryHandle');
}

function showDirectoryHandleDialog(): void {
  return window.showDirectoryPicker({
    mode: 'readwrite'
  });
}

async function persistDirectoryHandle(directoryHandle: any): Promise<void> {
  return set('directoryHandle', directoryHandle);
}

export function removeDirectoryHandle(): Promise<void> {
  return del('directoryHandle');
}

async function verifyPermission(
  directoryHandle: {
    getFileHandle: (arg0: string, arg1: { create: boolean }) => any;
    queryPermission: (args: any) => Promise<string>;
    requestPermission: (args: any) => Promise<string>;
  },
  readWrite: boolean
): Promise<boolean> {
  const options = {
    mode: ''
  };

  if (readWrite) {
    options.mode = 'readwrite';
  }
  if ((await directoryHandle.queryPermission(options)) === 'granted') {
    return true;
  }
  return (await directoryHandle.requestPermission(options)) === 'granted';
}

async function writeFile(
  directoryHandle: {
    getFileHandle: (arg0: string, arg1: { create: boolean }) => any;
    queryPermission: (args: any) => Promise<string>;
    requestPermission: (args: any) => Promise<string>;
  },
  file: any,
  fileSaveName: string,
  fileType: string
): Promise<void> {
  const fileHandle = await directoryHandle.getFileHandle(fileSaveName, {
    create: true
  });
  const fileWriter = await fileHandle.createWritable();
  await fileWriter.write(new Blob([file], { type: fileType }));
  await fileWriter.close();
}
