import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { dateToIsoStringEndOfDate, dateToIsoStringStartOfDate, dateToStartOfDate } from '@common/angular/utils';

import {
  ProcedureCreateDto,
  ProcedureCreateRequestDto,
  ProcedureDto,
  ProcedureRequestDto,
  ProcedureUpdateDto, ProcedureUpdateRequestDto
} from '@ifhms/models/admin';
import { map, Observable } from 'rxjs';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class EnterpriseProceduresService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  add(procedure: ProcedureCreateDto): Observable<ProcedureDto> {
    const updatedProcedureCharge = this.adjustDates(procedure);
    return this.http.post<ProcedureDto>(`${this.apiUrl}/Procedures`, updatedProcedureCharge);
  }

  get(procedureId: string): Observable<ProcedureDto> {
    return this.http.get<ProcedureDto>(`${this.apiUrl}/Procedures/${procedureId}`).pipe(
      map((x: ProcedureDto) => {

        for (const detail of x.details) {
          detail.effectiveFromDate = new Date(detail.effectiveFromDate);
          detail.effectiveToDate &&= new Date(detail.effectiveToDate as unknown as Date);
          detail.lastBilled &&= new Date(detail.lastBilled as unknown as Date);
        }

        return x;
      })
    );
  }

  getList(): Observable<ProcedureDto[]> {
    return this.http.get<ProcedureDto[]>(`${this.apiUrl}/Procedures/List`);
  }

  delete(procedureId: string): Observable<unknown> {
    return this.http.delete(`${this.apiUrl}/Procedures/${procedureId}`);
  }

  update(procedure: ProcedureUpdateDto): Observable<ProcedureDto> {
    procedure.details = procedure.details.map((detail) => ({
      ...detail,
      effectiveFromDate: dateToStartOfDate(detail.effectiveFromDate)!,
      effectiveToDate: dateToStartOfDate(detail.effectiveToDate)
    }));

    procedure.operationId = null;

    console.log('update procedure', procedure);

    return this.http.put<ProcedureDto>(`${this.apiUrl}/Procedures`, procedure);
  }

  toggleActiveStatus(id: string): Observable<ProcedureDto> {
    return this.http.put<ProcedureDto>(`${this.apiUrl}/Procedures/ToggleActiveStatus/${id}`, null);
  }

  isCodeUnique(code: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { code }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Procedures/IsCodeValid`, options);
  }

  private adjustDates(procedure: ProcedureDto | ProcedureCreateDto | ProcedureUpdateDto): ProcedureRequestDto | ProcedureCreateRequestDto | ProcedureUpdateRequestDto {
    return {
      ...procedure,
      details: procedure.details.map((detail) => ({
        ...detail,
        effectiveFromDate: dateToIsoStringStartOfDate(detail.effectiveFromDate || new Date())!,
        effectiveToDate: dateToIsoStringEndOfDate(detail.effectiveToDate)
      }))
    };
  }
}
