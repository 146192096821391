import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../reducer';
import * as reducer from './re-handling.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.reHandlingSettings
);

const selectLoading = createSelector(selectState, reducer.getLoading);
const selectLoaded = createSelector(selectState, reducer.getLoaded);
const selectUpdating = createSelector(selectState, reducer.getUpdating);
const selectUpdated = createSelector(selectState, reducer.getUpdated);

const selectReHandlingSettings = createSelector(selectState, reducer.getReHandlingSettings);

export const ReHandlingSettingsSelectors = {
  selectState,
  selectLoaded,
  selectLoading,
  selectUpdating,
  selectUpdated,
  selectReHandlingSettings
};