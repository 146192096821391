import { EnterprisePermissionsDto } from '@ifhms/models/admin';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Enterprise Permissions]';

const getEnterprisePermissions = createAction(`${NAMESPACE} getEnterprisePermissions`);
const getEnterprisePermissionsSuccess = createAction(`${NAMESPACE} Get Enterprise Permissions Success`,
  props<{ enterprisePermissions: EnterprisePermissionsDto; }>());

const reset = createAction(`${NAMESPACE} Reset`);

const error = createAction(`${NAMESPACE} Error`, props<{ error: any }>());

export const EnterprisePermissionsActions = {
  getEnterprisePermissions,
  getEnterprisePermissionsSuccess,
  reset,
  error
};
