import { FeedlotReHandlingSettingsDto } from '@ifhms/models/admin';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[ReHandling] - ';

// ===== ReHandling Settings ======
const getReHandlingSettings = createAction(
  `${NAMESPACE} Get ReHandling Settings`
);

const getReHandlingSettingsSuccess = createAction(
  `${NAMESPACE} Get ReHandling Settings Success`,
  props<{ settings: FeedlotReHandlingSettingsDto }>()
);

const updateReHandlingSettings = createAction(
  `${NAMESPACE} Update ReHandling Settings`,
  props<{ settings: FeedlotReHandlingSettingsDto }>()
);

const updateReHandlingSettingsSuccess = createAction(
  `${NAMESPACE} Update ReHandling Settings Success`,
  props<{ settings: FeedlotReHandlingSettingsDto }>()
);

const resetReHandlingSettings = createAction(
  `${NAMESPACE} Reset ReHandling Settings`
);

const error = createAction(`${NAMESPACE} error`);

export const ReHandlingSettingsActions = {
  error,
  getReHandlingSettings,
  getReHandlingSettingsSuccess,
  updateReHandlingSettings,
  updateReHandlingSettingsSuccess,
  resetReHandlingSettings
};
