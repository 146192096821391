import { ActivatedRoute, ActivatedRouteSnapshot, Data, Router, RouterStateSnapshot } from '@angular/router';

export function getCurrentRouteWithData(router: Router): Data {
  if (!router?.routerState?.root) {
    return router as unknown as ActivatedRoute;
  }
  let route = router.routerState.root;
  let routeData = {};

  while (route.firstChild) {
    routeData = { ...routeData, ...route.snapshot.data }
    route = route.firstChild;
  }

  const snapshot = { ...route.snapshot, data: routeData }
  return { ...route, snapshot } as ActivatedRoute;
}

export function getCurrentRouteDataFromSnapshot(state: RouterStateSnapshot): Data {
  if (!state?.root) {
    return state as unknown as ActivatedRoute;
  }
  let route = state.root;
  let routeData = {};

  while (route.firstChild) {
    routeData = { ...routeData, ...route.data, ...route.params }
    route = route.firstChild;
  }

  return routeData;
}

export function getCurrentRouteChildDataFromSnapshot(state: ActivatedRouteSnapshot): Data {
  let route: ActivatedRouteSnapshot | null = state;
  let routeData = {};

  while (route) {
    routeData = { ...routeData, ...route.data }
    route = route.firstChild;
  }

  return routeData;
}