import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  TranslocoModule,
  TranslocoService,
  provideTransloco
} from '@jsverse/transloco';
import { TranslateService, TranslationHttpInterceptor } from './services';
import { TranslocoHttpLoader } from './tranloco-loader';
import { availableLangs } from './scoped-translations';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: availableLangs,
        fallbackLang: 'en',
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: false,
        missingHandler: {
          useFallbackTranslation: true
        }
      },
      loader: TranslocoHttpLoader
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TranslationHttpInterceptor,
      multi: true,
      deps: [ TranslocoService ]
    },
    TranslateService
  ]
})
export class CommonAngularTranslationLoaderModule {}
