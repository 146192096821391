import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-currency',
  templateUrl: './currency-type.component.html',
  styleUrls: ['./currency-type.component.scss']
})
export class CurrencyTypeComponent extends FieldType<FieldTypeConfig> {

  readonly maxInputLength = 22;

  override defaultOptions = {
    props: {
      label: '',
      placeholder: '',
      required: false,
      prefix: 'USD', // USD does not prefix price with "CAD" or "USD" just shows '$' IN CHROME
      useGrouping: true,
      disabled: false,
      minFractionDigits: 0,
      maxFractionDigits: 2,
      allowNegativeNumbers: true,
      min: -2147483647,
      max: 2147483647,
      locale: 'en-US',
      suffixDisplay: false,
      suffixAddOn: ''
    },
    validation: {
      messages: {
        max: 'Value has to be less than or equal to 2147483647',
        min: 'Value has to be greater than or equal to -2147483647'
      }
    }
  };
}
