import { FeedlotArrivalSettingsDto } from '@ifhms/models/admin';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Arrival] - ';

// ===== Arrival Settings ======
const getArrivalSettings = createAction(
  `${NAMESPACE} Get Arrival Settings`
);

const getArrivalSettingsSuccess = createAction(
  `${NAMESPACE} Get Arrival Settings Success`,
  props<{ settings: FeedlotArrivalSettingsDto }>()
);

const updateArrivalSettings = createAction(
  `${NAMESPACE} Update Arrival Settings`,
  props<{ settings: FeedlotArrivalSettingsDto }>()
);

const updateArrivalSettingsSuccess = createAction(
  `${NAMESPACE} Update Arrival Settings Success`,
  props<{ settings: FeedlotArrivalSettingsDto }>()
);

const resetArrivalSettings = createAction(
  `${NAMESPACE} Reset Arrival Settings`
);

const error = createAction(`${NAMESPACE} error`);

export const ArrivalSettingsActions = {
  error,
  getArrivalSettings,
  getArrivalSettingsSuccess,
  updateArrivalSettings,
  updateArrivalSettingsSuccess,
  resetArrivalSettings
};
