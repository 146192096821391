<ng-container *transloco="let t; read: translateScope">
    <div class="title-with-button-container">
        <h2>
            {{t('title')}}
            <span aria-hidden="true" class="required-indicator ng-star-inserted">*</span>
        </h2>
        <p-button *ngIf="displayActionButton" (click)="addNewCharge()" [label]="t('add-button-label')" [disabled]="isDisabled()" [rounded]="true"
                severity="secondary" raised="true" class="add-button" icon="pi pi-plus" iconPos="left" [attr.data-add-charges-ele]="'add-charges'">
        </p-button>
    </div>

    <ng-container *ngIf="field.fieldGroup && field.fieldGroup.length > 0; then showList; else showEmptyMessage"></ng-container>

    <ng-template #showList>
        <div *ngFor="let field of field.fieldGroup; let i = index">
            <p-divider *ngIf="i > 0"></p-divider>
            <div class="section">
                <div class="fields-container">
                    <formly-field [field]="field" [attr.data-charge-fields-ele]="i"></formly-field>
                </div>
                <div *ngIf="displayActionButton" class="delete-button-container">
                    <p-button pRipple
                            *ngIf="i === formControl.controls.length - 1 && !getLastBilled(i)"
                            (click)="removeCharge(i)"
                            type="button" icon="pi pi-trash"
                            [attr.data-delete-charge-btn-edit-ele]="i"
                            [rounded]="true"
                            text="true"
                            severity="danger"
                            >
                    </p-button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #showEmptyMessage>
        <p-messages class="banner-sm" [(value)]="emptyMessage" [closable]="false"></p-messages>
    </ng-template>
</ng-container>
<br>
