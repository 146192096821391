import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProductExpiryDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ProductExpiryService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  getList(operationId: string, productId: string): Observable<ProductExpiryDto[]> {
    return this.http.get<ProductExpiryDto[]>(`${this.apiUrl}/Operation/${operationId}/ProductExpiry/List/${productId}`);
  }
}