import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as reducer from './url-route-history.reducer';

export const selectState = createFeatureSelector<reducer.RouteHistoryState>(reducer.ROUTES_HISTORY_FEATURE_NAME);

const selectRoutes = createSelector(selectState, reducer.getRoutes);

export const UrlRouteHistorySelectors = {
  selectRoutes
};
