import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AnimalImportHistoryDto } from '@ifhms/models/admin';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ImportAnimalsHistoryService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  loadImportHistory(operationId: string): Observable<AnimalImportHistoryDto[]> {
    return this.http.get<AnimalImportHistoryDto[]>(
      `${this.apiUrl}/AnimalsImport/Feedlot/${operationId}/ImportHistory`
    );
  }

}
