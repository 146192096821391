import { ChangeDetectorRef, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, debounceTime, Observable } from 'rxjs';

@Component({
  selector: 'sersi-spin',
  templateUrl: './spin.component.html',
  styleUrls: ['./spin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpinComponent implements OnInit {
  readonly translateScope = 'spinner';
  @HostBinding('class.h-full') get isFullScreenHeight(): boolean {
    return !!this.isFullScreen;
  }

  @Input() loadingMessage$?: Observable<string>;
  @Input() hideContent?: boolean;
  @Input() isFullScreen?: boolean;
  @Input() startDelay = 0;

  @Input() set spin(value: boolean | null) {
    this.spinner$.next(!!value);
    this.handleExtendedTimeNotification();
  }

  isSpinning$: Observable<boolean>;

  private spinner$: BehaviorSubject<boolean>;
  private extendedMessageTimerId: number;

  showLongLoadingMessage: boolean;

  constructor(private cdr: ChangeDetectorRef) {
    this.spinner$ = new BehaviorSubject(false);
  }

  ngOnInit(): void {
    this.isSpinning$ = this.spinner$.asObservable().pipe(debounceTime(this.startDelay));
  }

  private handleExtendedTimeNotification(): void {
    if (this.spinner$.value) {
      this.addTimeoutMessage();
    }
    else {
      this.removeTimeoutMessage();
    }
  }

  private addTimeoutMessage(): void {
    this.extendedMessageTimerId = setTimeout(() => {
      this.showLongLoadingMessage = true;
      this.cdr.detectChanges();
    }, 2000) as unknown as number;
  }

  private removeTimeoutMessage(): void {
    this.showLongLoadingMessage = false;
    clearTimeout(this.extendedMessageTimerId);
  }
}
