import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectListItemDto } from '@ifhms/models/shared';
import { ModuleEnablementDto, OperationSettingsPmIntegrationDto } from '@ifhms/models/admin';

@Injectable()
export class ModuleEnablementService {
  constructor(private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string) {
  }

  get(): Observable<SelectListItemDto[]> {
    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Enterprise/OperationsList`);
  }

  getFeatureSettings(operationId: string): Observable<ModuleEnablementDto> {
    return this.http.get<ModuleEnablementDto>(`${this.apiUrl}/Enterprise/Operation/${operationId}/Modules`);
  }

  updateFeatureSettings(
    operationId: string,
    featureSettings: ModuleEnablementDto
  ): Observable<ModuleEnablementDto> {
    return this.http.put<ModuleEnablementDto>(
      `${this.apiUrl}/Enterprise/Operation/${operationId}/Modules`,
      featureSettings
    );
  }

  updatePostMortemSettings(
    operationId: string,
    featureSettings: OperationSettingsPmIntegrationDto
  ): Observable<OperationSettingsPmIntegrationDto> {
    return this.http.put<OperationSettingsPmIntegrationDto>(
      `${this.apiUrl}/Enterprise/Operation/${operationId}/Modules`,
      featureSettings
    );
  }
}