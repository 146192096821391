import { SersiNavListItem } from '@sersi/angular/ui/side-nav';

export enum AdminNavigationMenu {
  Company,
  Enterprise,
  Operation
}

export type CompanyMenuItemsFn = (companySlug: string) => SersiNavListItem[];
export type FeedlotMenuItemsFn = (
  companySlug: string,
  feedlotId: string,
  isDevelopmentMode?: boolean
) => SersiNavListItem[];
