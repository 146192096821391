import { Component, ViewEncapsulation } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'sersi-group-wrapper',
  templateUrl: './group-wrapper.component.html',
  styleUrls: ['./group-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GroupWrapperComponent extends FieldWrapper {
  override defaultOptions = {
    props: {
      required: false,
      showBorder: true,
      noSpacing: false
    }
  };
}
