import { CompanyPermissionsDto } from '@ifhms/models/admin';
import { createReducer, on } from '@ngrx/store';
import { CompanyPermissionsActions } from './company-permissions.actions';

export interface State {
  companyPermissions: CompanyPermissionsDto | null;
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  companyPermissions: null,
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,

  on(CompanyPermissionsActions.getCompanyPermissions, (state, action) => {
    return {
      ...state,
      loading: true,
      loaded: false
    }
  }),

  on(CompanyPermissionsActions.getCompanyPermissionsSuccess, (state, action) => {
    return {
      ...state,
      companyPermissions: action.companyPermissions,
      loading: false,
      loaded: true
    }
  }),

  on(CompanyPermissionsActions.reset, () => initialState)
);

export const getCompanyPermissions = (state: State): CompanyPermissionsDto | null => state.companyPermissions;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
