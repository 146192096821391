import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeedlotArrivalSettingsDto, FeedlotDefaultValuesSettingsDto, FeedlotPostMortemSettingsDto, FeedlotPricingSettingsDto, FeedlotReHandlingSettingsDto } from '@ifhms/models/admin';
import { ArrivalSettingsFacade } from './arrival/arrival.facade';
import { PostMortemSettingsFacade } from './post-mortem/post-mortem.facade';
import { ReHandlingSettingsFacade } from './re-handling/re-handling.facade';
import { DefaultValuesSettingsFacade } from './default-values/default-values.facade';
import { PricingSettingsFacade } from './pricing/pricing.facade';

@Injectable()
export class FeedlotSettingsFacade {
  arrivalSettings$: Observable<FeedlotArrivalSettingsDto | null>;
  postMortemSettings$: Observable<FeedlotPostMortemSettingsDto | null>;
  reHandlingSettings$: Observable<FeedlotReHandlingSettingsDto | null>;
  defaultValuesSettings$: Observable<FeedlotDefaultValuesSettingsDto | null>;
  pricingSettings$: Observable<FeedlotPricingSettingsDto | null>;

  constructor(
    private arrivalSettingsFacade: ArrivalSettingsFacade,
    private postMortemSettingsFacade: PostMortemSettingsFacade,
    private reHandlingSettingsFacade: ReHandlingSettingsFacade,
    private defaultValuesSettingsFacade: DefaultValuesSettingsFacade,
    private pricingSettingsFacade: PricingSettingsFacade
  ) {
    this.arrivalSettings$ = this.arrivalSettingsFacade.arrivalSettings$;
    this.postMortemSettings$ = this.postMortemSettingsFacade.postMortemSettings$
    this.reHandlingSettings$ = this.reHandlingSettingsFacade.reHandlingSettings$
    this.defaultValuesSettings$ = this.defaultValuesSettingsFacade.defaultValuesSettings$
    this.pricingSettings$ = this.pricingSettingsFacade.pricingSettings$
  }
}
