import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageHubService } from './services/message-hub.service';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';

@NgModule({
  imports: [CommonModule],
  providers: [MessageHubService, provideHttpClient(withInterceptorsFromDi())]
})
export class IfhmsCommonAngularApiMessageHubModule {}
