import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-accordion-tab-type',
  templateUrl: './accordion-tab-type.component.html',
  styleUrls: ['./accordion-tab-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionTabTypeComponent extends FieldType<FieldTypeConfig> {

  override defaultOptions = {
    props: {
      multiple: true,
      activeIndex: []
    }
  }

}
