import { Component, ViewEncapsulation } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { validateHexCodeControl } from '@common/angular/utils';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-color-picker',
  templateUrl: './color-picker-type.component.html',
  styleUrls: ['./color-picker-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ColorPickerTypeComponent extends FieldType<FieldTypeConfig> {

  override defaultOptions = {
    props: {
      label: 'Colour Code',
      inline: false,
      required: false,
      disabled: false
    }
  };

  validHexCode(control: AbstractControl): boolean {
    return validateHexCodeControl(control);
  }
}
