import { Injectable } from '@angular/core';
import { OperationPermissionsService } from '@ifhms/admin/web/domain/permissions';
import { OperationFacade } from '@ifhms/admin/web/domain/state/operation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, withLatestFrom } from 'rxjs';
import { catchError, exhaustMap, map, timeout } from 'rxjs/operators';
import { OperationPermissionsActions } from './operation-permissions.actions';

@Injectable()
export class OperationPermissionsEffects {

  private readonly loginTimeout = 10 * 1000; // 10 seconds

  constructor(
    private readonly actions$: Actions,
    private operationFacade: OperationFacade,
    private operationPermissionsService: OperationPermissionsService
  ) {
  }

  getOperationPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationPermissionsActions.getOperationPermissions),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([, operationId]) =>
        this.operationPermissionsService.getOperationPermissionsForUser(operationId).pipe(
          map((operationPermissions) => OperationPermissionsActions.getOperationPermissionsSuccess({ operationPermissions })),
          timeout(this.loginTimeout),
          catchError(err => of(OperationPermissionsActions.error(err)))
        )
      )
    )
  );

}
