import { DEFAULT_LANGUAGE, TranslationDescription } from '../interfaces';

export function getLanguageCode(lang: string): string {
  return lang?.split('-')[0] || DEFAULT_LANGUAGE;
}

export function getLanguageLabelKey(lang: string): string {
  if (lang === 'en-US') return 'enUs';
  if (lang === 'ru') return 'kk';
  return getLanguageCode(lang);
}

export function getLocalizedLabel(value: TranslationDescription, lang: string): string {
  const langLabelKey = getLanguageLabelKey(lang);
  const result = value[langLabelKey as keyof TranslationDescription]?.trim() || value.none || value.en;
  return result || '-';
}
