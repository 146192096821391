import { createReducer, on } from '@ngrx/store';
import { FeedlotArrivalSettingsDto } from '@ifhms/models/admin';
import { ArrivalSettingsActions } from './arrival.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  updating: boolean;
  updated: boolean;
  arrivalSettings: FeedlotArrivalSettingsDto | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  arrivalSettings: null,
  updating: false,
  updated: false
};

export const reducer = createReducer(
  initialState,

  on(ArrivalSettingsActions.getArrivalSettings,
    (state) => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),

  on(ArrivalSettingsActions.getArrivalSettingsSuccess,
    (state, action) => ({
      ...state,
      arrivalSettings: action.settings,
      loading: false,
      loaded: true
    })
  ),

  on(
    ArrivalSettingsActions.updateArrivalSettings,
    state => ({
      ...state,
      updating: true
    })
  ),

  on(ArrivalSettingsActions.updateArrivalSettingsSuccess,
    (state, action) => (
      {
        ...state,
        arrivalSettings: action.settings,
        updating: false
      })
  ),

  on(ArrivalSettingsActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false,
    updating: false,
    updated: false
  })),

  on(ArrivalSettingsActions.resetArrivalSettings,
    (state) => ({
      ...state,
      arrivalSettings: null
    })
  )
);

export const getArrivalSettings = (state: State): FeedlotArrivalSettingsDto | null => state.arrivalSettings;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getUpdating = (state: State): boolean => state.updated;
export const getUpdated = (state: State): boolean => state.updating;
