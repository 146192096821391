import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BuildConfigService } from '@common/angular/config';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { Observable, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {

  private http = inject(HttpClient);
  private buildConfigService = inject(BuildConfigService);

  getTranslation(lang: string): Observable<Translation> {
    return this.buildConfigService.getBuildVersion().pipe(
      switchMap(buildVersion => {
        buildVersion ||= new Date().getTime().toString();
        return this.http.get<Translation>(`/assets/i18n/${lang}.json?${buildVersion}`);
      })
    )
  }
}
