<span class="p-fluid">
  <p-autoComplete
    [attr.data-ele]="key"
    [formControl]="formControl"
    [forceSelection]="props['forceSelection']"
    [suggestions]="(props['suggestions'] | async) || []"
    [placeholder]="(props['placeholder$'] | async) || ''"
    [optionLabel]="props['optionsLabel'] || null"
    [delay]="props['searchDelay']"
    [autoHighlight]="!!props['autoHighlight']"
    [required]="props['required'] || false"
    [showEmptyMessage]="!!props['emptyMessage$']"
    [appendTo]="'body'"
    [emptyMessage]="(props['emptyMessage$'] | async) || ''"
    (completeMethod)="props['onComplete']?.($event)"
    (onSelect)="props['onSelect']?.($event)"
    (onBlur)="onBlur($event)">
  </p-autoComplete>
</span>
