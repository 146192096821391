import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { AgeClassesActions } from './age-classes.actions';
import * as reducer from './age-classes.reducer';
import { AgeClassesSelectors } from './age-classes.selectors';

@Injectable()
export class AgeClassesFacade {

  loaded$ = this.store.select(AgeClassesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(AgeClassesSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}

  get(): void {
    this.store.dispatch(AgeClassesActions.get());
  }
}
