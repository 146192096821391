import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CommonPermissionsMap, CommonPermissionsService } from '@common/angular/permissions';
import { getCurrentRouteWithData } from '@common/angular/utils';
import { CompanyFacade } from '@ifhms/admin/web/domain/state/company';
import { AH_FMS_ADMIN_API_CONFIG } from '@ifhms/common/angular/data-access/admin-api';
import { CompanyPermissionsDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CompanyPermissionsService extends CommonPermissionsService {

  constructor(
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string,
    private http: HttpClient,
    private companyFacade: CompanyFacade,
    private router: Router
  ) {
    super();
    this.permissions$ = this.companyFacade.companyPermissions$ as Observable<CommonPermissionsMap | null>;
  }

  protected override getLoadedPermissions(): CommonPermissionsMap | null {
    return this.loadedPermissions || this.getRouteResolverPermissions();
  }

  private getRouteResolverPermissions(): CommonPermissionsMap | null {
    const routeData = getCurrentRouteWithData(this.router);
    return routeData['snapshot']?.data?.company?.permissions;
  }

  getCompanyPermissionsForUser(companyId: string): Observable<CompanyPermissionsDto> {
    return this.http.get<CompanyPermissionsDto>(`${this.apiUrl}/Company/${companyId}/Permissions`);
  }

}
