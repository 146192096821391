import {
  filter,
  lastValueFrom,
  map,
  NEVER,
  Observable,
  pairwise,
  skipLast,
  startWith,
  take
} from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { isEqual } from 'lodash-es';

export function toPromise<T>(observable: Observable<T>): Promise<T> {
  return lastValueFrom(
    observable.pipe(take(1))
  );
}

export function getUniqueValueChange<T>(formControl?: AbstractControl | null, defaultValue = null): Observable<T> {
  return formControl?.valueChanges.pipe(
    startWith(null, formControl?.value || defaultValue),
    pairwise(),
    filter(([prev, next]) => !isEqual(prev, next)),
    map(([, next]) => next)
  ) || NEVER;
}

export function getPreviousValue<T>(source$: Observable<T>): Observable<T> {
  return source$.pipe(skipLast(1));
}
