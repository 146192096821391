<div *ngFor="let option of field.fieldGroup;  let i = index">
  <p-checkbox
      class="mt-3"
      [ngClass]="props['customClass']"
      [inputId]="id + '_' + i"
      [formControl]="$any(option.formControl)"
      [label]="option.props?.['label'] || 'Option'"
      [formlyAttributes]="option"
      [name]="field.props['key']"
      [binary]="true"
  ></p-checkbox>

</div>
