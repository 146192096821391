export interface SersiSelectListItem {
  id: string | number;
  code: string;
  slug?: string;
  description: string;
  codeDescription?: string | null;
  isActive?: boolean;
  attributes?: {
    [key: string]: boolean | string | string[] | number | number[];
  };
}

export enum SersiSelectListItemViewMode {
  CODE = 'CODE',
  CODE_DESCRIPTION = 'CODE_DESCRIPTION',
  DESCRIPTION = 'DESCRIPTION',
  DESCRIPTION_OR_CODE = 'DESCRIPTION_OR_CODE'
}

export enum SersiSelectListUpdateEventType {
  OPTIONS_LIST_UPDATE = 'options_list_update'
}
