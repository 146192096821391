import { EnterprisePermissionsDto } from '@ifhms/models/admin';
import { createReducer, on } from '@ngrx/store';
import { EnterprisePermissionsActions } from './enterprise-permissions.actions';

export interface State {
  enterprisePermissions: EnterprisePermissionsDto | null;
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  enterprisePermissions: null,
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,

  on(EnterprisePermissionsActions.getEnterprisePermissions, (state) => {
    return {
      ...state,
      loading: true,
      loaded: false
    }
  }),

  on(EnterprisePermissionsActions.getEnterprisePermissionsSuccess, (state, action) => {
    return {
      ...state,
      enterprisePermissions: action.enterprisePermissions,
      loading: false,
      loaded: true
    }
  }),

  on(EnterprisePermissionsActions.reset, () => initialState)
);

export const getEnterprisePermissions = (state: State): EnterprisePermissionsDto | null => state.enterprisePermissions;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
