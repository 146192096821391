<p class="mt-0">{{ dialogData.message }}</p>
<div class="container-footer-buttons">
  <p-button
    iconPos="left"
    class="mr-3"
    size="small"
    severity="secondary"
    data-ele="cancel-btn"
    [ngClass]="dialogData.rejectButtonStyleClass"
    [icon]="dialogData.rejectIcon || ''"
    [label]="dialogData.rejectLabel!"
    [text]="true"
    [rounded]="true"
    (click)="onCancel()"
  >
  </p-button>
  <p-button
    iconPos="left"
    class="p-button-primary"
    data-ele="confirm-btn"
    size="small"
    [ngClass]="dialogData.acceptButtonStyleClass"
    [rounded]="true"
    (click)="onConfirm()"
  >
   <i *ngIf="dialogData.acceptIcon !== 'none'"  class="material-icons-outlined mr-1"> {{ dialogData.acceptIcon || 'check' }}</i>
   {{ dialogData.acceptLabel! }}
  </p-button>
</div>
