<div
  class="p-field default-form-field-wrapper"
  [ngClass]="props['wrapperClass']"
  [class.field-error]="showError"
  [pTooltip]="
    showError && props['showErrInTooltip']
      ? (validationMessage?.errorMessage$ | async)
      : $any(null)
  "
>
  <label
    *ngIf="(props.label || props['label$']) && props['hideLabel'] !== true"
    [for]="id + '_input'"
    class="sersi-label"
    [attr.title]="(props['label$'] | async) || props['label'] || null"
    [ngClass]="showError && props.required ? 'p-error' : ''"
  >
    <span
      *ngIf="props.required && props['hideRequiredMarker'] !== true"
      class="required-indicator"
      aria-hidden="true"
      >*</span
    >
    <sersi-form-text-label [props]="props" />
    <i
      *ngIf="props['helpText'] as helpText"
      class="pi pi-question-circle help-circle"
      [pTooltip]="helpText"
    >
    </i>
  </label>

  <ng-container #fieldComponent></ng-container>
  <small
    class="p-error"
    [class.hidden]="!showError || props['showErrInTooltip']"
  >
    <div class="flex flex-row">
    <i *ngIf="props.required" class="material-icons-outlined text-sm pr-1"> error </i>
    <formly-validation-message
      #validationMessage
      data-ele="validation-msg"
      class="ui-message-text"
      [field]="field"
    ></formly-validation-message>
  </div>
  </small>
  <small *ngIf="props['hint'] && !showError" class="sersi-hint text-break-word">
    {{ props['hint'] }}
  </small>
</div>
