<button
  pButton
  type="button"
  [class]="props['buttonClass']"
  [formlyAttributes]="field"
  [icon]="props['buttonIcon']"
  (click)="onClick()"
  [iconPos]="props['iconPos'] || 'left'"
  [label]="(props['label$'] | async) || ''"
  [disabled]="props['disabled'] || false">
</button>
