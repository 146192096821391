import { Injectable } from '@angular/core';
import { ReferenceDataTxGroupService } from '@ifhms/common/angular/data-access/admin-api';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { TxGroupsActions } from './tx-groups.actions';

@Injectable({
  providedIn: 'root'
})
export class TxGroupsEffects {
  constructor(
    private actions$: Actions,
    private txGroupService: ReferenceDataTxGroupService
  ) {}
  
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TxGroupsActions.get),
      exhaustMap(() =>
        this.txGroupService.get()
          .pipe(
            map((list: SelectListItemWithTranslationDto[]) => {
              if (!list) return TxGroupsActions.error();
              const items = this.transformSelectList(list);
              return TxGroupsActions.getSuccess({ items });
            }),
            catchError(() => of(TxGroupsActions.error()))
          )
      )
    )
  );

  private transformSelectList(list: SelectListItemWithTranslationDto[]): SelectListItemWithTranslationDto[] {
    return list.map(item => ({
      ...item,
      description: {
        ...item.description,
        // TODO: Remove this temp override when TX Group is available on ref data management
        es: item.description.en === 'Default Protocol' ? 'Protocolo predeterminado' : item.description.en
      }
    }));
  }
}
  