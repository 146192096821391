<ng-container *transloco="let t; read: translateScope">
  <div class="table-wrapper">
    <ifhms-animal-selector-table
            [attr.data-ele]="'available-animals-section-table'"
            class="animal-selector-table-component"
            [animals]="availableAnimals"
            [animalsSelection]="availableAnimalsSelection"
            [tableHeader]="t('available-animals-table-header')"
            [selectedItems]="availableAnimalsSelection"
            (selectionChange)="availableAnimalsSelectionChange($event)"
    />
    <div class="active-animals-only-checkbox">
      <p-checkbox [(ngModel)]="activeAnimalsOnly" (onChange)="onActiveAnimalsOnlyChange()" [binary]="true" [label]="t('active-animals-label')"></p-checkbox>
    </div>
  </div>
  <div class="flex gap-3 justify-content-center box-action-buttons">
    <p-button
            icon="pi pi-chevron-right"
            data-ele="move-to-right-btn"
            (onClick)="addAnimalsToSelectedList()"
            [disabled]="!availableAnimalsSelection.length"
            [rounded]="true"
    />
    <p-button
            icon="pi pi-chevron-left"
            data-ele="move-to-left-btn"
            (onClick)="removeAnimalsFromSelectedList()"
            [disabled]="!selectedAnimalsSelection.length"
            [rounded]="true"
    />
  </div>
  <ifhms-animal-selector-table
          class="animal-selector-table-component"
          [animals]="selectedAnimals || []"
          [animalsSelection]="selectedAnimalsSelection"
          [tableHeader]="t('selected-animals-table-header')"
          [selectedItems]="selectedAnimalsSelection"
          (selectionChange)="selectedAnimalsSelectionChange($event)"
  />
</ng-container>