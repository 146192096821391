import { Injectable } from '@angular/core';

import { AuthFacade, AuthState } from '@common/angular/auth';

import { filter, finalize, first, Observable, switchMap, tap } from 'rxjs';

import { CommonUserProfileFacade, State } from '../+state';

@Injectable()
export class CommonAuthUserProfileResolver {

  loading = false;

  constructor(
    private readonly StockFlowUserProfileFacade: CommonUserProfileFacade,
    private readonly authFacade: AuthFacade
  ) {
  }

  resolve(): Observable<State> {
    return this.authFacade.state$.pipe(
      filter((autState: AuthState) => autState.isAuthenticated),
      switchMap(authState => {
        return this.StockFlowUserProfileFacade.state$.pipe(
          tap((userProfileState: State) => {
            if (!this.loading && !userProfileState.loaded) {
              this.loading = true;
              this.StockFlowUserProfileFacade.get(authState.authUser?.id as string);
            }
          }),
          filter((userProfileState: State) => userProfileState.loaded),
          first(),
          finalize(() => (this.loading = false))
        );
      })
    )
  }

}
