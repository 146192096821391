<div [ngClass]="props['className']">
<div class="p-field-radiobutton" *ngFor="let option of props.options | formlySelectOptions: field | async; let i =index">
  <p-radioButton
   [attr.data-index-num-ele]='i'
    [name]="field.name || id"
    [formControl]="formControl"
    [label]="option.label"
    [value]="option.value"
    >
  </p-radioButton>
</div>
</div>

