import { Injectable } from '@angular/core';
import { ReferenceDataType } from '@ifhms/models/admin';
import { ReferenceDataService } from '@ifhms/common/angular/data-access/admin-api';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { ContextService } from '@ifhms/common/angular/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';
import { DoseUnitsActions } from './dose-units.actions';

@Injectable({
  providedIn: 'root'
})
export class DoseUnitsEffects {
  constructor(
    private actions$: Actions,
    private contextService: ContextService,
    private service: ReferenceDataService
  ) {
  }

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoseUnitsActions.get),
      withLatestFrom(this.contextService.appContext$),
      exhaustMap(([, appContext]) => {
        return this.service.getSelectListByType(
          appContext,
          ReferenceDataType.DoseUnit
        ).pipe(
          map((items: SelectListItemWithTranslationDto[]) => {
            if (!items) DoseUnitsActions.error();
            return DoseUnitsActions.getSuccess({ items });
          }),
          catchError(() => of(DoseUnitsActions.error()))
        );
      })
    )
  );
}
