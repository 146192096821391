<ng-select class="sersi-multi-select ng-select-control" appendTo="body" [bindValue]="props['bindValue']"
    [virtualScroll]="props['virtualScroll']" [items]="activeItems$ | async"
    [attr.data-ele]="props['attrDataEle'] || key" [formControl]="formControl" [formlyAttributes]="field"
    [bindLabel]="props['optionLabel']" [placeholder]="(props['placeholder$'] | async) || ''"
    [clearable]="props['showClear']" [searchFn]="customSearchFn" [multiple]="true" [closeOnSelect]="false"
    [pTooltip]="props.disabled ? getTooltipText() : $any(null)" (open)="onDropdownOpen()" (close)="onDropdownClose()"
    (onChange)="props.change && props.change(field, $event)"
    [ngClass]="props['enableSearch'] ? 'display-search-input' : 'hide-search-input'">
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <ng-container *ngIf="props['limit']">
            <div class="ng-value" *ngFor="let item of items | slice:0:props['limit']">
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                <span class="ng-value-label">
                    <sersi-select-item [className]="props['valueClassName']" [item]="$any(item)" [options]="allItems"
                        [viewMode]="props['selectedItemLabel']" />
                </span>
            </div>
        </ng-container>

        <div class="ng-value-icon ng-value" *ngIf="props['limit'] === 0; else moreThanZero">
            <span class="ng-value-label more-items">{{ items.length }} selected</span>
        </div>

        <ng-template #moreThanZero>
            <div class="ng-value-icon ng-value" *ngIf="items.length > props['limit']">
                <span class="ng-value-label more-items">
                    {{items.length - props['limit']}}
                    {{props['labelMore']}}
                </span>
            </div>
        </ng-template>

    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="flex">
            <p-checkbox class="mr-3" inputId="item-{{index}}" [binary]="true" [ngModel]="item$.selected"></p-checkbox>
            <sersi-select-item [className]="props['optionClassName']" [item]="item"
                [viewMode]="props['optionsLabel']" />
        </div>
    </ng-template>

    <ng-container *ngLet="props['emptyListText$'] | async as emptyListText">
        <ng-template ng-notfound-tmp>
            <div class="ng-option ng-option-disabled" [attr.title]="emptyListText">
                {{ (items$ | async)! ? emptyListText || 'No Items Found' : '' }}
            </div>
        </ng-template>
    </ng-container>
</ng-select>