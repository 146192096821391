import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  CompaniesService,
  CompanyGroupService,
  CompanyProductManagementService,
  CompanyProductMappingService,
  CompanyRolePermissionsService,
  CompanyRoleService,
  CompanyUserService,
  EnterpriseAdgSettingsManagementService,
  EnterpriseProceduresService,
  EnterpriseProductManagementService,
  EnterpriseRoleService,
  EnterpriseTreatmentProtocolManagementService,
  EnterpriseTreatmentProtocolRegimesService,
  EnterpriseUserService,
  FeedlotAdgSettingsManagementService,
  FeedlotCustomFieldsSettingsService,
  FeedlotItsGlobalSettingsService,
  FeedlotRolePermissionsService,
  FeedlotRoleService,
  FeedlotSettingsAdgService,
  FeedlotSettingsProFeederService,
  FeedlotSettingsService,
  FeedlotSettingsTargetWeightService,
  FeedlotSortGroupTemplatesSettingsService,
  FeedlotTagSettingsService,
  FeedlotTreatmentProtocolSettingsService,
  FeedlotUserService,
  FeedlotZeroScaleReminderSettingsService,
  ImportAnimalsDetailService,
  ImportAnimalsHistoryService,
  ImportAnimalsUploadService,
  ImportTreatmentProtocolService,
  LotBillingManagementService,
  OperationProceduresService,
  OperationProductManagementService,
  OperationProductMappingService,
  OperationsService,
  OperationTreatmentProtocolManagementService,
  OperationTreatmentProtocolRegimesService,
  ProceduresManagementService,
  ProductExpiryService,
  ProductManagementService,
  ProductMappingService,
  ProductPricingService,
  ProductReceiptService,
  ProductReconcileService,
  ProductTransactionService,
  ReferenceDataCountryService,
  ReferenceDataLabourChargesService,
  ReferenceDataManagementService,
  ReferenceDataOtherFeesService,
  ReferenceDataService,
  ReferenceDataTxGroupService,
  ReferenceDataUnitsOfMeasureService,
  ReleaseNotesService,
  TreatmentProtocolManagementService,
  TreatmentProtocolRegimesService
} from './services';

@NgModule({
  imports: [CommonModule],
  providers: [
    CompaniesService,
    CompanyGroupService,
    CompanyProductManagementService,
    CompanyProductMappingService,
    CompanyRolePermissionsService,
    CompanyRoleService,
    CompanyUserService,
    EnterpriseProceduresService,
    EnterpriseProductManagementService,
    EnterpriseRoleService,
    EnterpriseUserService,
    FeedlotCustomFieldsSettingsService,
    FeedlotItsGlobalSettingsService,
    FeedlotRolePermissionsService,
    FeedlotRoleService,
    FeedlotSettingsService,
    FeedlotZeroScaleReminderSettingsService,
    FeedlotSortGroupTemplatesSettingsService,
    FeedlotTagSettingsService,
    FeedlotTreatmentProtocolSettingsService,
    FeedlotUserService,
    ImportAnimalsDetailService,
    ImportAnimalsUploadService,
    ImportAnimalsHistoryService,
    LotBillingManagementService,
    OperationProceduresService,
    OperationProductManagementService,
    OperationProductMappingService,
    OperationsService,
    FeedlotSettingsTargetWeightService,
    ProceduresManagementService,
    ProductExpiryService,
    ProductManagementService,
    ProductMappingService,
    ProductPricingService,
    ProductReceiptService,
    ProductReconcileService,
    ProductTransactionService,
    ReferenceDataCountryService,
    ReferenceDataLabourChargesService,
    ReferenceDataManagementService,
    ReferenceDataOtherFeesService,
    ReferenceDataService,
    ReferenceDataTxGroupService,
    ReferenceDataUnitsOfMeasureService,
    ReleaseNotesService,
    TreatmentProtocolManagementService,
    EnterpriseTreatmentProtocolManagementService,
    OperationTreatmentProtocolManagementService,
    FeedlotSettingsAdgService,
    TreatmentProtocolRegimesService,
    EnterpriseTreatmentProtocolRegimesService,
    OperationTreatmentProtocolRegimesService,
    FeedlotAdgSettingsManagementService,
    EnterpriseAdgSettingsManagementService,
    ImportTreatmentProtocolService,
    FeedlotSettingsProFeederService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class IfhmsCommonAngularDataAccessAdminApiModule {
}
