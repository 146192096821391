import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(fieldVal: string | null, emptyPlaceholder = '-'): string | number {

    if (!fieldVal) {
      return emptyPlaceholder;
    }

    const cleaned = fieldVal.replace(/\D/g, '');

    if (cleaned.length === 7) {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    }
    else if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    }

    return fieldVal;
  }
}
