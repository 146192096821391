import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as reducer from './arrival.reducer';
import { Observable } from 'rxjs';
import { FeedlotArrivalSettingsDto } from '@ifhms/models/admin';
import { ArrivalSettingsActions } from './arrival.actions';
import { ArrivalSettingsSelectors } from './arrival.selectors';

@Injectable()
export class ArrivalSettingsFacade {

  loading$: Observable<boolean> = this.store.select(ArrivalSettingsSelectors.selectLoading);
  loaded$: Observable<boolean> = this.store.select(ArrivalSettingsSelectors.selectLoaded);
  updating$: Observable<boolean> = this.store.select(ArrivalSettingsSelectors.selectUpdating);
  updated$: Observable<boolean> = this.store.select(ArrivalSettingsSelectors.selectUpdated);
  arrivalSettings$: Observable<FeedlotArrivalSettingsDto | null> = this.store.select(ArrivalSettingsSelectors.selectArrivalSettings);

  constructor(private store: Store<reducer.State>) {}
  
  getArrivalSettings(): void {
    this.store.dispatch(ArrivalSettingsActions.getArrivalSettings());
  }

  updateArrivalSettings(settings: FeedlotArrivalSettingsDto): void {
    this.store.dispatch(ArrivalSettingsActions.updateArrivalSettings({ settings }));
  }

  resetArrivalSettings(): void {
    this.store.dispatch(ArrivalSettingsActions.resetArrivalSettings());
  }
}
