import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ProductReconcileDto,
  ProductReconcileTableRequestDto,
  ProductReconcileTableResultDto
} from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class ProductReconcileService {

  constructor(private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string) {
  }

  get(operationId: string, reconcileId: string): Observable<ProductReconcileDto> {
    return this.http.get<ProductReconcileDto>(`${this.apiUrl}/Operation/${operationId}/ProductReconcile/${reconcileId}`);
  }

  generateWorksheetReport(operationId: string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: operationId
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/PhysicalInventoryWorksheet`, options);
  }

  generateWorksheetReportPending(operationId: string, reconcileId: string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: operationId,
            reconciliationId: reconcileId
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/InventoryAdjustmentProof`, options);
  }

  generateSummaryReportPending(operationId: string, reconcileId: string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: operationId,
            reconciliationId: reconcileId
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/ProductReconciliationSummary`, options);
  }

  generateSummaryReportComplete(operationId: string, reconcileId: string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: operationId,
            reconciliationId: reconcileId
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/ProductReconciliationSummary`, options);
  }

  create(operationId: string): Observable<ProductReconcileDto> {
    return this.http.post<ProductReconcileDto>(`${this.apiUrl}/Operation/${operationId}/ProductReconcile`, null);
  }

  save(operationId: string, productReconcile: ProductReconcileDto): Observable<ProductReconcileDto> {
    return this.http.put<ProductReconcileDto>(`${this.apiUrl}/Operation/${operationId}/ProductReconcile`, productReconcile);
  }

  review(operationId: string, productReconcile: ProductReconcileDto): Observable<ProductReconcileDto> {
    return this.http.put<ProductReconcileDto>(`${this.apiUrl}/Operation/${operationId}/ProductReconcile/Review`, productReconcile);
  }

  post(operationId: string, reconcileId: string): Observable<ProductReconcileDto> {
    return this.http.put<ProductReconcileDto>(`${this.apiUrl}/Operation/${operationId}/ProductReconcile/Post/${reconcileId}`, null);
  }

  deleteReconcile(operationId: string, reconcileId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/Operation/${operationId}/ProductReconcile/${reconcileId}`);
  }

  getTable(operationId: string, request: ProductReconcileTableRequestDto): Observable<ProductReconcileTableResultDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...request
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<ProductReconcileTableResultDto>(`${this.apiUrl}/Operation/${operationId}/ProductReconcile/Table`, options);
  }
}