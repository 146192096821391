import { ChangeDetectorRef, Directive, inject, OnDestroy, OnInit } from '@angular/core';
import { HashMap, ProviderScope, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject, switchMap, take } from 'rxjs';

import { TranslateService } from './services';

@UntilDestroy()
@Directive()
export abstract class AbstractTranslationComponent implements OnInit, OnDestroy {

  protected abstract translationNamespace: string;
  protected translationReady$ = new ReplaySubject<void>(1);
  protected translationReady = false;
  protected cdr = inject(ChangeDetectorRef);
  protected translateService = inject(TranslateService);
  private providerScope = inject<ProviderScope>(TRANSLOCO_SCOPE);

  get scopedNamespace(): string {
    return `${this.scope}.${this.translationNamespace}`;
  }

  get scope(): string {
    const providerScope = Array.isArray(this.providerScope) ? this.providerScope[0] : this.providerScope;
    return providerScope?.scope;
  }

  ngOnInit(): void {
    this.subscribeToTranslationReadyEvents();
  }

  /* eslint-disable-next-line  @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function */
  ngOnDestroy(): void {
  }

  // translations init callback
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onTranslationInit(): void {
  }

  // translations changed callback
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onTranslationChanged(): void {
  }

  // translations ready callback ( initialized or changed )
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onTranslationReady(): void {
  }

  protected getTranslation(key: string, params?: HashMap<string | number>, namespace?: string): string {
    const translationNamespace = this.getNamespace(namespace);
    return this.translateService.getTranslation(`${this.scope}${translationNamespace}`, key, params);
  }

  protected getTranslation$(key: string, params?: HashMap, namespace?: string): Observable<string> {
    const translationNamespace = this.getNamespace(namespace);
    return this.translateService.getTranslation$(`${this.scope}${translationNamespace}`, key, params);
  }

  protected getTranslationObject(objectKey: string, namespace?: string): HashMap {
    const translationNamespace = this.getNamespace(namespace);
    return this.translateService.translateObject(`${this.scope}${translationNamespace}`, objectKey);
  }

  protected getTranslationObject$(objectKey: string, namespace?: string): Observable<HashMap> {
    const translationNamespace = this.getNamespace(namespace);
    return this.translateService.translateObject$(`${this.scope}${translationNamespace}`, objectKey);
  }

  private getNamespace(namespaceOverride?: string): string {
    return namespaceOverride ?? `.${this.translationNamespace}`;
  }

  private subscribeToTranslationReadyEvents(): void {
    this.translateService.lazyLoadScope(this.providerScope)
      .pipe(
        take(1),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.translationReady$.next();
        this.translationReady = true;
        this.subscribeToTranslationChangedEvents();
        this.onTranslationInit();
        this.onTranslationReady();
        this.cdr.markForCheck();
      });
  }

  private subscribeToTranslationChangedEvents(): void {
    this.translateService.langChanges$
      .pipe(
        switchMap(() => this.translateService.getTranslationLoaded$(this.providerScope?.scope)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.onTranslationChanged();
        this.onTranslationReady();
      });
  }
}
