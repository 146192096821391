import { Component, HostBinding } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'sersi-checkbox-table-row-wrapper',
  templateUrl: './checkbox-table-row-wrapper.component.html',
  styleUrls: ['./checkbox-table-row-wrapper.component.scss']
})
export class CheckboxTableRowWrapperComponent extends FieldWrapper {
  constructor() {
    super();
  }

  @HostBinding('class') class = 'col-12';
}
