import { Injectable } from '@angular/core';
import { TranslocoEvents, TranslocoService } from '@jsverse/transloco';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable, filter, map, of, switchMap } from 'rxjs';

import { camelCaseToKebab } from '../helpers';

@Injectable()
export abstract class AbstractStaticListFacade {
  protected scope = 'domain.state.select-lists';

  protected abstract selectList: SersiSelectListItem[];
  protected abstract selectListType: string;
  protected useCustomTranslationForCode: boolean;

  get items$(): Observable<SersiSelectListItem[]> {
    return this.getTranslationLoader().pipe(
      map(() => {
        return this.selectList.map(item => ({
          ...item,
          description: this.getTranslation(item.code),
          codeDescription: this.useCustomTranslationForCode ? this.getTranslation(`${item.code}-code-description`) : null
        }))
      })
    );
  }

  constructor(private translocoService: TranslocoService) {
  }

  private getTranslationLoader(): Observable<void> {
    return of(void 0).pipe(
      switchMap(() => {
        const loadedTranslations = this.translocoService.getTranslation();
        return loadedTranslations.size ? of(void 0) : this.getTranslatedLoaderByEvent();
      })
    );
  }

  private getTranslatedLoaderByEvent(): Observable<void> {
    return this.translocoService.events$.pipe(
      filter((event: TranslocoEvents) => {
        return event.type === 'translationLoadSuccess';
      }),
      map(() => void 0)
    );
  }

  private getTranslation(code: string): string {
    return this.translocoService.translate(`${this.scope}.${this.selectListType}.${camelCaseToKebab(code)}`)
  }
}

