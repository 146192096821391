import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OperationEffects } from './+state/operation/operation.effects';
import { OperationFacade } from './+state/operation/operation.facade';

import { reducer } from './+state/operation/operation.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('operation', reducer),
    EffectsModule.forFeature([OperationEffects])
  ],
  providers: [
    OperationEffects,
    OperationFacade
  ]
})
export class IfhmsAdminWebDomainStateOperationModule {}
