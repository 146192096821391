import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { FeedlotUserInviteDto, FeedlotUserListItemDto, FeedlotUserUpdateDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class FeedlotUserService {

  constructor(@Inject('apiUrl') private apiUrl: string, private http: HttpClient) {
  }

  getList(feedlotId: string): Observable<FeedlotUserListItemDto[]> {
    return this.http.get<FeedlotUserListItemDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Users/List`);
  }

  invite(feedlotId: string, companyUserUpdateDto: FeedlotUserInviteDto): Observable<FeedlotUserListItemDto[]> {
    return this.http.put<FeedlotUserListItemDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Users/Invite`, companyUserUpdateDto);
  }

  revoke(feedlotId: string, feedlotUserId: string): Observable<FeedlotUserListItemDto[]> {
    return this.http.put<FeedlotUserListItemDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Users/${feedlotUserId}/Revoke`, {});
  }

  update(feedlotId: string, companyUserUpdateDto: FeedlotUserUpdateDto): Observable<FeedlotUserListItemDto[]> {
    return this.http.put<FeedlotUserListItemDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Users`, companyUserUpdateDto);
  }

  isEmailValid(feedlotId: string, email: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { email }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Feedlot/${feedlotId}/Users/IsEmailUnique`, options);
  }
}
