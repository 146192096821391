import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SHOW_DEV_FEATURE } from '@common/angular/config';

@Injectable({ providedIn: 'root' })
export class DevFeatureGuard {

  constructor(
    private readonly router: Router,
    @Inject(SHOW_DEV_FEATURE) private showDevFeature: boolean
  ) {}

  canMatch(): boolean | UrlTree {
    return this.showDevFeature || this.router.parseUrl('/');
  }

}
