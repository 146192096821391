import { Injectable } from '@angular/core';
import { ReferenceDataService } from '@ifhms/common/angular/data-access/admin-api';
import { ContextService } from '@ifhms/common/angular/utils';
import { ReferenceDataType } from '@ifhms/models/admin';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';
import { InjectionLocationsActions } from './injection-locations.actions';

@Injectable()
export class InjectionLocationsEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InjectionLocationsActions.get),
      withLatestFrom(this.contextService.appContext$),
      exhaustMap(([, appContext]) => {
        return this.service.getSelectListByType(
          appContext,
          ReferenceDataType.AdministrationLocation
        ).pipe(
          map((items: SelectListItemWithTranslationDto[]) => {
            if (!items) return InjectionLocationsActions.error;
            return InjectionLocationsActions.getSuccess({ items });
          }),
          catchError(() => of(InjectionLocationsActions.error))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private contextService: ContextService,
    private service: ReferenceDataService
  ) {
  }
}
