import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AH_FMS_ADMIN_API_CONFIG } from '@ifhms/common/angular/data-access/admin-api';
import { EnterprisePermissionsDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EnterprisePermissionsService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  getEnterprisePermissionsForUser(): Observable<EnterprisePermissionsDto> {
    return this.http.get<EnterprisePermissionsDto>(`${this.apiUrl}/Enterprise/Permissions`);
  }
}
