import { BehaviorSubject, Observable, filter } from 'rxjs';
import _isEqual from 'lodash-es/isEqual';

export class DataSubject<T> {

  value: T;
  value$: Observable<T>;
  distinctVal$: Observable<T>;

  private prevVal: T;
  
  private subject$: BehaviorSubject<T>;

  constructor(val: T) {
    this.value = val;
    this.subject$ = new BehaviorSubject(val);
    this.value$ = this.subject$.asObservable();
    this.distinctVal$ = this.value$.pipe(
      filter(() => !_isEqual(this.prevVal, this.value))
    );
  }

  next(newVal: T): void {
    this.setValue(newVal);
    this.subject$.next(this.value);
  }

  patch(patch: Partial<T>): void {
    this.setValue({ ...this.value, ...patch });
    this.subject$.next(this.value);
  }

  silentUpdate(newVal: T): void {
    this.setValue(newVal);
  }

  private setValue(val: T): void {
    this.prevVal = { ...this.value };
    this.value = { ...val };
  }
}
