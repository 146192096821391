import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-phone-number',
  templateUrl: './phone-number-type.component.html',
  styleUrls: ['./phone-number-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneNumberTypeComponent extends FieldType<FieldTypeConfig> {
  override defaultOptions = {
    templateOptions: {
      label: '',
      placeholder: '',
      mask: '(999) 999-9999',
      autoClear: false,
      required: false
    }
  };
}
