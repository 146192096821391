import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Confirmation } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ah-utils-common-delete-confirmation-dialog',
  templateUrl: 'common-delete-confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonDeleteConfirmationDialogComponent implements OnInit {

  dialogData: Confirmation;

  constructor(
    private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.dialogData = this.config.data || {};
  }

  ngOnInit(): void {
    this.handleIndirectClose();
  }

  onConfirm(): void {
    this.dialogRef.close(true);
    this.dialogData.accept?.();
  }

  onCancel(): void {
    this.dialogRef.close(false);
    this.dialogData.reject?.();
  }

  private handleIndirectClose(): void {
    // indirect dialog close with backdrop click, esc keypress, etc
    // should act as confirmation denied
    this.dialogRef.onClose
      .pipe(
        filter(isConfirmed => isConfirmed === void 0),
        untilDestroyed(this)
      )
      .subscribe(() => this.dialogData.reject?.())
  }

}
