import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyCreateDto, CompanyDto } from '@ifhms/models/admin';

@Injectable()
export class CompaniesService {
  constructor(private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string) {
  }
 
  create(companyCreateDto: CompanyCreateDto): Observable<CompanyDto[]> {
    return this.http.post<CompanyDto[]>(`${this.apiUrl}/Companies`, companyCreateDto);
  }

  get(): Observable<CompanyDto[]> {
    return this.http.get<CompanyDto[]>(`${this.apiUrl}/Companies`);
  }

  getById(companyId: string): Observable<CompanyDto> {
    return this.http.get<CompanyDto>(`${this.apiUrl}/Companies/${companyId}`);
  }

  getBySlug(slug: string): Observable<CompanyDto> {
    return this.http.get<CompanyDto>(`${this.apiUrl}/Companies/Slug/${slug}`);
  }

  isCompanyNameValid(companyName: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { companyName }
    };
    const options = {
      params: new HttpParams(httpParams)
    };
    return this.http.get<boolean>(`${this.apiUrl}/Companies/IsCompanyNameValid`, options);
  }
}
