import { Injectable } from '@angular/core';
import { filter, NEVER, Observable, switchMap, take, tap } from 'rxjs';

import { AuthFacade, AuthState } from '../+state';

@Injectable({ providedIn: 'root' })
export class AuthLogoutRouteGuard  {

  constructor(
    private readonly authFacade: AuthFacade
  ) {}

  canActivate(): Observable<boolean> {
    return this.authFacade.state$.pipe(
      filter(({ isAuthInProgress }) => !isAuthInProgress),
      tap((authState: AuthState) => {
        if (!authState.isInitialized) {
          this.authFacade.init();
        }
      }),
      filter(({ isInitialized }) => !!isInitialized),
      take(1),
      tap(() => this.authFacade.logout()),
      switchMap(() => NEVER)
    );
  }
}
