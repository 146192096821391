import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonPermissionsService } from '../services';

@UntilDestroy()
@Directive({ selector: '[ahCommonHasPermission]' })
export class CommonHasPermissionDirective {
  private subscription?: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: CommonPermissionsService
  ) {}

  @Input() set ahCommonHasPermission(requiredPermission: string) {
    this.checkPermissionAccess(requiredPermission);
  }

  protected checkPermissionAccess(permission: string): void {
    this.subscription?.unsubscribe();

    this.subscription = this.permissionsService.hasPermission(permission)
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(hasAccess => this.reRenderView(hasAccess));
  }

  private reRenderView(hasAccess: boolean): void {
    this.viewContainer.clear();
    if (hasAccess) this.viewContainer.createEmbeddedView(this.templateRef);
  }
}

