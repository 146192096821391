import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { ProcedureCreateDto, ProcedureDto, ProcedureUpdateDto } from '@ifhms/models/admin';
import { AppContextDto, ContextLevel } from '@ifhms/models/shared';
import { Observable } from 'rxjs';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';
import { EnterpriseProceduresService } from './enterprise-procedures.service';
import { OperationProceduresService } from './operation-procedures.service';

@Injectable()
export class ProceduresManagementService {
  constructor(
    private http: HttpClient,
    private enterpriseProceduresService: EnterpriseProceduresService,
    private operationProceduresService: OperationProceduresService,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  add(appContext: AppContextDto, procedure: ProcedureCreateDto): Observable<ProcedureDto> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseProceduresService.add(procedure);
      case ContextLevel.Operation:
        return this.operationProceduresService.add(appContext.levelId, procedure);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  get(appContext: AppContextDto, procedureId: string): Observable<ProcedureDto> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseProceduresService.get(procedureId);
      case ContextLevel.Operation:
        return this.operationProceduresService.get(appContext.levelId, procedureId);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  getList(appContext: AppContextDto): Observable<ProcedureDto[]> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseProceduresService.getList();
      case ContextLevel.Operation:
        return this.operationProceduresService.getList(appContext.levelId);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  delete(appContext: AppContextDto, procedureId: string): Observable<unknown> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        throw new Error('Cannot delete enterprise procedure');
      case ContextLevel.Operation:
        return this.operationProceduresService.delete(appContext.levelId, procedureId);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  update(appContext: AppContextDto, procedure: ProcedureUpdateDto): Observable<ProcedureDto> {

    procedure.operationId = appContext.levelId;

    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseProceduresService.update(procedure);
      case ContextLevel.Operation:
        return this.operationProceduresService.update(appContext.levelId, procedure);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  toggleActiveStatus(appContext: AppContextDto, id: string): Observable<ProcedureDto> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseProceduresService.toggleActiveStatus(id);
      case ContextLevel.Operation:
        return this.operationProceduresService.toggleActiveStatus(appContext.levelId, id);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  isCodeUnique(appContext: AppContextDto, code: string): Observable<boolean> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseProceduresService.isCodeUnique(code);
      case ContextLevel.Operation:
        return this.operationProceduresService.isCodeUnique(appContext.levelId, code);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }
}
