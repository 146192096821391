import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { RouterNavigatedAction, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { urlRouteHistoryActions } from './url-route-history.actions';
import { Store } from '@ngrx/store';
import { RouteHistoryState } from './url-route-history.reducer';
import { RouterStateData } from '../../models';

@Injectable()
export class UrlRouteHistoryEffects {
  trackRouteHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      tap((action: RouterNavigatedAction) => {
        const routerState = action.payload.routerState as unknown as RouterStateData;
        this.store.dispatch(urlRouteHistoryActions.add({
          route: {
            url: routerState.url,
            queryParams: routerState.params
          }
        }));
      })
    ), { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<RouteHistoryState>
  ) {}
}
