import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HashMap } from '@common/angular/interfaces';
import {
  BillDto,
  BillingGenerationRequestDto,
  BillingGenerationResultDto,
  BillingListRequestDto,
  BillingListResultDto,
  LotBillingGenerationDto
} from '@ifhms/models/admin';
import { Observable } from 'rxjs';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class LotBillingManagementService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  getBillsList(operationId: string, dto: BillingListRequestDto): Observable<BillingListResultDto> {
    const options = {
      params: new HttpParams({ fromObject: <HashMap>dto })
    };
    return this.http.get<BillingListResultDto>(`${this.apiUrl}/Feedlot/${operationId}/LotBilling`, options);
  }

  cancelBillingGeneration(operationId: string, billId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/Feedlot/${operationId}/LotBilling/${billId}/Cancel`);
  }

  getBillDetails(operationId: string, billId: string): Observable<BillDto> {
    return this.http.get<BillDto>(`${this.apiUrl}/Feedlot/${operationId}/LotBilling/${billId}`);
  }

  generateReport(operationId: string, billId: string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId,
            billId: billId
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/LotBill`, options);
  }

  downloadCSV(operationId: string, billId: string): Observable<any> {
    const options: object = {
      responseType: 'arraybuffer'
    };
    return this.http.get<Observable<any>>(`${this.apiUrl}/Feedlot/${operationId}/LotBilling/${billId}/Export/Csv`, options);
  }

  deleteBill(operationId: string, billId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/Feedlot/${operationId}/LotBilling/${billId}`);
  }

  getLotBillingList(operationId: string, isLotCloseout: boolean, billToDate: string | null, request: BillingGenerationRequestDto): Observable<BillingGenerationResultDto> {
    let params = new HttpParams({ fromObject: <HashMap>request });

    params = params.set('isLotCloseout', isLotCloseout.toString());
    params = params.set('billToDate', billToDate ?? '');

    const options = { params: params };

    return this.http.get<BillingGenerationResultDto>(`${this.apiUrl}/Feedlot/${operationId}/LotBilling/LotList`, options);
  }

  validateLots(request: LotBillingGenerationDto, operationId: string): Observable<string[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        billToDate: request.billToDate
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };
    return this.http.post<string[]>(`${this.apiUrl}/Feedlot/${operationId}/LotBilling/ValidateLots`, request.lotList, options);
  }

  createBill(operationId: string, isLotCloseout: boolean, request: LotBillingGenerationDto): Observable<BillDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        isLotCloseout,
        billToDate: request.billToDate,
        invoiceDate: request.invoiceDate
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.post<BillDto>(`${this.apiUrl}/Feedlot/${operationId}/LotBilling`, request.lotList, options);
  }

  finalizeBill(operationId: string, billId: string): Observable<BillDto> {
    return this.http.post<BillDto>(`${this.apiUrl}/Feedlot/${operationId}/LotBilling/${billId}/Finalize`, null);
  }

}

