import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { OtherFeeDto, OtherFeeRequestDto, OtherFeesListItemDto } from '@ifhms/models/admin';
import { dateToIsoStringEndOfDate, dateToIsoStringStartOfDate } from '@common/angular/utils';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ReferenceDataOtherFeesService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) { }

  add(feedlotId: string, otherFees: OtherFeeDto): Observable<OtherFeeDto> {
    const updateFee = this.adjustDates(otherFees);
    return this.http.post<OtherFeeDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/OtherFees`, updateFee);
  }

  get(feedlotId: string, otherFeesId: string): Observable<OtherFeeDto> {
    return this.http.get<OtherFeeDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/OtherFees/${otherFeesId}`).pipe(
      map((x: OtherFeeDto) => {

        for (const detail of x.details) {
          detail.effectiveFromDate = new Date(detail.effectiveFromDate);
          detail.effectiveToDate &&= new Date(detail.effectiveToDate as unknown as Date);
          detail.lastBilled &&= new Date(detail.lastBilled as unknown as Date);
        }

        return x;
      })
    );
  }

  getList(feedlotId: string): Observable<OtherFeesListItemDto[]> {
    return this.http.get<OtherFeesListItemDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/OtherFees/List`);
  }

  delete(feedlotId: string, otherFeesId: string): Observable<unknown> {
    return this.http.delete(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/OtherFees/${otherFeesId}/Delete`);
  }

  update(feedlotId: string, otherFees: OtherFeeDto): Observable<OtherFeeDto> {
    const updateFee = this.adjustDates(otherFees);
    return this.http.put<OtherFeeDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/OtherFees`, updateFee);
  }

  activate(feedlotId: string, otherFeesId: string): Observable<OtherFeeDto> {
    return this.http.put<OtherFeeDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/OtherFees/${otherFeesId}/Activate`, null);
  }

  deactivate(feedlotId: string, otherFeesId: string): Observable<OtherFeeDto> {
    return this.http.put<OtherFeeDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/OtherFees/${otherFeesId}/Deactivate`, null);
  }

  isCodeValid(feedlotId: string, code: string): Observable<boolean> {

    const httpParams: HttpParamsOptions = {
      fromObject: {
        code: code
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Feedlot/${feedlotId}/Reference/OtherFees/IsCodeValid`, options);
  }

  private adjustDates(otherFee: OtherFeeDto): OtherFeeRequestDto {
    return {
      ...otherFee,
      details: otherFee.details.map((detail) => ({
        ...detail,
        effectiveFromDate: dateToIsoStringStartOfDate(detail.effectiveFromDate || new Date())!,
        effectiveToDate: dateToIsoStringEndOfDate(detail.effectiveToDate)
      }))
    };
  }
}
