import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProductReceiptDto, ProductReceiptTableRequestDto, ProductReceiptTableResultDto } from '@ifhms/models/admin';
import { ResponseDto } from '@ifhms/models/shared';
import { Observable } from 'rxjs';

@Injectable()
export class ProductReceiptService {

  constructor(private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string) {
  }

  get(operationId: string, receiptId: string): Observable<ProductReceiptDto> {
    return this.http.get<ProductReceiptDto>(`${this.apiUrl}/Operation/${operationId}/ProductReceipt/${receiptId}`);
  }

  uploadFile(operationId: string, file: File): Observable<ProductReceiptDto> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<ProductReceiptDto>(`${this.apiUrl}/Operation/${operationId}/ProductReceipt/Upload`, formData);
  }

  save(operationId: string, productReceipt: ProductReceiptDto): Observable<ProductReceiptDto> {
    return this.http.put<ProductReceiptDto>(`${this.apiUrl}/Operation/${operationId}/ProductReceipt`, productReceipt);
  }

  post(operationId: string, productReceipt: ProductReceiptDto): Observable<ResponseDto<ProductReceiptDto>> {
    return this.http.put<ResponseDto<ProductReceiptDto>>(`${this.apiUrl}/Operation/${operationId}/ProductReceipt/PostReceipt`, productReceipt);
  }

  deleteReceipt(operationId: string, receiptId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/Operation/${operationId}/ProductReceipt/${receiptId}`);
  }

  getTable(operationId: string, request: ProductReceiptTableRequestDto): Observable<ProductReceiptTableResultDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        operationId,
        body: JSON.stringify(request)
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<ProductReceiptTableResultDto>(`${this.apiUrl}/Operation/${operationId}/ProductReceipt/Table`, options);
  }

  isFileNameUnique(operationId: string, fileName: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}/Operation/${operationId}/ProductReceipt/IsFileNameUnique`, {
      params: new HttpParams().set('fileName', fileName)
    });
  }
}