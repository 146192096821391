<ng-container *transloco="let t; read: translateScope">
  <p-table
    styleClass="p-datatable-lg p-datatable-striped"
    scrollHeight="calc(50vh + 5rem)"
    [value]="animals"
    [scrollable]="true"
    [(selection)]="selectedItems"
    [virtualScrollItemSize]="50"
    [virtualScroll]="true"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowUnselect()"
    (onHeaderCheckboxToggle)="onHeaderCheckboxSelected()"
  >
    <ng-template pTemplate="caption" style="background-color: inherit">
      <div class="flex justify-content-between align-items-center h-2rem">
        {{ tableHeader }}
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center sersi-width-50" [attr.data-ele]="'animal-selector-header-checkbox'">
          <p-tableHeaderCheckbox />
        </th>
        <th class="text-center">{{ t('tag') }}</th>
        <th class="text-center">{{ t('current-pen') }}</th>
        <th class="text-center">{{ t('home-pen') }}</th>
        <th class="text-center">{{ t('lot') }}</th>
        <th class="text-center">{{ t('sub-group') }}</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6" class="empty-message text-center border-none">{{ t('no-animals-selected') }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-animal>
      <tr>
        <td class="py-0 px-1 text-center">
          <p-tableCheckbox [value]="animal" [attr.data-animal-selector-checkbox-ele]="animal.id" dataKey="animal.id"></p-tableCheckbox>
        </td>
        <td class="py-0 px-1 text-center">{{ animal.tagId }}</td>
        <td class="py-0 px-1 text-center">{{ animal.currentPenName }}</td>
        <td class="py-0 px-1 text-center">{{ animal.homePenName }}</td>
        <td class="py-0 px-1 text-center">{{ animal.lotNumber }}</td>
        <td class="py-0 px-1 text-center">{{ animal.subGroup }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="summary">
      <div
        class="flex align-items-center justify-content-between footer"
        *ngIf="animals.length"
      >
        <span *ngIf="selectedItems.length as selected; else totalAnimalsCount">
          @if (animals.length === 1) {
            {{ t('selected-animal-count', { selected, total: animals.length }) }}
          } @else {
            {{ t('selected-animals-count', { selected, total: animals.length }) }}
          }
        </span>
        <ng-template #totalAnimalsCount>
          @if (animals.length === 1) {
            <span>{{ t('total-animal-count', { total: animals.length }) }}</span>
          } @else {
            <span>{{ t('total-animals-count', { total: animals.length }) }}</span>
          }
        </ng-template>
      </div>
    </ng-template>
  </p-table>
</ng-container>