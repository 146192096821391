import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { VendorsActions } from './vendors.actions';
import * as reducer from './vendors.reducer';
import { VendorsSelectors } from './vendors.selectors';

@Injectable()
export class VendorsFacade {

  loaded$ = this.store.select(VendorsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(VendorsSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}
  
  get(): void {
    this.store.dispatch(VendorsActions.get());
  }
}
