import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CommonPermissionsService } from '@common/angular/permissions';
import { transformPermission } from '@common/angular/utils';
import { OperationsFacade } from '@ifhms/admin/web/domain/state/operations';
import {
  FeedlotSettingsService,
  FeedlotTagSettingsService,
  OperationsService
} from '@ifhms/common/angular/data-access/admin-api';
import {
  FeedlotGeneralSettingsDto,
  OperationDto,
  OperationSettingsCciaDto,
  OperationSettingsPmIntegrationDto
} from '@ifhms/models/admin';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { catchError, exhaustMap, map, of, tap, withLatestFrom } from 'rxjs';

import { OperationActions } from './operation.actions';
import { OperationFacade } from './operation.facade';

@Injectable({
  providedIn: 'root'
})
export class OperationEffects {
  translateScope = 'feedlotSettings.state';

  constructor(
    private actions$: Actions,
    private commonPermissionService: CommonPermissionsService,
    private feedlotSettingsService: FeedlotSettingsService,
    private feedlotTagSettingsService: FeedlotTagSettingsService,
    private messageService: MessageService,
    private operationFacade: OperationFacade,
    private operationsFacade: OperationsFacade,
    private operationsService: OperationsService,
    private translateService: TranslocoService,
    private router: Router
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.get),
      exhaustMap((action) =>
        this.operationsService.get(action.operationId).pipe(
          tap((operation: OperationDto) => {
            this.commonPermissionService.setPermissions(
              transformPermission(operation.permissions)
            );
          }),
          map((operation: OperationDto) =>
            OperationActions.getSuccess({ operation })
          ),
          catchError(() => of(OperationActions.error()))
        )
      )
    )
  );

  getBySlug$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.getBySlug),
      exhaustMap((action) =>
        this.operationsService.getBySlug(action.slug).pipe(
          tap((operation: OperationDto) => {
            this.commonPermissionService.setPermissions(
              transformPermission(operation.permissions)
            );
          }),
          map((operation: OperationDto) => {
            return OperationActions.getBySlugSuccess({
              operation,
              updateCurrent: action.updateCurrent
            });
          }),
          catchError(() => of(OperationActions.error()))
        )
      )
    )
  );

  getGeneralSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.getGeneralSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([, operationId]) =>
        this.feedlotSettingsService.getGeneralSettings(operationId).pipe(
          map((generalSettings: FeedlotGeneralSettingsDto) =>
            OperationActions.getGeneralSettingsSuccess({ generalSettings })
          ),
          catchError(() => of(OperationActions.errorSettingsUpdate()))
        )
      )
    )
  );

  updateGeneralSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.updateGeneralSettings),
      withLatestFrom(
        this.operationFacade.operationId$,
        this.operationFacade.operationSlug$
      ),
      exhaustMap(([action, operationId, slug]) =>
        this.feedlotSettingsService
          .updateGeneralSettings(operationId, action.generalSettingsUpdate)
          .pipe(
            map((generalSettings: FeedlotGeneralSettingsDto) => {
              return OperationActions.updateGeneralSettingsSuccess({
                generalSettings
              });
            }),
            catchError(() => of(OperationActions.errorSettingsUpdate()))
          )
      )
    )
  );

  updateGeneralSettingsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OperationActions.updateGeneralSettingsSuccess),
        withLatestFrom(this.operationFacade.operationSlug$),
        exhaustMap(([action, previousSlug]) =>
          of(action.generalSettings).pipe(
            tap(() => {
              this.messageService.add({
                severity: 'success',
                summary: this.getTranslation('success-title'),
                detail: this.getTranslation('saved-success-message')
              });
              this.syncFeedlotSlug(previousSlug, action.generalSettings.slug);
              this.operationsFacade.refresh();
            })
          )
        )
      ),
    { dispatch: false }
  );

  updateTagSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.updateTagSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([action, operationId]) =>
        this.feedlotTagSettingsService
          .updateTagSettings(operationId, action.settings)
          .pipe(
            map((operation: OperationDto) =>
              OperationActions.updateSuccess({ operation })
            ),
            catchError(() => of(OperationActions.errorSettingsUpdate()))
          )
      )
    )
  );

  getCciaSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.getCciaSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([, operationId]) =>
        this.feedlotSettingsService.getCCIAAccountSettings(operationId).pipe(
          map((cciaSettings: OperationSettingsCciaDto) =>
            OperationActions.getCciaSettingsSuccess({ cciaSettings })
          ),
          catchError(() => of(OperationActions.errorSettingsUpdate()))
        )
      )
    )
  );

  updateCciaSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.updateCciaSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([action, operationId]) =>
        this.feedlotSettingsService
          .updateCCIAAccountSettings(operationId, action.cciaSettings)
          .pipe(
            map((cciaSettings: OperationSettingsCciaDto) =>
              OperationActions.updateCciaSettingsSuccess({ cciaSettings })
            ),
            catchError(() => of(OperationActions.errorSettingsUpdate()))
          )
      )
    )
  );

  getPmIntegrationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.getPmIntegrationSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([, operationId]) =>
        this.feedlotSettingsService.getPmIntegrationSettings(operationId).pipe(
          map((settings: OperationSettingsPmIntegrationDto) =>
            OperationActions.getPmIntegrationSettingsSuccess({ settings })
          ),
          catchError(() => of(OperationActions.errorSettingsUpdate()))
        )
      )
    )
  );

  updatePmIntegrationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.updatePmIntegrationSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([action, operationId]) =>
        this.feedlotSettingsService
          .updatePmIntegrationSettings(operationId, action.settings)
          .pipe(
            map((settings: OperationSettingsPmIntegrationDto) =>
              OperationActions.updatePmIntegrationSettingsSuccess({ settings })
            ),
            catchError(() => of(OperationActions.errorSettingsUpdate()))
          )
      )
    )
  );

  updateSortGroupSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationActions.updateSortGroupSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([action, operationId]) =>
        this.feedlotSettingsService
          .updateSortGroupSettings(operationId, action.settings)
          .pipe(
            map((operation: OperationDto) =>
              OperationActions.updateSuccess({ operation })
            ),
            catchError(() => of(OperationActions.errorSettingsUpdate()))
          )
      )
    )
  );

  updateSettingsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          OperationActions.updateSuccess,
          OperationActions.updateCciaSettingsSuccess,
          OperationActions.updatePmIntegrationSettingsSuccess
        ),
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: this.getTranslation('success-title'),
            detail: this.getTranslation('saved-success-message')
          });
        })
      ),
    { dispatch: false }
  );

  updateSettingsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OperationActions.errorSettingsUpdate),
        tap(() => {
          this.messageService.add({
            severity: 'error',
            summary: this.getTranslation('save-error-title'),
            detail: this.getTranslation('save-error-message')
          });
        })
      ),
    { dispatch: false }
  );

  private getTranslation(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }

  private syncFeedlotSlug(prevSlug: string, activeSlug: string): void {
    if (prevSlug === activeSlug) return;
    this.router.navigateByUrl(this.router.url.replace(prevSlug, activeSlug), {
      replaceUrl: true,
      state: { ignoreGuardCheck: true }
    });
  }
}
