import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { AppContextDto } from '@ifhms/models/shared';
import * as reducer from './sort-types.reducer';
import { SortTypesSelectors } from './sort-types.selectors';
import { SortTypesActions } from './sort-types.actions';

@Injectable()
export class SortTypesFacade {

  loaded$ = this.store.select(SortTypesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(SortTypesSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}
  
  get(context?: AppContextDto): void {
    this.store.dispatch(SortTypesActions.get({ context }));
  }
}
