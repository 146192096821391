import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormlyModule } from '@ngx-formly/core';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';

import { scopeLoader } from '@common/angular/translation';
import { FormlyTypes } from '@ifhms/common/angular/interfaces';

import { SersiAngularUiSpinModule } from '@sersi/angular/ui/spin';
import { CheckboxModule } from 'primeng/checkbox';
import { AnimalSelectorEffects, AnimalSelectorFacade, FEATURE_NAME, reducer } from './+state';
import {
  AnimalSelectorDualListBoxComponent,
  AnimalSelectorFormComponent,
  AnimalSelectorTableComponent
} from './components';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    TableModule,
    FormlyModule.forChild({
      types: [
        { name: FormlyTypes.ANIMAL_SELECTOR, component: AnimalSelectorFormComponent }
      ]
    }),
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([AnimalSelectorEffects]),
    ButtonModule,
    SersiAngularUiSpinModule,
    CheckboxModule,
    FormsModule
  ],
  providers: [
    AnimalSelectorFacade,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'animalSelector',
        alias: 'animalSelector',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        )
      }
    }
  ],
  declarations: [AnimalSelectorFormComponent, AnimalSelectorTableComponent, AnimalSelectorDualListBoxComponent]
})
export class IfhmsCommonSharedFormlyTypesAnimalSelectorModule {}
