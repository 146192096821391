import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AnimalImportDetailDto } from '@ifhms/models/admin';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ImportAnimalsDetailService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  getDetail(operationId: string, importHistoryId: string): Observable<AnimalImportDetailDto> {
    return this.http.get<AnimalImportDetailDto>(
      `${this.apiUrl}/AnimalsImport/Feedlot/${operationId}/ImportHistory/${importHistoryId}`
    );
  }

}
