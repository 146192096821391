import { ChangeDetectorRef, Component, HostBinding, inject } from '@angular/core';
import { HashMap } from '@jsverse/transloco';
import { FieldType, FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, Subject } from 'rxjs';

import { TranslateService } from '@common/angular/translation';

import { SersiFormlyFieldProps } from '../interfaces';

@Component({ template: '' })
export abstract class AbstractFormFieldConfigComponent extends FieldType implements FormlyExtension {

  @HostBinding('class') hostClassNames: string;

  isInitialized = false;

  protected scopedNamespace: string;
  protected readonly fieldClassName: string;

  protected fieldConfig: FormlyFieldConfig;
  protected fieldDestroy$ = new Subject<void>();

  protected translateService = inject(TranslateService);
  protected cdr = inject(ChangeDetectorRef);

  protected get fieldProps(): SersiFormlyFieldProps {
    return this.fieldConfig?.props || {} as SersiFormlyFieldProps;
  }
  protected get isDisabled(): boolean {
    return !!this.fieldConfig?.props?.disabled;
  }
  protected get fieldModel(): typeof this.model {
    return this.fieldConfig.model;
  }
  protected get fieldFormState(): HashMap {
    return this.fieldConfig.options?.formState || {};
  }

  constructor() {
    super();
    this.onPopulate = this.onPopulate.bind(this);
    this.handleActionEvent = this.handleActionEvent.bind(this);
    this.onFieldInit = this.onFieldInit?.bind(this);
    this.onFieldDestroy = this.onFieldDestroy.bind(this);
  }

  onPopulate(field: FormlyFieldConfig): void {
    // skip if already initialized
    if (field.fieldGroup) return;

    this.fieldConfig = field;

    this.fieldConfig.className = this.getFieldClassName(field);
    this.fieldConfig.fieldGroup = this.getFieldGroupConfig(field);
    this.fieldConfig.hooks = {
      onInit: (field): void => this.onFieldInit(field),
      onDestroy: (): void => this.onFieldDestroy()
    }
  }

  protected abstract getFieldGroupConfig(field: FormlyFieldConfig): FormlyFieldConfig[];

  protected onFieldInit(field: FormlyFieldConfig): void {
    this.setFieldValue(field, 'isInitialized', true);
  }

  protected setFieldValue(field: FormlyFieldConfig, key: string, value: unknown): void {
    field.props ||= {};
    field.props[key] = value;
  }

  protected getFieldValue<T>(key: string): T {
    const field = this.fieldConfig || this.field || {};
    return field?.props?.[key] || null;
  }

  protected getFieldConfig(key: string): FormlyFieldConfig | null {
    const fieldGroup = this.fieldConfig?.fieldGroup || [];
    return fieldGroup.find(f => f.key === key) || null;
  }

  protected onFieldDestroy(): void {
    this.fieldDestroy$.next();
  }

  protected handleActionEvent(actionData: unknown): void {
    const actionFn = this.fieldConfig.props && this.fieldConfig.props['onAction'];
    return actionFn && actionFn(actionData);
  }

  protected getParentTranslation(key: string, data?: HashMap): string {
    const translateFn = this.fieldConfig.props && this.fieldConfig.props['translateFn'];
    return translateFn ? translateFn(key, data) : '';
  }

  protected getParentTranslation$(key: string, data?: HashMap): Observable<string> {
    const translateFn = this.fieldConfig.props?.['translateFn$'];
    return translateFn ? translateFn(key, data) : '';
  }

  protected getTranslation(key: string, params?: HashMap): string {
    return this.translateService.getTranslation(this.scopedNamespace, key, params);
  }

  protected getTranslation$(key: string, params?: HashMap): Observable<string> {
    return this.translateService.getTranslation$(this.scopedNamespace, key, params);
  }

  private getFieldClassName(field: FormlyFieldConfig): string {
    return [this.fieldClassName, field.className, '']
      .filter(className => className !== undefined && className !== null)
      .join(' ');
  }

}
