import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { map, Observable, switchMap } from 'rxjs';

import { getCurrentRouteDataFromSnapshot } from '@common/angular/utils';
import { CompanyFacade } from '@ifhms/admin/web/domain/state/company';
import { ContextLevel } from '@ifhms/models/shared';

import { OperationsFacade } from '../+state/operations/operations.facade';

@Injectable({ providedIn: 'root' })
export class OperationsSelectGuard {

  constructor(
    private readonly companyFacade: CompanyFacade,
    private readonly operationsFacade: OperationsFacade,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const routeData = getCurrentRouteDataFromSnapshot(state);
    const companySlug = routeData['slug'];
    const contextLevel = route.data['contextLevel'];

    this.companyFacade.get(companySlug, false);

    return this.companyFacade.loadedCompany$.pipe(
      switchMap((company) => {
        this.operationsFacade.get(company.id, false);
        return this.operationsFacade.loadedOperations$;
      }),
      map((operations) => {
        // auto redirect to a single operation
        if (operations?.length === 1) {
          return this.router.parseUrl(`/operation/${companySlug}/feedlot/${operations[0].code}`);
        }
        const operationsListBasePath = contextLevel === ContextLevel.Operation ? 'operation' : 'company';
        return this.router.parseUrl(`/${operationsListBasePath}/${companySlug}/operations`);
      })
    );
  }

}
