import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { InjectionLocationsActions } from './injection-locations.actions';
import * as reducer from './injection-locations.reducer';
import { InjectionLocationsSelectors } from './injection-locations.selectors';

@Injectable()
export class InjectionLocationsFacade {

  loaded$ = this.store.select(InjectionLocationsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(InjectionLocationsSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}
  
  get(): void {
    this.store.dispatch(InjectionLocationsActions.get());
  }
}
