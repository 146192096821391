import { filter, map, Observable, withLatestFrom } from 'rxjs';

export function getLoadedStateData<T>(data$: Observable<T | null>, loaded$: Observable<boolean>): Observable<T> {
  return data$
    .pipe(
      withLatestFrom(loaded$),
      filter(([, loaded]) => !!loaded),
      map(([data]) => data as T)
    );
}

export function excludeNulls<T>(source$: Observable<T | null>): Observable<T> {
  return source$
    .pipe(
      filter(event => !!event),
      map(event => event!)
    );
}
