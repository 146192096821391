import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-postal-code',
  templateUrl: './postal-code-type.component.html',
  styleUrls: ['./postal-code-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostalCodeTypeComponent extends FieldType<FieldTypeConfig> {
  override defaultOptions = {
    templateOptions: {
      label: '',
      placeholder: '',
      required: false,
      disabled: false,
      mask: 'a9a 9a9', //this is formatted for canadian postal codes
      autoClear: false
    }
  };
}
