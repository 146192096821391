import { Injectable } from '@angular/core';
import { AbstractStaticListFacade } from '@common/angular/utils';
import { ProcessMethod } from '@ifhms/models/shared';

@Injectable()
export class ProcessMethodsFacade extends AbstractStaticListFacade {

  protected selectList = [
    // {
    //   id: ProcessMethod.Group,
    //   code: 'Group',
    //   description: '',
    //   attributes: {
    //     isDefault: 'true'
    //   }
    // },
    {
      id: ProcessMethod.IndvM,
      code: 'Indv-M',
      description: ''
    },
    {
      id: ProcessMethod.IndvS,
      code: 'Indv-S',
      description: ''
    }
  ];
  protected selectListType = 'process-methods';

}

