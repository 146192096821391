import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { LotOwnersActions } from './lot-owners.actions';
import * as reducer from './lot-owners.reducer';
import { LotOwnersSelectors } from './lot-owners.selectors';

@Injectable()
export class LotOwnersFacade {

  loaded$ = this.store.select(LotOwnersSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(LotOwnersSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) { }

  get(): void {
    this.store.dispatch(LotOwnersActions.get());
  }
}
