export enum ReferenceDataType {
  AcquisitionType = 1,
  AdministrationLocation = 2,
  AgeClass = 3,
  Alley = 6,
  BreedClass = 7,
  BreedType = 8,
  Breeds = 9,
  CoatColor = 10,
  Dairy = 11,
  Dentition = 12,
  DoseUnit = 13,
  DxCategory = 14,
  DxGroup = 15,
  Facility = 16,
  FeedingPlan = 17,
  Gender = 18,
  IdType = 19,
  LotOwner = 20,
  Manufacturer = 21,
  MarketingPlan = 22,
  PenType = 23,
  PricingMethod = 24,
  ProductType = 25,
  Purchaser = 26,
  RailDestination = 27,
  Region = 28,
  RiskCategory = 29,
  Route = 30,
  Source = 31,
  TagColor = 32,
  TagLocation = 33,
  UnitType = 34,
  Vendor = 35,
  SortType = 36,
  ActiveIngredient = 37,
  DoseInEar = 38,
  Program = 39,
  ProcurementType = 40,
  ProcurementCategory = 41,
  TxGroup
}
