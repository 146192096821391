<p class="mt-0 mb-4 white-space-pre-wrap">{{ dialogData['message'] }}</p>
<div class="text-center">
  <button
    pButton
    class="p-button-secondary"
    data-ele="close-btn"
    [label]="dialogData['closeLabel']"
    [text]="true"
    [rounded]="true"
    (click)="onClose()"
  ></button>
</div>
