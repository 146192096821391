import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { initSentry } from '@common/angular/integrations/sentry';

import { AppModule } from './app/app.module';
import { getAppConfig } from './utils';

const { integrations, environmentName } = getAppConfig();

initSentry(integrations?.sentryDsn, { environmentName });

enableProdMode();
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
