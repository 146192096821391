<ng-container *ngIf="props['subHeading']; else mainHeading">
  <p
    class="disabled-text"
    [class.mt-0]="props['noTopMargin']"
  >
    <sersi-form-text-label [props]="props" />
  </p>
</ng-container>
<ng-template #mainHeading>
  <h4
    class="section-label"
    [class.mt-0]="props['noTopMargin']"
  >
    <sersi-form-text-label [props]="props" />
    <span
      *ngIf="props['showRequiredIndicator']"
      class="required-indicator"
      aria-hidden="true"
    >*</span>
  </h4>

</ng-template>

<ng-container #fieldComponent></ng-container>