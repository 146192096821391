import { Injectable } from '@angular/core';
import { ProductCreateDto, ProductDto, ProductListRequestDto, ProductListResultDto, ProductToggleActiveRequestDto, ProductUpdateDto } from '@ifhms/models/admin';
import { AppContextDto, ContextLevel, SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { Observable } from 'rxjs';
import { CompanyProductManagementService } from './company-product-management.service';
import { EnterpriseProductManagementService } from './enterprise-product-management.service';
import { OperationProductManagementService } from './operation-product-management.service';

@Injectable()
export class ProductManagementService {

  constructor(
    private enterpriseProductManagementService: EnterpriseProductManagementService,
    private companyProductManagementService: CompanyProductManagementService,
    private operationProductManagementService: OperationProductManagementService) {
  }

  create(appContext: AppContextDto, productCreateDto: ProductCreateDto): Observable<ProductDto> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseProductManagementService.create(productCreateDto);
      case ContextLevel.Operation:
        return this.operationProductManagementService.create(appContext.levelId, productCreateDto);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  get(appContext: AppContextDto, productId: string): Observable<ProductDto> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseProductManagementService.get(productId);
      case ContextLevel.Operation:
        return this.operationProductManagementService.get(appContext.levelId, productId);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  update(appContext: AppContextDto, productUpdateDto: ProductUpdateDto): Observable<ProductDto> {
    console.log('update: ', appContext);
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseProductManagementService.update(productUpdateDto);
      case ContextLevel.Operation:
        return this.operationProductManagementService.update(appContext.levelId, productUpdateDto);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  getSelectList(appContext: AppContextDto): Observable<SelectListItemWithTranslationDto[]> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseProductManagementService.getSelectList();
      case ContextLevel.Company:
        return this.companyProductManagementService.getSelectList(appContext.levelId);
      case ContextLevel.Operation:
        return this.operationProductManagementService.getSelectList(appContext.levelId);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  getTable(appContext: AppContextDto, request: ProductListRequestDto): Observable<ProductListResultDto> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseProductManagementService.getTable(request);
      case ContextLevel.Operation:
        return this.operationProductManagementService.getTable(appContext.levelId, request);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  toggleActiveStatus(appContext: AppContextDto, toggleActiveRequest: ProductToggleActiveRequestDto): Observable<ProductListResultDto> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseProductManagementService.toggleActiveStatus(toggleActiveRequest);
      case ContextLevel.Operation:
        return this.operationProductManagementService.toggleActiveStatus(appContext.levelId, toggleActiveRequest);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  isCodeUnique(code: string, appContext: AppContextDto): Observable<boolean> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseProductManagementService.isCodeUnique(code);
      case ContextLevel.Operation:
        return this.operationProductManagementService.isCodeUnique(appContext.levelId, code);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }
}