import { CompanyDto, CompanyPermissionsDto } from '@ifhms/models/admin';
import { createReducer, on } from '@ngrx/store';

import { CompanyActions } from './company.actions';

export const FEATURE_NAME = 'Company';

export interface State {
  company: CompanyDto | null;
  loading: boolean;
  loaded: boolean;
  isActive: boolean;
}

const initialState: State = {
  company: null,
  loading: false,
  loaded: false,
  isActive: false
};

export const reducer = createReducer(
  initialState,

  on(CompanyActions.get, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(CompanyActions.getSuccess, (state, action) => ({
    ...state,
    company: action.company,
    loading: false,
    loaded: true,
    isActive: true
  })),

  on(CompanyActions.getSuccessCached, (state) => ({
    ...state,
    loading: false,
    loaded: true
  })),

  on(CompanyActions.error, CompanyActions.reset, () => initialState)
)

export const getCompany = (state: State): CompanyDto | null => state.company;

export const getCompanyId = (state: State): string => state?.company?.id || '';

export const getCompanySlug = (state: State): string => state?.company?.slug || '';

export const getCompanyName = (state: State): string => state?.company?.name || '';

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;

export const getIsActive = (state: State): boolean => state.isActive;

export const getCompanyPermissions = (state: State): CompanyPermissionsDto | null => state.company?.permissions || null;