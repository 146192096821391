import { FeedlotPricingSettingsDto } from '@ifhms/models/admin';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Pricing] - ';

// ===== Pricing Settings ======
const getPricingSettings = createAction(
  `${NAMESPACE} Get Pricing Settings`
);

const getPricingSettingsSuccess = createAction(
  `${NAMESPACE} Get Pricing Settings Success`,
  props<{ settings: FeedlotPricingSettingsDto }>()
);

const updatePricingSettings = createAction(
  `${NAMESPACE} Update Pricing Settings`,
  props<{ settings: FeedlotPricingSettingsDto }>()
);

const updatePricingSettingsSuccess = createAction(
  `${NAMESPACE} Update Pricing Settings Success`,
  props<{ settings: FeedlotPricingSettingsDto }>()
);

const resetPricingSettings = createAction(
  `${NAMESPACE} Reset Pricing Settings`
);

const applyPricingSettings = createAction(
  `${NAMESPACE} Apply Pricing Settings`,
  props<{ settings: FeedlotPricingSettingsDto }>()
);

const applyPricingSettingsSuccess = createAction(
  `${NAMESPACE} Apply Pricing Settings Success`,
  props<{ settings: FeedlotPricingSettingsDto }>()
);

const error = createAction(`${NAMESPACE} error`);

export const PricingSettingsActions = {
  error,
  getPricingSettings,
  getPricingSettingsSuccess,
  updatePricingSettings,
  updatePricingSettingsSuccess,
  resetPricingSettings,
  applyPricingSettings,
  applyPricingSettingsSuccess
};
