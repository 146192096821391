import { Observable, Subject } from 'rxjs';

export const GRID_KEY = 'grid';

export interface GridOptions {
  columns: GridColumnDef[];
  context: string;
  deleteRow: Subject<number>;
  addRow: Subject<void>;
  showSelectAll?: boolean;
}

export interface GridColumnDef {
  fieldKey: string;
  width: string;
  minWidth?: number;
  className?: string;
  headerName?: string;
  headerName$?: Observable<string>;
  hideCell?: boolean;
  required?: boolean;
  hotKey?: string;
  cellRendererParams?: GridCellRendererParams;
  isSelectColumn?: boolean;
}

export interface GridCellRendererParams {
  type: GridRendererCellType;
}

export enum GridRendererCellType {
  Default = 'default',
  Info = 'info',
  Delete = 'delete',
  Add = 'add',
  ADD_DELETE = 'add-delete',
  DosingGun = 'dosing-gun'
}

export type GridData<T> = {
  grid: T[];
}
