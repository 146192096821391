<span class="p-fluid p-calendar-container">
    <p-calendar
      [attr.data-ele]='key'
      [formControl]="formControl"
      [formlyAttributes]="field"
      [hideOnDateTimeSelect]="props['hideOnSelect']"
      [placeholder]="props['placeholder'] ?? ''"
      [showIcon]="props['showIcon']"
      [dateFormat]="props['dateFormat']"
      [styleClass]="props['styleClass']"
      [readonlyInput]="props['readonlyInput']"
      [minDate]="props['minDate'] ?? null"
      [maxDate]="props['maxDate'] ?? null"
      [showOnFocus]="props['showOnFocus'] ?? true"
      [appendTo]="props['appendTo'] ?? null"
      [showClear]="props['showClear'] ?? null"
      (onSelect)="props['onSelect']?.($event)"
      [iconDisplay]="'input'"
      [defaultDate]="props['defaultDate'] ?? null"
      [view]="props['view'] ?? 'date'"
    >
    <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
        <i
          class="material-icons-outlined cursor-pointer calendar-icon"
          [class.disabled]="!!field.props.disabled"
          (click)="clickCallBack($event)"
        >calendar_today</i>
    </ng-template>
    </p-calendar>
</span>
