import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';

import { FormDebuggerComponent, FormTextLabelComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    AccordionModule,
    TabViewModule
  ],
  declarations: [
    FormDebuggerComponent,
    FormTextLabelComponent
  ],
  exports: [
    FormDebuggerComponent,
    FormTextLabelComponent
  ]
})
export class SersiAngularFormlyCoreModule {
}
