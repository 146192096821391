import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProductCreateDto, ProductDto, ProductListRequestDto, ProductListResultDto, ProductToggleActiveRequestDto, ProductUpdateDto } from '@ifhms/models/admin';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { map, Observable } from 'rxjs';

@Injectable()
export class OperationProductManagementService {

  constructor(private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string) {
  }

  get(operationId: string, productId: string): Observable<ProductDto> {
    return this.http.get<ProductDto>(`${this.apiUrl}/Operation/${operationId}/Products/${productId}`).pipe(
      map((x: ProductDto) => {

        for (const route of x.routes) {
          for (const detail of route.productRouteDetails) {
            detail.effectiveFrom = new Date(detail.effectiveFrom);
            if (detail.effectiveTo) { // Nullish coalescing assignment operator did not work here.
              detail.effectiveTo = new Date(detail.effectiveTo as unknown as Date);
            }
          }
        }
        return x;
      })
    );
  }

  create(operationId: string, productDto: ProductCreateDto): Observable<ProductDto> {
    return this.http.post<ProductDto>(`${this.apiUrl}/Operation/${operationId}/Products`, productDto);
  }

  update(operationId: string, productDto: ProductUpdateDto): Observable<ProductDto> {
    return this.http.put<ProductDto>(`${this.apiUrl}/Operation/${operationId}/Products`, productDto);
  }

  getSelectList(operationId: string): Observable<SelectListItemWithTranslationDto[]> {
    return this.http.get<SelectListItemWithTranslationDto[]>(`${this.apiUrl}/Operation/${operationId}/Products/SelectList`);
  }

  getTable(operationId: string, request: ProductListRequestDto): Observable<ProductListResultDto> {

    const httpParams: HttpParamsOptions = {
      fromObject: { ...request }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<ProductListResultDto>(`${this.apiUrl}/Operation/${operationId}/Products/Page`, options);
  }

  toggleActiveStatus(operationId: string, toggleActiveRequest: ProductToggleActiveRequestDto): Observable<ProductListResultDto> {
    return this.http.put<ProductListResultDto>(`${this.apiUrl}/Operation/${operationId}/Products/ToggleActiveStatus`, toggleActiveRequest);
  }

  isCodeUnique(operationId: string, code: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        code
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Operation/${operationId}/Products/IsCodeUnique`, options);
  }
}