import { Injectable } from '@angular/core';
import { AppContextDto, ContextLevel } from '@ifhms/models/shared';
import { TreatmentProtocolRegimeListResultDto, TreatmentProtocolRegimesListRequestDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';
import { EnterpriseTreatmentProtocolRegimesService } from './enterprise-treatment-protocol-regimes.service';
import { OperationTreatmentProtocolRegimesService } from './operation-treatment-protocol-regimes.service';

@Injectable()
export class TreatmentProtocolRegimesService {

  constructor(private enterpriseTreatmentProtocolRegimesService: EnterpriseTreatmentProtocolRegimesService,
    private operationTreatmentProtocolRegimesService: OperationTreatmentProtocolRegimesService) {

  }

  getTable(appContext: AppContextDto, request: TreatmentProtocolRegimesListRequestDto): Observable<TreatmentProtocolRegimeListResultDto> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseTreatmentProtocolRegimesService.getTable(request);
      case ContextLevel.Operation:
        return this.operationTreatmentProtocolRegimesService.getTable(appContext.levelId, request);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

}
