<p class="mt-0">{{ dialogData.message }}</p>
<div class="container-footer-buttons">
  <button
    pButton
    iconPos="left"
    class="p-button-secondary"
    data-ele="cancel-btn"
    [ngClass]="dialogData.rejectButtonStyleClass"
    [icon]="dialogData.rejectIcon || ''"
    [label]="dialogData.rejectLabel!"
    [rounded]="true"
    [text]="true"
    (click)="onCancel()"
  ></button>
  <button
    pButton
    iconPos="left"
    class="p-button-danger"
    data-ele="confirm-delete-btn"
    [ngClass]="dialogData.acceptButtonStyleClass"
    [rounded]="true"
    (click)="onConfirm()"
  >
  <i *ngIf="dialogData.acceptIcon !== 'none'" class="material-icons-outlined mr-1"> {{ dialogData.acceptIcon || 'delete' }}</i>
  {{ dialogData.acceptLabel! }}
  </button>
</div>
