import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as CompanyReducer from './company.reducer';

export const selectFeatureState = createFeatureSelector<CompanyReducer.State>(CompanyReducer.FEATURE_NAME);

const selectState = createSelector(
  selectFeatureState,
  (state: CompanyReducer.State) => state
);

const selectCompany = createSelector(selectState, CompanyReducer.getCompany);

const selectCompanyId = createSelector(selectState, CompanyReducer.getCompanyId);

const selectCompanySlug = createSelector(selectState, CompanyReducer.getCompanySlug);

const selectCompanyName = createSelector(selectState, CompanyReducer.getCompanyName);

const selectLoading = createSelector(selectState, CompanyReducer.getLoading);

const selectLoaded = createSelector(selectState, CompanyReducer.getLoaded);

const selectIsActive = createSelector(selectState, CompanyReducer.getIsActive);

const selectCompanyPermissions = createSelector(selectState, CompanyReducer.getCompanyPermissions);

export const CompanySelectors = {
  selectCompanyId,
  selectCompanySlug,
  selectCompanyName,
  selectState,
  selectCompany,
  selectLoaded,
  selectLoading,
  selectIsActive,
  selectCompanyPermissions
};
