import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SLIDER_MIN_NUMBER_INPUT, SLIDER_MAX_NUMBER_INPUT } from '@sersi/angular/formly/core';

@UntilDestroy()
@Component({
  selector: 'sersi-range-slider',
  templateUrl: './range-slider-type.component.html',
  styleUrls: ['./range-slider-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeSliderTypeComponent extends FieldType<FieldTypeConfig> {
  rangeValues: (number)[] | null = null;
  rangeMin = SLIDER_MIN_NUMBER_INPUT;
  rangeMax = SLIDER_MAX_NUMBER_INPUT;

  rangeValueLeft: number;
  rangeValueRight: number;

  override defaultOptions = {
    props: {
      maxFractionDigits: 0,
      minFractionDigits: 0
    }
  };

  onInputChangeLeft(leftVal: number): void {
    this.rangeValueLeft = leftVal
    this.rangeValues = [this.rangeValueLeft, this.rangeValueRight]
  }

  onInputChangeRight(rightVal: number): void {
    // by default set the min range to 0 if the max has been manually set
    if (!this.rangeValueLeft) {
      this.rangeValueLeft = this.rangeMin
    }
    this.rangeValueRight = rightVal
    this.rangeValues = [this.rangeValueLeft, this.rangeValueRight]
  }

  onSlidersChange(sliderValues: any): void {
    this.rangeValueLeft = sliderValues?.[0]
    this.rangeValueRight = sliderValues?.[1]
  }
}

