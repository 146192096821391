import { createAction, props } from '@ngrx/store';
import { CompanyDto } from '@ifhms/models/admin';

const NAMESPACE = '[COMPANY]';

const get = createAction(
  `${NAMESPACE} Get`,
  props<{ slug: string, forceReload: boolean }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ company: CompanyDto }>()
);

const getSuccessCached = createAction(`${NAMESPACE} Get Success Cached`);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const CompanyActions = {
  get,
  getSuccess,
  getSuccessCached,
  error,
  reset
};
