import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CommonPermissionsMap, CommonPermissionsService } from '@common/angular/permissions';
import { getCurrentRouteWithData, transformPermission } from '@common/angular/utils';
import { OperationFacade } from '@ifhms/admin/web/domain/state/operation';
import { AH_FMS_ADMIN_API_CONFIG } from '@ifhms/common/angular/data-access/admin-api';
import { FeedlotPermissionsDto } from '@ifhms/models/shared';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OperationPermissionsService extends CommonPermissionsService {

  constructor(
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string,
    private http: HttpClient,
    private operationFacade: OperationFacade,
    private router: Router
  ) {
    super();
    this.permissions$ = this.operationFacade.permissions$.pipe(
      map(permissions => transformPermission(permissions))
    );
  }

  getOperationPermissionsForUser(operationId: string): Observable<FeedlotPermissionsDto> {
    return this.http.get<FeedlotPermissionsDto>(`${this.apiUrl}/Operation/${operationId}/Permissions`);
  }

  protected override getLoadedPermissions(): CommonPermissionsMap | null {
    return this.loadedPermissions || this.getRouteResolverPermissions();
  }

  private getRouteResolverPermissions(): CommonPermissionsMap | null {
    const routeData = getCurrentRouteWithData(this.router);
    return transformPermission(routeData['snapshot']?.data?.operation?.permissions);
  }

}
