import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';

import { scopeLoader } from '@common/angular/translation';
import { getIndexedMapFromList } from '@common/angular/utils';

import { AnimalSelectorAnimalItem } from '../../interfaces';
import { AnimalSelectorTableComponent } from '../animal-selector-table/animal-selector-table.component';

@Component({
  selector: 'ifhms-animal-selector-dual-list-box',
  templateUrl: './animal-selector-dual-list-box.component.html',
  styleUrls: ['./animal-selector-dual-list-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'animalSelector',
        alias: 'animalSelector',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class AnimalSelectorDualListBoxComponent {
  readonly translateScope = 'animalSelector.components.animal-selector-dual-list-box';
  activeAnimalsOnly = false;

  @Input()
  set animals(value: AnimalSelectorAnimalItem[] | null) {
    if (!value) return;
    this._animals = [...value];
    this.setAvailableAnimals();
  }

  @Input()
  set selectedAnimals(value: AnimalSelectorAnimalItem[] | null) {
    if (!value) return;
    this._selectedAnimals = [...value];
    this.setAvailableAnimals();
  }
  get selectedAnimals(): AnimalSelectorAnimalItem[] {
    return this._selectedAnimals;
  }

  @Output() selectedAnimalsChange = new EventEmitter<AnimalSelectorAnimalItem[]>();

  availableAnimals: AnimalSelectorAnimalItem[] = [];
  availableAnimalsSelection: AnimalSelectorAnimalItem[] = [];

  selectedAnimalsSelection: AnimalSelectorAnimalItem[] = [];

  private _selectedAnimals: AnimalSelectorAnimalItem[] = [];
  private _animals: AnimalSelectorAnimalItem[] = [];

  private readonly cdr = inject(ChangeDetectorRef);

  @ViewChild(AnimalSelectorTableComponent, { static: false }) childComponent: AnimalSelectorTableComponent;

  availableAnimalsSelectionChange(selection: AnimalSelectorAnimalItem[]): void {
    this.availableAnimalsSelection = selection;
  }

  selectedAnimalsSelectionChange(selection: AnimalSelectorAnimalItem[]): void {
    this.selectedAnimalsSelection = selection;
  }

  addAnimalsToSelectedList(): void {
    const selectedAnimals = [...this.selectedAnimals, ...this.availableAnimalsSelection];
    this.resetSelections();
    this.selectedAnimalsChange.emit(selectedAnimals);
  }

  removeAnimalsFromSelectedList(): void {
    const animalIdsToRemove = getIndexedMapFromList(this.selectedAnimalsSelection, 'id')
    const selectedAnimals = this.selectedAnimals.filter((animal) => !animalIdsToRemove[animal.id]);
    this.resetSelections();
    this.selectedAnimalsChange.emit(selectedAnimals);
  }

  private resetSelections(): void {
    this.availableAnimalsSelection = [];
    this.selectedAnimalsSelection = [];
    this.childComponent.lastSelectedRowIndex = -1;
  }

  private setAvailableAnimals(): void {
    const selectedAnimalIds = getIndexedMapFromList(this.selectedAnimals, 'id');
    this.availableAnimals = this._animals.filter((animal) => {
      const isSelected = selectedAnimalIds[animal.id];
      const isActive = animal.status === 'Active';
      return !isSelected && (!this.activeAnimalsOnly || isActive);
    });
  }

  onActiveAnimalsOnlyChange(): void {
    this.availableAnimalsSelection = []
    this.setAvailableAnimals();
    this.childComponent.lastSelectedRowIndex = -1;
  }
}
