import { FeedlotPermissionsDto } from '@ifhms/models/shared';
import { createReducer, on } from '@ngrx/store';
import { OperationPermissionsActions } from './operation-permissions.actions';

export interface State {
  operationPermissions: FeedlotPermissionsDto | null;
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  operationPermissions: null,
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,

  on(OperationPermissionsActions.getOperationPermissions, (state) => {
    return {
      ...state,
      loading: true,
      loaded: false
    }
  }),

  on(OperationPermissionsActions.getOperationPermissionsSuccess, (state, action) => {
    return {
      ...state,
      operationPermissions: action.operationPermissions,
      loading: false,
      loaded: true
    }
  }),

  on(OperationPermissionsActions.reset, () => initialState)
);

export const getOperationPermissions = (state: State): FeedlotPermissionsDto | null => state.operationPermissions;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
