import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ButtonMenuTypeComponent } from './button-menu-type/button-menu-type.component';

@NgModule({
  imports: [CommonModule, MenuModule, ButtonModule],
  declarations: [ButtonMenuTypeComponent],
  exports: [ButtonMenuTypeComponent]
})
export class SersiAngularFormlyTypesButtonMenuModule {}
