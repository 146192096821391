import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { DxGroupsActions } from './dx-groups.actions';
import * as reducer from './dx-groups.reducer';
import { DxGroupsSelectors } from './dx-groups.selectors';

@Injectable()
export class DxGroupsFacade {

  loaded$ = this.store.select(DxGroupsSelectors.selectLoaded);
  pmGroups$ = getLoadedSelectItems(this.store.select(DxGroupsSelectors.selectPmGroups), this.loaded$);
  txGroups$ = getLoadedSelectItems(this.store.select(DxGroupsSelectors.selectTxGroups), this.loaded$);

  constructor(private store: Store<reducer.State>) {}

  get(): void {
    this.store.dispatch(DxGroupsActions.get());
  }
}