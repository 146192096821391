import { ChangeDetectionStrategy, Component } from '@angular/core';

import { LIST_FILTER_DEFAULT_DEBOUNCE } from '@common/angular/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { of, take } from 'rxjs';

@UntilDestroy()

@Component({
  selector: 'sersi-button',
  templateUrl: './auto-complete-type.component.html',
  styleUrls: ['./auto-complete-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoCompleteTypeComponent extends FieldType<FieldTypeConfig> {

  override defaultOptions = {
    props: {
      type: 'text',
      optionsLabel: 'code',
      showClear: false,
      suggestions: of([]),
      forceSelection: false,
      searchDelay: LIST_FILTER_DEFAULT_DEBOUNCE
    }
  };

  onBlur(event: any): void {
    this.props['suggestions'].pipe(
      take(1),
      untilDestroyed(this)
    ).subscribe((suggestions: any) => {
      // check if input value matches anything in suggestions list and set value if true
      const index = suggestions.findIndex((val: { code: string; }) => val.code === event.target.value.toString())
      if (index > -1 && this.formControl.value !== suggestions[index]) {
        this.formControl.setValue(suggestions[index])
      }
    }
    )
    this.props['onBlur']?.(event)
  }
}

