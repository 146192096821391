import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sersi-color-box',
  templateUrl: './color-box.component.html',
  styleUrls: ['./color-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorBoxComponent {

  @HostBinding('class') class = 'color-box';

  @Input() hexCode = '';

  @HostBinding('style.background-color') get backgroundColor(): string {
    return this.hexCode;
  }

  @HostBinding('style.border') get border(): string {
    return this.hexCode?.toLowerCase() !== '#ffffff' ? '' : '1px solid black';
  }
}
