import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-button',
  templateUrl: './button-type.component.html',
  styleUrls: ['./button-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonTypeComponent extends FieldType<FieldTypeConfig> {
  @Input() styleClass = '';
  @Input() buttonAttrDataEle: string;

  override defaultOptions = {
    props: {
      disabled: false,
      buttonClass: 'p-button-outlined',
      iconPos: 'left'
    }
  };

  onClick(): void {
    this.props['onClick']?.(this.model, this.formControl);
  }
}
