import { createSelector } from '@ngrx/store';

import { selectFeatureState, State } from '../reducer';
import * as reducer from './lot-subgroups.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.lotSubGroups
);

const selectItems = createSelector(selectState, reducer.getAll);

export const LotSubGroupsSelectors = {
  selectItems
};
