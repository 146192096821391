import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { TxGroupsActions } from './tx-groups.actions';
import * as reducer from './tx-groups.reducer';
import { TxGroupsSelectors } from './tx-groups.selectors';

@Injectable()
export class TxGroupsFacade {

  loaded$ = this.store.select(TxGroupsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(TxGroupsSelectors.selectTxGroups), this.loaded$);

  constructor(private store: Store<reducer.State>) {}

  get(): void {
    this.store.dispatch(TxGroupsActions.get());
  }
}