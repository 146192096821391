import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ReleaseNotesDto } from '@ifhms/models/admin';

@Injectable()
export class ReleaseNotesService {
  constructor(private http: HttpClient, @Inject('apiUrl') private apiUrl: string) {
  }

  get(): Observable<ReleaseNotesDto[]> {
    return this.http.get<ReleaseNotesDto[]>(`${this.apiUrl}/ReleaseNotes`);
  }
}
