import { Injectable } from '@angular/core';
import { filter, map, Observable, of } from 'rxjs';
import { HashMap, ProviderScope, TranslocoService } from '@jsverse/transloco';

import { getLanguageCode } from '../utils';

@Injectable()
export class TranslateService {

  get activeLang(): string {
    return this.translocoService.getActiveLang();
  }

  get activeLangCode(): string {
    return getLanguageCode(this.activeLang);
  }

  langChanges$: Observable<string> = this.translocoService.langChanges$;

  constructor(private translocoService: TranslocoService) { }

  getTranslation(scopedNamespace: string, key: string, params?: HashMap): string {
    const scope = this.getScope(scopedNamespace);
    const translationKey = this.getTranslationKey(scopedNamespace, key);
    return this.translocoService.translate(translationKey, params, scope);
  }

  getTranslation$(scopedNamespace: string, key: string, params?: HashMap): Observable<string> {
    const scope = this.getScope(scopedNamespace);
    const translationKey = this.getTranslationKey(scopedNamespace, key);
    return this.translocoService.selectTranslate(translationKey, params, scope);
  }

  getGlobalTranslation(key: string, params?: HashMap): string {
    return this.translocoService.translate(key, params);
  }

  getGlobalTranslation$(key: string, params?: HashMap): Observable<string> {
    return this.translocoService.selectTranslate(key, params);
  }

  translateObject(scopedNamespace: string, objectKey: string, params?: HashMap): HashMap<string> {
    const scope = this.getScope(scopedNamespace);
    const translationKey = this.getTranslationKey(scopedNamespace, objectKey);
    return this.translocoService.translateObject(translationKey, params, scope);
  }

  translateObject$(scopedNamespace: string, objectKey: string, params?: HashMap): Observable<HashMap<string>> {
    const scope = this.getScope(scopedNamespace);
    const translationKey = this.getTranslationKey(scopedNamespace, objectKey);
    return this.translocoService.selectTranslateObject(translationKey, params, scope);
  }

  lazyLoadScope(providerScope: ProviderScope): Observable<void> {
    return this.translocoService.selectTranslate('', {}, providerScope);
  }

  getTranslationLoaded$(scope?: string | null): Observable<void> {
    // if translation is already loaded, return
    if (scope) {
      const lang = this.translocoService.getActiveLang()
      const result = this.translocoService.getTranslation(`${scope}/${lang}`)
      if (result && Object.keys(result).length !== 0) {
        return of(void 0)
      }
    }
    return this.translocoService.events$
      .pipe(
        filter(event => event.type === 'translationLoadSuccess'
        ),
        filter(event => event.payload.scope === scope),
        map(() => void 0)
      );
  }

  private getScope(scopedNamespace: string): string {
    return scopedNamespace.split('.')[0];
  }

  private getTranslationKey(scopedNamespace: string, key: string): string {
    const scopeIndex = scopedNamespace.indexOf('.');
    const namespace = scopeIndex !== -1 ? scopedNamespace.slice(scopeIndex + 1) : null;
    return namespace ? `${namespace}.${key}` : key;
  }

}
