import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { CommonAngularPermissionsModule } from '@common/angular/permissions';
import { CompanyHasPermissionDirective, OperationHasPermissionDirective } from './directives';
import { OperationPermissionGuard } from './guards';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    CommonAngularPermissionsModule
  ],
  declarations: [
    CompanyHasPermissionDirective,
    OperationHasPermissionDirective
  ],
  providers: [
    OperationPermissionGuard
  ],
  exports: [
    CompanyHasPermissionDirective,
    OperationHasPermissionDirective
  ]
})
export class IfhmsAdminWebDomainPermissionsModule {}
