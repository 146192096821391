import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { MessageService } from 'primeng/api';

import { AhCommonDialogService } from '@common/angular/utils';

import { ApiErrorType } from '../interfaces';
import { isCustomStatusStatus } from '../utils';

@Injectable()
export class ErrorHandlingService implements ErrorHandler {

  private readonly translateScope = 'common-errors';

  error$ = new Subject<HttpErrorResponse>();

  protected readonly dialogService = inject(AhCommonDialogService);
  protected processUnhandledApiError?(resp: HttpErrorResponse): void;

  private readonly messageService = inject(MessageService);
  private readonly translationService = inject(TranslocoService);

  handleError(error: Error | HttpErrorResponse, useDefaultErrAlerts = true): void {
    const isClientError = error instanceof Error;

    if (isClientError) {
      useDefaultErrAlerts && this.showToastErr(error.message);
      return;
    }

    const isCustomError = isCustomStatusStatus(error.status);
    if (isCustomError && useDefaultErrAlerts) {
      this.handleCustomStatusResponse(error);
    }

    this.error$.next(error);
  }

  protected getTranslation(key: string): string {
    return this.translationService.translate(`${this.translateScope}.${key}`);
  }

  private handleCustomStatusResponse(resp: HttpErrorResponse): void {
    switch (resp.status) {
      case ApiErrorType.ApiErrorException:
        return this.processApiErrorException(resp);
      case ApiErrorType.ApiErrorConfirmationException:
        return this.processApiErrorConfirmationException(resp);
      default:
        return this.processUnhandledApiError?.(resp);
    }
  }

  private processApiErrorException(resp: HttpErrorResponse): void {
    this.showToastErr(resp.error.text);
  }

  private processApiErrorConfirmationException(resp: HttpErrorResponse): void {
    this.dialogService.openErrorModal({
      header: this.getTranslation('confirmation-required-exception.title'),
      message: resp.error.text || this.getTranslation('confirmation-required-exception.message')
    });
  }

  private showToastErr(message: string): void {
    this.messageService.add({
      severity: 'error',
      summary: this.getTranslation('generic-error.title'),
      detail: message || this.getTranslation('generic-error.message')
    });
  }

}
