import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CompanyRoleDto, CompanyRoleUpdateDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyRolePermissionsService {
  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient
  ) {
  }

  get(companyId: string, id: string): Observable<CompanyRoleDto> {
    return this.http.get<CompanyRoleDto>(`${this.apiUrl}/Company/${companyId}/Permissions/${id}`);
  }

  update(companyId: string, roleUpdate: CompanyRoleUpdateDto): Observable<CompanyRoleDto> {
    return this.http.put<CompanyRoleDto>(`${this.apiUrl}/Company/${companyId}/Permissions`, roleUpdate);
  }
}
