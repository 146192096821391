import { Injectable } from '@angular/core';

import { OperationFacade } from '@ifhms/admin/web/domain/state/operation';
import { FeedlotSettingsService } from '@ifhms/common/angular/data-access/admin-api';
import { FeedlotReHandlingSettingsDto } from '@ifhms/models/admin';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';

import { catchError, exhaustMap, map, of, tap, withLatestFrom } from 'rxjs';
import { ReHandlingSettingsActions } from './re-handling.actions';

@Injectable({
  providedIn: 'root'
})
export class ReHandlingSettingsEffects {
  translateScope = 'feedlotSettings.state';

  constructor(
    private actions$: Actions,
    private feedlotSettingsService: FeedlotSettingsService,
    private operationFacade: OperationFacade,
    private translateService: TranslocoService,
    public messageService: MessageService
  ) {
  }

  getReHandlingSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReHandlingSettingsActions.getReHandlingSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([, operationId]) =>
        this.feedlotSettingsService.getReHandlingSettings(operationId).pipe(
          map((settings: FeedlotReHandlingSettingsDto) => ReHandlingSettingsActions.getReHandlingSettingsSuccess({ settings })),
          catchError(() => of(ReHandlingSettingsActions.error()))
        )
      )
    )
  );

  updateReHandlingSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReHandlingSettingsActions.updateReHandlingSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([action, operationId]) =>
        this.feedlotSettingsService.updateReHandlingSettings(operationId, action.settings).pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: this.getTranslation('success-title'),
              detail: this.getTranslation('saved-success-message')
            });
          }),
          map((settings: FeedlotReHandlingSettingsDto) => ReHandlingSettingsActions.updateReHandlingSettingsSuccess({ settings })),
          catchError(() => of(ReHandlingSettingsActions.error()))
        )
      )
    )
  );

  getTranslation(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }

}
