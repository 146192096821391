import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

import { CommonAngularUtilsModule } from '@common/angular/utils';
import { HotkeyModule } from 'angular2-hotkeys';
import { TooltipModule } from 'primeng/tooltip';
import { GridAddButtonRendererComponent } from './grid-type/add-button-renderer/add-button-renderer.component';
import { GridDeleteButtonRendererComponent } from './grid-type/delete-button-renderer/delete-button-renderer.component';
import {
  DosingGunButtonRendererComponent
} from './grid-type/dosing-gun-button-renderer/dosing-gun-button-renderer.component';
import { MenuModule } from 'primeng/menu';
import { GridRowRendererComponent } from './grid-type/row-renderer/grid-row-renderer.component';
import { GridInfoButtonRendererComponent } from './grid-type/info-button-renderer/info-button-renderer.component';
import { GridTypeComponent } from './grid-type/grid-type.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    FormlyModule,
    CommonAngularUtilsModule,
    NgOptimizedImage,
    TooltipModule,
    MenuModule,
    HotkeyModule,
    CheckboxModule,
    FormsModule
  ],
  declarations: [
    GridTypeComponent,
    GridInfoButtonRendererComponent,
    GridDeleteButtonRendererComponent,
    GridAddButtonRendererComponent,
    GridRowRendererComponent,
    DosingGunButtonRendererComponent
  ],
  exports: [
    GridTypeComponent,
    GridInfoButtonRendererComponent,
    GridDeleteButtonRendererComponent,
    GridAddButtonRendererComponent,
    GridRowRendererComponent
  ]
})
export class SersiAngularFormlyTypesGridModule {
}
