import { Injectable } from '@angular/core';
import { ReferenceDataService } from '@ifhms/common/angular/data-access/admin-api';
import { ReferenceDataType } from '@ifhms/models/admin';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { ContextService } from '@ifhms/common/angular/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';
import { DxGroupsActions } from './dx-groups.actions';

@Injectable({
  providedIn: 'root'
})
export class DxGroupsEffects {
  constructor(
    private actions$: Actions,
    private contextService: ContextService,
    private service: ReferenceDataService
  ) {}
  
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DxGroupsActions.get),
      withLatestFrom(this.contextService.appContext$),
      exhaustMap(([, appContext]) => {
        return this.service
          .getSelectListByType(
            appContext,
            ReferenceDataType.DxGroup
          )
          .pipe(
            map((items: SelectListItemWithTranslationDto[]) => {
              if (!items) return DxGroupsActions.error();
              return DxGroupsActions.getSuccess({ items });
            }),
            catchError(() => of(DxGroupsActions.error()))
          );
      })
    )
  );
}
  