import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { FieldViewValueType } from '@common/angular/interfaces';

@Pipe({
  name: 'fieldViewValue'
})
export class FieldViewValuePipe implements PipeTransform {

  private tzOffset = new Date().getTimezoneOffset();

  constructor(private datePipe: DatePipe) {
  }

  transform(fieldVal: SafeHtml | string | number | Date | null | undefined, fieldType = FieldViewValueType.STRING, emptyPlaceholder = '-'): string | number {
    switch (fieldType) {
      case FieldViewValueType.BOOLEAN:
        return fieldVal ? 'Yes' : 'No';
      case FieldViewValueType.STRING:
        return <string>fieldVal || emptyPlaceholder;
      case FieldViewValueType.DATE:
        return fieldVal ? this.datePipe.transform(<string>fieldVal) as string : emptyPlaceholder;
      case FieldViewValueType.LOCAL_DATE:
        return fieldVal ? this.datePipe.transform(<string>fieldVal, void 0, `${this.tzOffset}`) as string : emptyPlaceholder;
      case FieldViewValueType.NUMBER: {
        const numberVal = <number>fieldVal;
        const hasValue = !!numberVal !== null || numberVal === 0;
        return hasValue ? numberVal : emptyPlaceholder;
      }
      default:
        return emptyPlaceholder;
    }
  }
}
