import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { HashMap } from '@common/angular/interfaces';

import { EventDetailsReportParams, ExportByTagParams, ExportLotBillingDetailsReportParams } from '../dtos';

@Injectable()
export class ReportDataExportService {

  constructor(
    private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string
  ) {}

  getWeightByWorkOrderExport(params: EventDetailsReportParams): Observable<any> {
    const options = this.getWorkOrderExportOptions(params);
    return this.http.get(`${this.apiUrl}/DataExports/ExportWeightByWorkOrder`, options);
  }

  private getWorkOrderExportOptions(params: EventDetailsReportParams): HashMap {
    return {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            workOrderId: params.workOrderId ?? '',
            workOrderType: params.workOrderType
          }
        }
      ),
      responseType: 'arraybuffer'
    }
  }

  getWeightByTagExport(params: ExportByTagParams): Observable<any> {
    return this.http.put(`${this.apiUrl}/DataExports/ExportWeightByTag`, params, { responseType: 'arraybuffer' });
  }

  getBirthdayByTagExport(params: ExportByTagParams): Observable<any> {
    return this.http.put(`${this.apiUrl}/DataExports/ExportBirthdayByTag`, params, { responseType: 'arraybuffer' });
  }

  getCCIAExportByTag(params: ExportByTagParams): Observable<any> {
    return this.http.put(`${this.apiUrl}/DataExports/ExportCCIAData`, params, { responseType: 'arraybuffer' });
  }

  getCustomFieldsByTagExport(params: ExportByTagParams): Observable<any> {
    return this.http.put(`${this.apiUrl}/DataExports/ExportCustomFieldsByTag`, params, { responseType: 'arraybuffer' });
  }

  getLotBillingExport(params: ExportLotBillingDetailsReportParams): Observable<any> {
    const options = this.getLotBillingExportOptions(params);
    return this.http.get(`${this.apiUrl}/DataExports/ExportLotBilling`, options);
  }

  private getLotBillingExportOptions(params: ExportLotBillingDetailsReportParams): HashMap {
    return {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            lotBillingId: params.lotBillingId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    }
  }

}