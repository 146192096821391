import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TimezoneHttpInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const timeZoneRequest = this.getRequestWithTZHeader(req);
    return next.handle(timeZoneRequest);
  }

  private getRequestWithTZHeader(req: HttpRequest<unknown>): HttpRequest<unknown> {
    const dateNow = new Date();
    const headers = req.headers.set('Timezone-Offset', `${dateNow.getTimezoneOffset()}`);
    return req.clone({ headers });
  }
}
