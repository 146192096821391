import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormlyModule } from '@ngx-formly/core';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';

import { SersiAngularUiCoreModule } from '@sersi/angular/ui/core';
import { SersiAngularUiSpinModule } from '@sersi/angular/ui/spin';
import { SersiAngularUiTypographyModule } from '@sersi/angular/ui/typography';

import { CommonUserProfileEffects, CommonUserProfileFacade, FEATURE_NAME, reducer } from './+state';
import { UserProfileLangComponent, CommonUserProfileTopNavComponent } from './components';
import { CommonAuthUserProfileResolver } from './resolvers';
import { CommonUserProfileApiService } from './services';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    FormlyModule,
    AvatarModule,
    MenuModule,
    ButtonModule,
    CardModule,
    DividerModule,
    SersiAngularUiSpinModule,
    SersiAngularUiCoreModule,
    SersiAngularUiTypographyModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([ CommonUserProfileEffects ]),
    NgSelectModule,
    FormsModule
  ],
  declarations: [
    CommonUserProfileTopNavComponent,
    UserProfileLangComponent
  ],
  exports: [
    CommonUserProfileTopNavComponent,
    UserProfileLangComponent
  ],
  providers: [
    DialogService,
    CommonUserProfileEffects,
    CommonUserProfileFacade,
    CommonAuthUserProfileResolver,
    CommonUserProfileApiService
  ]
})
export class CommonUserProfileModule {
}
