import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HashMap } from '@common/angular/interfaces';

@Component({
  selector: 'ah-utils-common-information-dialog',
  templateUrl: 'common-information-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonInformationDialogComponent {

  dialogData: HashMap;

  constructor(
    private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.dialogData = this.config.data;
  }

  onClose(): void {
    this.dialogRef.close(true);
  }

}
