import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { FeedingProgramsActions } from './feeding-programs.actions';
import * as reducer from './feeding-programs.reducer';
import { FeedingProgramsSelectors } from './feeding-programs.selectors';

@Injectable()
export class FeedingProgramsFacade {

  loaded$ = this.store.select(FeedingProgramsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(FeedingProgramsSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}
  
  get(): void {
    this.store.dispatch(FeedingProgramsActions.get());
  }
}
