export function ComponentInitializedState() {
  return function (constructor: any): void {

    const original = constructor.prototype.ngOnInit;

    constructor.prototype.ngOnInit = function (): void {

      if (original) {
        original.apply(this);
      }

      this.isInitialized = true;
    };
  };
}