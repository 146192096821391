import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { catchError, exhaustMap, filter, map, of, tap, withLatestFrom } from 'rxjs';

import { getPreviousValue } from '@common/angular/utils';
import { CompaniesService } from '@ifhms/common/angular/data-access/admin-api';
import { CompanyDto } from '@ifhms/models/admin';

import { CompaniesActions } from './companies.actions';
import { CompaniesFacade } from './companies.facade';
import { TranslateService } from '@common/angular/translation';

@Injectable()
export class CompaniesEffects {
  private readonly translateScope = 'domain.state.companies.effects';

  constructor(
    private actions$: Actions,
    private companiesService: CompaniesService,
    private companiesFacade: CompaniesFacade,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
  }

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.create),
      exhaustMap((action) =>
        this.companiesService.create(action.companyCreateDto).pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: this.getTranslation('create-success-title'),
              detail: this.getTranslation('create-success-message')
            })
          }),
          map((companies: CompanyDto[]) => CompaniesActions.createSuccess({ companies })),
          catchError(() => of(CompaniesActions.error()))
        )
      )
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.get),
      withLatestFrom(getPreviousValue(this.companiesFacade.state$)),
      filter(([action, state]) => {
        if (action.forceReload) return true;
        return !state.loaded && !state.loading;
      }),
      exhaustMap(() => this.companiesService.get().pipe(
        map((result: CompanyDto[]) =>
          CompaniesActions.getSuccess({
            companies: result
          })
        ),
        catchError(() => of(CompaniesActions.error()))
      ))
    )
  );

  getCached$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.get),
      withLatestFrom(getPreviousValue(this.companiesFacade.state$)),
      filter(([action, state]) => {
        return !action.forceReload && state.loaded;
      }),
      map(() => CompaniesActions.getSuccessCached())
    )
  );

  private getTranslation(key: string): string {
    return this.translateService.getGlobalTranslation(`${this.translateScope}.${key}`);
  }
}
