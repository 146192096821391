import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import {
  TreatmentProtocolDefinitionCreateDto,
  TreatmentProtocolDefinitionDto,
  TreatmentProtocolDefinitionUpdateDto,
  TreatmentProtocolRegimeListResultDto,
  TreatmentProtocolRegimesListRequestDto
} from '@ifhms/models/admin';
import { Observable } from 'rxjs';
import { SelectListItemDto } from '@common/angular/core/models';

@Injectable()
export class OperationTreatmentProtocolRegimesService {
  constructor(private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string) {
  }

  getTable(operationId: string, request: TreatmentProtocolRegimesListRequestDto): Observable<TreatmentProtocolRegimeListResultDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...request
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<TreatmentProtocolRegimeListResultDto>(`${this.apiUrl}/Operation/${operationId}/TreatmentProtocols/Regimes`, options);
  }

  getDefinition(operationId: string, txGroupId: string): Observable<TreatmentProtocolDefinitionDto> {
    return this.http.get<TreatmentProtocolDefinitionDto>(`${this.apiUrl}/Operation/${operationId}/TreatmentProtocols/${txGroupId}/Definition`);
  }

  getSelectList(operationId: string): Observable<SelectListItemDto[]> {
    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Operation/${operationId}/TreatmentProtocols/SelectList`);
  }

  updateDefinition(operationId: string, updateDto: TreatmentProtocolDefinitionUpdateDto): Observable<TreatmentProtocolDefinitionDto> {
    return this.http.put<TreatmentProtocolDefinitionDto>(`${this.apiUrl}/Operation/${operationId}/TreatmentProtocols/Definition`, updateDto);
  }

  create(levelId: string, createDto: TreatmentProtocolDefinitionCreateDto): Observable<TreatmentProtocolDefinitionDto> {
    return this.http.post<TreatmentProtocolDefinitionDto>(`${this.apiUrl}/Operation/${levelId}/TreatmentProtocols/Definition`, createDto);
  }
}
