import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { UnitsOfMeasureActions } from './units-of-measure.actions';
import * as reducer from './units-of-measure.reducer';
import { UnitsOfMeasureSelectors } from './units-of-measure.selectors';

@Injectable()
export class UnitsOfMeasureFacade {

  loaded$ = this.store.select(UnitsOfMeasureSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(UnitsOfMeasureSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}
  
  get(): void {
    this.store.dispatch(UnitsOfMeasureActions.get());
  }
}
