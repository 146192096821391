import { createReducer, on } from '@ngrx/store';

import { urlRouteHistoryActions } from './url-route-history.actions';
import { UrlRouteHistoryRecord } from '../../models';

export const ROUTES_HISTORY_FEATURE_NAME = 'route-history';

export interface RouteHistoryState {
  routes: UrlRouteHistoryRecord[];
}

export const initialState: RouteHistoryState = {
  routes: []
};

export const urlRouteHistoryReducer = createReducer(
  initialState,
  on(urlRouteHistoryActions.add, (state, { route }) => ({
    ...state,
    routes: [ ...state.routes, route]
  }))

);

export const getRoutes = (state: RouteHistoryState): UrlRouteHistoryRecord[] => state.routes;
