import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CompanyFacade } from '@ifhms/admin/web/domain/state/company';

import { getCurrentRouteDataFromSnapshot } from '@common/angular/utils';
import { CompanyPermissionsDto } from '@ifhms/models/admin';
import { filter, finalize, first, map, Observable, switchMap, tap } from 'rxjs';

import { CompanyPermissionsService } from '../services';

@Injectable({ providedIn: 'root' })
export class CompanyPermissionGuard {

  private loading: boolean;

  constructor(
    private companyFacade: CompanyFacade,
    private companyPermissionsService: CompanyPermissionsService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const routeData = getCurrentRouteDataFromSnapshot(state);
    return this.getCompanyPermissions(routeData['slug']).pipe(
      switchMap(() => this.companyPermissionsService.hasPermission(route.data?.['requiredPermission'])
        .pipe(
          map(hasPermission => {
            if (hasPermission) return true;
            return this.router.parseUrl('/');
          })
        )
      )
    );
  }

  private getCompanyPermissions(companySlug: string): Observable<CompanyPermissionsDto | null> {
    return this.companyFacade.state$.pipe(
      tap((companyState) => {
        if (!this.loading && !companyState.loaded) {
          this.loading = true;
          this.companyFacade.get(companySlug);
        }
      }),
      filter((state) => state.loaded),
      first(),
      map(state => state.company!.permissions),
      finalize(() => (this.loading = false))
    );
  }

}
