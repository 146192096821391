<div
  class="p-field default-form-field-wrapper checkbox-field-wrapper"
  [ngClass]="props['wrapperClass']"
  [class.field-error]="showError"
  [pTooltip]="showError ? (validationMessage?.errorMessage$ | async) : $any(null)"
>
  <div class="flex justify-content-between">
    <label *ngIf="props['label$']" class="sersi-label mb-0">
      {{ (props['label$'] | async) || '' }}
      <span
        *ngIf="props.required && props['hideRequiredMarker'] !== true"
        class="required-indicator"
        aria-hidden="true"
      >*</span
      >
    </label>
    <div class="checkbox-wrapper flex align-items-center gap-1">
      <label
        [for]="id + '_checkbox'"
        class="sersi-label mb-0"
      >{{ (props['checkboxLabel$'] | async) || ''}}
      </label>
      <p-checkbox
        [inputId]="id + '_checkbox'"
        [(ngModel)]="props['isChecked']"
        [readonly]="props['readonly'] || false"
        [formlyAttributes]="field"
        [binary]="true"
        [attr.data-ele]="key+'-checkbox'"
        (onChange)="props['onCheckboxChange']?.($event.checked)"
      ></p-checkbox>
    </div>
  </div>
  <formly-validation-message
      #validationMessage
      class="hidden"
      [field]="field"
  ></formly-validation-message>
  <ng-container #fieldComponent></ng-container>
</div>
