import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { isObject } from 'lodash-es';

import { HashMap } from '@common/angular/interfaces';

import { normalizeEmptyDate } from '../helpers';

@Injectable()
export class EmptyDateTransformInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map(event => {
        const resp = this.getResponseBody(event);

        if (!resp) return event;

        for (const key in resp) {
          // @ts-ignore
          resp[key] = normalizeEmptyDate(resp[key]);
        }

        return event;
      })
    );
  }

  private getResponseBody(event: HttpEvent<any>): HashMap | null {
    const isRespEvent = event instanceof HttpResponse;
    if (!isRespEvent) return null;

    const resp = event.body;

    return resp && isObject(resp) ? resp : null;
  }

}
