import { RouterStateSnapshot } from '@angular/router';
import * as fromRouter from '@ngrx/router-store';
import { RouterStateData } from './url-router.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RouterStateSerializer } from '@ngrx/router-store';

@Injectable()
export class UrlRouterCustomSerializer implements RouterStateSerializer<RouterStateData> {

  constructor(private store: Store<fromRouter.RouterReducerState<RouterStateData>>) {}

  serialize(routerState: RouterStateSnapshot): RouterStateData {
    let route = routerState.root;
    let routeData = route.data;

    while (route.firstChild) {
      route = route.firstChild;
      routeData = { ...routeData, ...route.data };
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams, routeData };
  }
}
