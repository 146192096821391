import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../index';
import * as CompaniesReducer from './companies.reducer';

export const selectFeatureState = createFeatureSelector<CompaniesReducer.State>(CompaniesReducer.FEATURE_NAME);

const selectState = createSelector(
  selectFeatureState,
  (state: State) => state
);

const selectCompanies = createSelector(
  selectState,
  CompaniesReducer.getCompanies
);

const selectCreating = createSelector(selectState, CompaniesReducer.getCreating);
const selectCreated = createSelector(selectState, CompaniesReducer.getCreated);

const selectLoading = createSelector(selectState, CompaniesReducer.getLoading);
const selectLoaded = createSelector(selectState, CompaniesReducer.getLoaded);

export const CompaniesSelectors = {
  selectState,
  selectCompanies,
  selectCreating,
  selectCreated,
  selectLoaded,
  selectLoading
};
