import { OperationPermissionsLotBilling } from './operation-permissions-lot-billing.enum';
import { OperationPermissionsProductManagement } from './operation-permissions-product-management.enum';
import { OperationPermissionsRefData } from './operation-permissions-ref-data.enum';
import { OperationPermissionsReport } from './operation-permissions-reports.enum';
import { OperationPermissionsSettings } from './operation-permissions-settings.enum';
import { OperationPermissionsUserManagement } from './operation-permissions-user-management.enum';
import {
  OperationPermissionsTreatmentProtocolsManagement
} from './operation-permissions-treatment-protocol-management.enum';

export type OperationFeaturePermission =
  OperationPermissionsLotBilling
  | OperationPermissionsProductManagement
  | OperationPermissionsRefData
  | OperationPermissionsReport
  | OperationPermissionsSettings
  | OperationPermissionsUserManagement
  | OperationPermissionsTreatmentProtocolsManagement;

export const OperationFeaturePermission = {
  ...OperationPermissionsLotBilling,
  ...OperationPermissionsProductManagement,
  ...OperationPermissionsRefData,
  ...OperationPermissionsReport,
  ...OperationPermissionsSettings,
  ...OperationPermissionsUserManagement,
  ...OperationPermissionsTreatmentProtocolsManagement
};
