import { createAction, props } from '@ngrx/store';

import { AppRoute, AppUrlRoute } from '../../models';

const init = createAction('[Router] INIT');

const go = createAction(
  '[Router] Go',
  props<AppRoute>()
);

const goByUrl = createAction(
  '[Router] Go By Url',
  props<AppUrlRoute>()
);

const back = createAction('[Router] BACK');

const forward = createAction('[Router] FORWARD');

export const urlRouterActions = {
  init,
  go,
  goByUrl,
  back,
  forward
};
