import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { SersiSelectListItemViewMode } from '@sersi/angular/formly/core';
import { AbstractSelectType } from '../abstract-select-type/abstract-select-type.component';

@Component({
  selector: 'sersi-multi-select',
  templateUrl: './multi-select-type.component.html',
  styleUrls: ['./multi-select-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MultiSelectTypeComponent extends AbstractSelectType {

  override defaultOptions = {
    props: {
      labelMore: 'more...',
      options: [],
      showClear: true,
      optionsLabel: SersiSelectListItemViewMode.DESCRIPTION_OR_CODE,
      selectedItemLabel: SersiSelectListItemViewMode.DESCRIPTION_OR_CODE,
      items: null,
      items$: null,
      excludeItems$: null,
      virtualScroll: true,
      bindValue: 'id',
      limit: 2,
      resetInvalidSelections: true,
      valueClassName: 'text-break-word',
      optionClassName: 'text-break-word',
      enableSearch: false,
      showAllItems: false
    }
  };

  getTooltipText(): string | null {
    const val = this.formControl.value || [];
    if (!val.length || !this.allItems?.length) return null;

    const selectedCodes = val.map((v: string) => this.allItems.find(i => i.id === v)?.code);
    return selectedCodes.join(', ') || null;
  }
  
}
