export enum SortGroupTemplateAttributes {
  Breed = 1,
  NoBreed = 2,
  BreedType = 3,
  NoBreedType = 4,
  Dentition = 5,
  NoDentition = 6,
  Gender = 7,
  NoGender = 8,
  Age = 9,
  NoAge = 10,
  AgeClass = 11,
  NoAgeClass = 12,
  Temperature = 13,
  NoTemperature = 14,
  Weight = 15,
  NoWeight = 16
}
