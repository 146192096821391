import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation, inject } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';

import { scopeLoader } from '@common/angular/translation';

import { AnimalSelectorAnimalItem } from '../../interfaces';
import { uniqueValues } from '@common/angular/utils';

@Component({
  selector: 'ifhms-animal-selector-table',
  templateUrl: './animal-selector-table.component.html',
  styleUrls: ['./animal-selector-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'animalSelector',
        alias: 'animalSelector',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class AnimalSelectorTableComponent {
  readonly translateScope = 'animalSelector.components.animal-selector-table';

  @HostBinding('class.animal-selector-table') hostClass = true;

  @Input() animals: AnimalSelectorAnimalItem[];
  @Input() selectedItems: AnimalSelectorAnimalItem[];
  @Input() tableHeader: string;
  @Input() animalsSelection: AnimalSelectorAnimalItem[];

  @Output() selectionChange = new EventEmitter<AnimalSelectorAnimalItem[]>();

  lastSelectedRowIndex = -1;

  private readonly cdr = inject(ChangeDetectorRef);

  onHeaderCheckboxSelected(): void {
    this.selectionChange.emit(this.selectedItems);
  }

  onRowSelect(event: any): void {
    const shiftKeyPressed = event.originalEvent.shiftKey;
    const rowIndex = this.animals.findIndex(animal => animal === event.data);
    const hasSelectedAnimals = this.lastSelectedRowIndex !== -1;
    const isMultiSelectAction = shiftKeyPressed && hasSelectedAnimals;

    if (isMultiSelectAction) {
      this.toggleMultiAnimalSelection(rowIndex);
      return;
    }
    this.lastSelectedRowIndex = rowIndex;
    this.selectionChange.emit(this.selectedItems);
  }

  onRowUnselect(): void {
    this.selectionChange.emit(this.selectedItems);
  }

  private toggleMultiAnimalSelection(currentRowIndex: number): void {
    const rangeStart = Math.min(this.lastSelectedRowIndex, currentRowIndex);
    const rangeEnd = Math.max(this.lastSelectedRowIndex, currentRowIndex);
    const selectedAnimals = this.animals.slice(rangeStart, rangeEnd + 1).concat(this.selectedItems)
    const updatedAnimalList = uniqueValues<AnimalSelectorAnimalItem>(selectedAnimals);
    this.lastSelectedRowIndex = currentRowIndex;
    this.selectionChange.emit(updatedAnimalList);
    this.cdr.detectChanges();
  }
}
