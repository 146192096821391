import { Inject, Injectable } from '@angular/core';

import { SersiIconConfig } from '../../interfaces';

@Injectable()
export class SersiIconService {
  path = './assets/icons/';
  constructor(@Inject('sersiIconConfig') private config: SersiIconConfig) {
    if (this.config?.svgPath) {
      this.path = this.config.svgPath; //override default svg path
    }
  }
}
