import { Injectable } from '@angular/core';
import { ProductMappingCreateDto, ProductMappingDto, ProductMappingUpdateDto } from '@ifhms/models/admin';
import { AppContextDto, ContextLevel } from '@ifhms/models/shared';
import { Observable } from 'rxjs';
import { CompanyProductMappingService } from './company-product-mapping.service';
import { OperationProductMappingService } from './operation-product-mapping.service';

@Injectable()
export class ProductMappingService {
  constructor(
    private companyProductMappingService: CompanyProductMappingService,
    private operationProductMappingService: OperationProductMappingService
  ) {
  }

  getList(productId: string, appContext: AppContextDto): Observable<ProductMappingDto[]> {
    switch (appContext.contextLevel) {
      case ContextLevel.Company:
        return this.companyProductMappingService.getList(appContext.levelId, productId);
      case ContextLevel.Operation:
        return this.operationProductMappingService.getList(appContext.levelId, productId);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  create(productMappingCreate: ProductMappingCreateDto, appContext: AppContextDto): Observable<ProductMappingDto[]> {
    switch (appContext.contextLevel) {
      case ContextLevel.Company:
        return this.companyProductMappingService.create(appContext.levelId, productMappingCreate);
      case ContextLevel.Operation:
        return this.operationProductMappingService.create(appContext.levelId, productMappingCreate);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  update(productMappingUpdate: ProductMappingUpdateDto, appContext: AppContextDto): Observable<ProductMappingDto[]> {
    switch (appContext.contextLevel) {
      case ContextLevel.Company:
        return this.companyProductMappingService.update(appContext.levelId, productMappingUpdate);
      case ContextLevel.Operation:
        return this.operationProductMappingService.update(appContext.levelId, productMappingUpdate);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  isSkuUnique(appContext: AppContextDto, vendorId: string, sku: string): Observable<boolean> {
    switch (appContext.contextLevel) {
      case ContextLevel.Company:
        return this.companyProductMappingService.isSkuUnique(appContext.levelId, vendorId, sku);
      case ContextLevel.Operation:
        return this.operationProductMappingService.isSkuUnique(appContext.levelId, vendorId, sku);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  toggleActiveStatus(appContext: AppContextDto, id: string): Observable<ProductMappingDto[]> {
    switch (appContext.contextLevel) {
      case ContextLevel.Company:
        return this.companyProductMappingService.toggleActiveStatus(appContext.levelId, id);
      case ContextLevel.Operation:
        return this.operationProductMappingService.toggleActiveStatus(appContext.levelId, id);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }
}