import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AdgBulkUpdateDto,
  AdgSettingsListDto,
  AdgSettingsListRequestDto,
  AdgSettingsUpdateSelectItemsDto
} from '@ifhms/models/admin';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

@Injectable()
export class FeedlotAdgSettingsManagementService {
  constructor(
    private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string
  ) {
  }

  getAdgSettingsList(feedlotId: string, request: AdgSettingsListRequestDto): Observable<AdgSettingsListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<AdgSettingsListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Settings/AdgSettings`, { params });
  }

  bulkUpdateAdgSettings(feedlotId: string, settingsUpdateDto: AdgBulkUpdateDto): Observable<AdgSettingsUpdateSelectItemsDto> {
    settingsUpdateDto.adgOperationOverrideId = feedlotId;
    return this.http.put<AdgSettingsUpdateSelectItemsDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Settings/AdgSettings/BulkUpdate`, settingsUpdateDto);
  }

  updateSelectedAdgSettings(feedlotId: string, settingsUpdateDto: AdgSettingsUpdateSelectItemsDto): Observable<AdgSettingsUpdateSelectItemsDto> {
    settingsUpdateDto.adgOperationOverrideId = feedlotId;
    return this.http.put<AdgSettingsUpdateSelectItemsDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Settings/AdgSettings`, settingsUpdateDto);
  }

  getAdgWeightRangeSelectList(feedlotId: string): Observable<SersiSelectListItem[]> {
    return this.http.get<SersiSelectListItem[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Settings/AdgSettings/AdgWeightRangeSelectList`);
  }
}