import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ZeroScaleReminderSettingsDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class FeedlotZeroScaleReminderSettingsService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string) {
  }

  get(feedlotId: string): Observable<ZeroScaleReminderSettingsDto> {
    return this.http.get<ZeroScaleReminderSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/WorkOrdersAndEvents/ZeroScaleReminderSettings`
    );
  }

  update(feedlotId: string, settings: ZeroScaleReminderSettingsDto)
    : Observable<ZeroScaleReminderSettingsDto> {
    return this.http.put<ZeroScaleReminderSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/WorkOrdersAndEvents/ZeroScaleReminderSettings`,
      settings
    );
  }
}
