import { AbstractControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SersiSelectListItem, SersiSelectListUpdateEventType } from '@sersi/angular/formly/core';
import { filter, map, NEVER, Observable, pairwise, startWith } from 'rxjs';

export function updateFieldSelectItems(
  field: FormlyFieldConfig | null,
  items$: Observable<SersiSelectListItem[]>,
  value?: string | number | null
): void {
  if (field?.props) {
    field.props['items$'] = items$;
    field.options?.fieldChanges?.next({ 
      field: field,
      type: SersiSelectListUpdateEventType.OPTIONS_LIST_UPDATE,
      value: value ?? field.formControl?.value
    });
  }
}

export function uniqueFieldValueChange<T = string>(field: FormlyFieldConfig, formKey: string): Observable<T> {
  const fieldControl = field.form?.get(formKey);
  if (!fieldControl) return NEVER;

  return uniqueControlValueChange<T>(fieldControl);
}

export function uniqueControlValueChange<T = string>(formControl?: AbstractControl | null): Observable<T> {
  if (!formControl) return NEVER;

  return formControl.valueChanges
    .pipe(
      startWith(formControl?.value),
      pairwise(),
      filter(([prev, current]) => prev !== current),
      map(([, current]) => current)
    )
}
