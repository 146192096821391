import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { LayoutFacade } from './+state/layout.facade';
import { FEATURE_NAME } from './+state';
import { reducer } from './+state/layout.reducer';
import { LayoutService } from './services/layout.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer)
  ],
  providers: [
    LayoutFacade,
    LayoutService
  ]

})
export class IfhmsAdminWebDomainStateLayoutModule {
}
