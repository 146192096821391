import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { RoutesActions } from './routes.actions';
import * as reducer from './routes.reducer';
import { RoutesSelectors } from './routes.selectors';

@Injectable()
export class RoutesFacade {

  loaded$ = this.store.select(RoutesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(RoutesSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}
  
  get(): void {
    this.store.dispatch(RoutesActions.get());
  }
}
