import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ButtonReturnLinkComponent } from './components';
import {
  ClickStopPropagationDirective,
  CursorHoverDirective,
  SersiResizeDirective,
  SersiStickyNavDirective,
  EllipsisDirective,
  ScrollIntoViewDirective
} from './directives';
import { InterpolatePipe } from './pipes';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    RouterModule
  ],
  declarations: [
    SersiResizeDirective,
    CursorHoverDirective,
    ClickStopPropagationDirective,
    SersiStickyNavDirective,
    EllipsisDirective,
    ScrollIntoViewDirective,
    ButtonReturnLinkComponent,
    InterpolatePipe
  ],
  exports: [
    SersiResizeDirective,
    CursorHoverDirective,
    ClickStopPropagationDirective,
    SersiStickyNavDirective,
    EllipsisDirective,
    ScrollIntoViewDirective,
    ButtonReturnLinkComponent,
    InterpolatePipe
  ]
})
export class SersiAngularUiCoreModule {
}
