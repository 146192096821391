import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { AppContextDto } from '@ifhms/models/shared';
import { RiskCategoriesActions } from './risk-categories.actions';
import * as reducer from './risk-categories.reducer';
import { RiskCategoriesSelectors } from './risk-categories.selectors';

@Injectable()
export class RiskCategoriesFacade {

  loaded$ = this.store.select(RiskCategoriesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(RiskCategoriesSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}
  
  get(context?: AppContextDto): void {
    this.store.dispatch(RiskCategoriesActions.get({ context }));
  }
}
