import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-checkbox-group',
  templateUrl: './checkbox-group-type.component.html',
  styleUrls: ['./checkbox-group-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxGroupTypeComponent extends FieldType<FieldTypeConfig> {
  
}
