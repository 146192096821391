import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyProductManagementService {

  constructor(private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string) {
  }

  getSelectList(companyId: string): Observable<SelectListItemWithTranslationDto[]> {
    return this.http.get<SelectListItemWithTranslationDto[]>(`${this.apiUrl}/Company/${companyId}/Products/SelectList`);
  }
}