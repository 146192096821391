import { getLanguageCode } from '@common/angular/translation';
import { UserProfileDto } from '@common/angular/core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from './common-user-profile.reducer';
import { FEATURE_NAME } from './common-user-profile.reducer';

export const selectState = createFeatureSelector<reducer.State>(FEATURE_NAME);
const selectUserProfile = createSelector(selectState, reducer.getUserProfile);
const selectUserId = createSelector(selectUserProfile, reducer.getUserId);
const selectLanguage = createSelector(selectUserProfile, (profile: UserProfileDto | null) => profile?.language || 'en');
const selectLanguageCode = createSelector(selectLanguage, lang => getLanguageCode(lang));
const selectLoading = createSelector(selectState, reducer.getLoading);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

export const CommonUserProfileSelectors = {
  selectState,
  selectUserProfile,
  selectUserId,
  selectLoading,
  selectLoaded,
  selectLanguage,
  selectLanguageCode
};