import { Injectable } from '@angular/core';
import { RouterFacade, RouterStateData } from '@common/angular/url-router';
import { AppContextDto, ContextLevel } from '@ifhms/models/shared';
import { filter, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContextService {

  constructor(private routerFacade: RouterFacade) {}

  get appContext$(): Observable<AppContextDto> {
    return this.routerFacade.selectRoutesState$.pipe(
      filter(x => !!x && x.routeData['contextLevel']),
      map((routeState) => this.transformToAppContext(routeState))
    );
  }

  private transformToAppContext(routeState: RouterStateData): AppContextDto {

    const routeData = routeState?.routeData;

    let appContext: AppContextDto | null = null;

    if (routeData) {
      switch (routeData['contextLevel'] as ContextLevel) {
        case ContextLevel.Enterprise:
          appContext = {
            levelId: '',
            contextLevel: ContextLevel.Enterprise
          };
          break;

        case ContextLevel.Company:
          appContext = {
            levelId: routeData['company']?.id ?? '',
            contextLevel: ContextLevel.Company
          };
          break;

        case ContextLevel.Group:
          appContext = {
            levelId: routeData['group'].id,
            contextLevel: ContextLevel.Group
          };
          break;

        case ContextLevel.Operation:
          appContext = {
            levelId: routeData['operation']?.id,
            contextLevel: ContextLevel.Operation
          };
          break;

        case ContextLevel.Feedlot:
          appContext = {
            levelId: routeData['feedlotData'].id,
            contextLevel: ContextLevel.Feedlot
          };
          break;

        default:
          throw 'Can\'t resolve app context level';
      }
    }

    return appContext as AppContextDto;
  }
}
