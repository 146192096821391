import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import { LotSubgroupsActions } from './lot-subgroups.actions';
import * as reducer from './lot-subgroups.reducer';
import { LotSubGroupsSelectors } from './lot-subgroups.selectors';

@Injectable()
export class LotSubGroupsFacade {

  items$: Observable<SersiSelectListItem[]> = this.store.select(LotSubGroupsSelectors.selectItems);

  constructor(private store: Store<reducer.State>) {
  }
  
  get(levelId?: string): void {
    this.store.dispatch(LotSubgroupsActions.get( { levelId }));
  }
}
