<span class="p-fluid">
    <div class="p-inputgroup">
    <p-inputNumber
            mode='currency'
            [attr.data-testid]="'currency'"
            [attr.data-ele]="key"
            [inputStyleClass]="props['inputClassNames']"
            [formControl]="formControl"
            [formlyAttributes]="field"
            [placeholder]="props['placeholder'] || ''"
            [currency]="props['prefix']"
            [locale]="props['locale']"
            [useGrouping]="props['useGrouping']"
            [minFractionDigits]="props['minFractionDigits']"
            [maxFractionDigits]="props['maxFractionDigits']"
            [min]="props['allowNegativeNumbers'] ? props['min']! : 0"
            [maxlength]="maxInputLength"
            [readonly]="!!props['readonly']"
            (onBlur)="props.blur && props.blur(field, $event)"
    >
    </p-inputNumber>
    <span *ngIf="props['suffixDisplay']" class="p-inputgroup-addon">  {{ props['suffixAddOn']}}</span>
</div>

</span>
