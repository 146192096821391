import { Injectable, inject } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthFacade } from '../+state';

@Injectable()
export class AuthErrHttpInterceptor implements HttpInterceptor {

  private readonly authErrCodes = [ 401, 403 ];
  private readonly authFacade = inject(AuthFacade);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.authErrCodes.includes(error.status)) {
          this.authFacade.login(location.pathname);
        }
        return throwError(() => error);
      })
    );

  }
}
