import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-text-readonly',
  templateUrl: './text-readonly-type.component.html',
  styleUrls: ['./text-readonly-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextReadonlyTypeComponent extends FieldType<FieldTypeConfig> {}
