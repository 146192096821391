import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../permissions.reducer';

import * as PermissionsReducer from './enterprise-permissions.reducer';

const selectState = createSelector(
  selectFeatureState,
  (state: State) => state.enterprisePermissions
);

const selectEnterprisePermissions = createSelector(selectState, PermissionsReducer.getEnterprisePermissions);
const selectLoading = createSelector(selectState, PermissionsReducer.getLoading);
const selectLoaded = createSelector(selectState, PermissionsReducer.getLoaded);

export const PermissionsSelector = {
  selectState,
  selectLoading,
  selectLoaded,
  selectEnterprisePermissions
};
