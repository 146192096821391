import { AppContextDto, SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Reference - Sort-Types] - ';

const get = createAction(`${NAMESPACE} Get`,
  props<{ context?: AppContextDto }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ items: SelectListItemWithTranslationDto[] }>()
);

const error = createAction(`${NAMESPACE} error`);

export const SortTypesActions = {
  error,
  get,
  getSuccess
};
