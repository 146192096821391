import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'sersi-checkbox-table-wrapper',
  templateUrl: './checkbox-table-wrapper.component.html',
  styleUrls: ['./checkbox-table-wrapper.component.scss']
})
export class CheckboxTableWrapperComponent extends FieldWrapper {
  constructor() {
    super();
  }
}
