import { inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { BuildInfo } from '../interfaces';
import { APP_CONFIG } from '../tokens';
import { BuildConfigHelper } from '../utils';

@Injectable({ providedIn: 'root' })
export class BuildConfigService {

  private appConfig = inject(APP_CONFIG);
  private buildConfigHelper: BuildConfigHelper;

  constructor() {
    // Initialize the helper with appConfig
    this.buildConfigHelper = BuildConfigHelper.getInstance(this.appConfig);
  }

  getBuildVersion(): Observable<string | null> {
    return from(this.buildConfigHelper.getBuildVersion());
  }

  getBuildInfo(): Observable<BuildInfo> {
    return from(this.buildConfigHelper.getBuildInfo());
  }

}
