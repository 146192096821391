import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME, reducer } from './+state';
import { CompaniesEffects } from './+state/companies/companies.effects';
import { CompaniesFacade } from './+state/companies/companies.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([CompaniesEffects])
  ],
  providers: [
    CompaniesEffects,
    CompaniesFacade
  ]
})
export class IfhmsAdminDomainStateCompaniesModule {
}
