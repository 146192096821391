import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuType } from '../models/menu-type.enum';
import { LayoutSelectors } from './layout.selectors';
import { LayoutActions } from './layout.actions';
import { Observable } from 'rxjs';

@Injectable()
export class LayoutFacade {

  menuType$: Observable<MenuType>;

  constructor(private readonly store: Store) {
    this.menuType$ = this.store.select(LayoutSelectors.selectMenuType);
  }

  setMenuType(menuType: MenuType): void {
    this.store.dispatch(LayoutActions.setMenu({ menuType }));
  }
}
