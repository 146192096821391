import { AppConfig } from '@ifhms/admin/web/core/config';

import { APP_CONFIG_DEV,
  APP_CONFIG_FMS_DEV,
  APP_CONFIG_FMS_UAT,
  APP_CONFIG_FMS_QA,
  APP_CONFIG_FMS_STAGING,
  APP_CONFIG_FMS_PRODUCTION
} from '../configs';
import { environment } from '../environments/environment';

export function getAppConfig(): AppConfig {
  const hostname = window.location.hostname;
  let appConfig: AppConfig;

  switch (hostname) {
    case 'localhost':
    case 'host.docker.internal':
      appConfig = APP_CONFIG_DEV;
      break;
    case 'admin.dev.fms.feedlothealth.com':
      appConfig = APP_CONFIG_FMS_DEV;
      break;
    case 'admin.uat.fms.feedlothealth.com':
      appConfig = APP_CONFIG_FMS_UAT;
      break;
    case 'admin.qa.fms.feedlothealth.com':
      appConfig = APP_CONFIG_FMS_QA;
      break;
    case 'admin.staging.fms.feedlothealth.com':
      appConfig = APP_CONFIG_FMS_STAGING;
      break;
    case 'admin.frm.feedlothealth.com':
      appConfig = APP_CONFIG_FMS_PRODUCTION;
      break;
    default:
      appConfig = APP_CONFIG_DEV;
      break;
  }

  return {
    ...appConfig,
    ...environment
  }

}
