import { AfterViewInit, Directive, ElementRef, Inject, Input, NgZone, Renderer2 } from '@angular/core';
import { WINDOW } from '@common/angular/config';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, asyncScheduler, delay, of, throttleTime } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[sersiStickyNav]'
})
export class SersiStickyNavDirective implements AfterViewInit {

  @Input() topOffset = 0;

  private navElement: HTMLElement;
  private navHeight: number;
  private prevScrollPos = 0;
  private overflowVisibilityTrigger: number;
  private windowScroll$: Subject<void> = new Subject();

  private get currentScrollPos(): number {
    return this.window.scrollY;
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone,
    @Inject(WINDOW) private window: Window
  ) {
    this.navElement = this.elementRef.nativeElement;
    this.onWindowScroll = this.onWindowScroll.bind(this);
  }

  ngAfterViewInit(): void {
    // delay for screen transition
    of(null).pipe(delay(500)).subscribe(() => this.initStickyNav());
  }

  private initStickyNav(): void {
    const clientRect = this.navElement.getBoundingClientRect();
    const elTopPosition = clientRect.top + this.currentScrollPos
    this.navHeight = Math.max(clientRect.height, this.navElement.offsetHeight);
    this.overflowVisibilityTrigger = elTopPosition - this.topOffset;
    this.attachScrollEvent();
  }

  private attachScrollEvent(): void {
    this.ngZone.runOutsideAngular(() => {
      this.window.addEventListener('scroll', this.onWindowScroll, true);
    });
    this.windowScroll$ 
      .pipe(
        throttleTime(50, asyncScheduler, { trailing: true, leading: true }),
        untilDestroyed(this)
      )
      .subscribe(() => this.handleScrollEvent());
  }

  private updateElementStyling(): void {
    const elementBottomPos = this.navHeight + this.topOffset;

    if (this.currentScrollPos < this.overflowVisibilityTrigger) {
      this.renderer.removeClass(document.body, 'sticky-nav-visible')
      this.renderer.removeStyle(this.navElement, 'position');
      this.renderer.removeStyle(this.navElement, 'top');
      return;
    }
    if (this.currentScrollPos < this.prevScrollPos && this.currentScrollPos > elementBottomPos) {
      this.renderer.addClass(document.body, 'sticky-nav-visible')
      this.renderer.setStyle(this.navElement, 'position', 'fixed');
      this.setTopPosition(this.topOffset)
    }
    if(this.currentScrollPos > this.prevScrollPos) {
      this.renderer.removeClass(document.body, 'sticky-nav-visible')
    }
  }

  private updateTopPosition(): void {
    const topPosition = this.currentScrollPos > this.prevScrollPos ? -this.navHeight : this.topOffset;
    this.setTopPosition(topPosition);
  }

  private setTopPosition(position: number): void {
    this.renderer.setStyle(this.navElement, 'top', `${position}px`);
  }

  private onWindowScroll(): void {
    this.windowScroll$.next();
  }

  private handleScrollEvent(): void {
    this.updateElementStyling();
    this.updateTopPosition();
    this.prevScrollPos = this.currentScrollPos;
  }

}
