import { createReducer, on } from '@ngrx/store';
import { CompanyDto } from '@ifhms/models/admin';
import { CompaniesActions } from './companies.actions';

export const FEATURE_NAME = 'Companies';

export interface State {
  companies: CompanyDto[];
  creating: boolean;
  created: boolean;
  loading: boolean;
  loaded: boolean;
  isActive: boolean;
}

const initialState: State = {
  companies: [],
  creating: false,
  created: false,
  loading: false,
  loaded: false,
  isActive: false
};

export const reducer = createReducer(
  initialState,

  on(CompaniesActions.create, (state) => ({
    ...state,
    creating: true,
    created: false,
    loading: false,
    loaded: false
  })),

  on(CompaniesActions.createSuccess, (state, action) => ({
    ...state,
    companies: action.companies,
    creating: false,
    created: true,
    loading: false,
    loaded: false,
    isActive: true
  })),

  on(CompaniesActions.get, (state) => ({
    ...state,
    creating: false,
    created: false,
    loading: true,
    loaded: false
  })),

  on(CompaniesActions.getSuccess, (state, action) => ({
    ...state,
    companies: action.companies,
    creating: false,
    created: false,
    loading: false,
    loaded: true,
    isActive: true
  })),

  on(CompaniesActions.getSuccessCached, (state) => ({
    ...state,
    loading: false,
    loaded: true
  })),

  on(CompaniesActions.error, () => initialState),

  on(CompaniesActions.reset, () => initialState)
);

export const getCompanies = (state: State): CompanyDto[] | null => state.companies;

export const getCreating = (state: State): boolean => state.creating;

export const getCreated = (state: State): boolean => state.created;

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;
