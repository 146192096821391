import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as reducer from './re-handling.reducer';
import { Observable } from 'rxjs';
import { FeedlotReHandlingSettingsDto } from '@ifhms/models/admin';
import { ReHandlingSettingsActions } from './re-handling.actions';
import { ReHandlingSettingsSelectors } from './re-handling.selectors';

@Injectable()
export class ReHandlingSettingsFacade {

  loading$: Observable<boolean> = this.store.select(ReHandlingSettingsSelectors.selectLoading);
  loaded$: Observable<boolean> = this.store.select(ReHandlingSettingsSelectors.selectLoaded);
  updating$: Observable<boolean> = this.store.select(ReHandlingSettingsSelectors.selectUpdating);
  updated$: Observable<boolean> = this.store.select(ReHandlingSettingsSelectors.selectUpdated);
  reHandlingSettings$: Observable<FeedlotReHandlingSettingsDto | null> = this.store.select(ReHandlingSettingsSelectors.selectReHandlingSettings);

  constructor(private store: Store<reducer.State>) { }

  getReHandlingSettings(): void {
    this.store.dispatch(ReHandlingSettingsActions.getReHandlingSettings());
  }

  updateReHandlingSettings(settings: FeedlotReHandlingSettingsDto): void {
    this.store.dispatch(ReHandlingSettingsActions.updateReHandlingSettings({ settings }));
  }

  resetReHandlingSettings(): void {
    this.store.dispatch(ReHandlingSettingsActions.resetReHandlingSettings());
  }
}
