export enum ProductReceiptSortBy {
  InvoiceNumberAscending = 0,
  InvoiceNumberDescending = 1,
  FileNameAscending = 2,
  FileNameDescending = 3,
  DescriptionAscending = 4,
  DescriptionDescending = 5,
  UploadedDateAscending = 6,
  UploadedDateDescending = 7,
  PostedDateAscending = 8,
  PostedDateDescending = 9
}