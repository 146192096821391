import { InlineLoader } from '@jsverse/transloco/lib/types';

export const availableLangs = ['en', 'en-US', 'es-MX', 'ru'];

export const scopeLoader = (importer: any, root = 'i18n'): InlineLoader => {
  return availableLangs.reduce((acc: any, lang: string) => {
    acc[lang] = (): void => importer(lang, root);
    return acc;
  }, {});
};
