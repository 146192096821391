import { FormlyFieldConfig } from '@ngx-formly/core';
import { isNumber } from 'lodash-es';
import { map, Observable, of, switchMap } from 'rxjs';

import { TranslateService } from '@common/angular/translation';

import { MAX_NUMBER_INPUT, SersiFormlyValidationObj } from '../interfaces';

export function sersiFormlyValidationConfig(translateService: TranslateService): SersiFormlyValidationObj {

  return {
    validationMessages: [
      {
        name: 'required',
        message(error: any, field: FormlyFieldConfig): Observable<string> {
          const label$ = getValidationLabel$(field);
          return label$.pipe(
            switchMap((label: string): Observable<string> => {
              return label
                ? translateService.getGlobalTranslation$('formly.errors.required-suffix', { label })
                : translateService.getGlobalTranslation$('formly.errors.required');
            })
          )
        }
      },
      {
        name: 'max',
        message(error: any, field: FormlyFieldConfig): Observable<string> {
          const label$ = getValidationLabel$(field);
          const max = isNumber(field?.props?.max) ? field?.props?.max : MAX_NUMBER_INPUT;
          return label$.pipe(
            switchMap((label: string): Observable<string> => {
              return label
                ? translateService.getGlobalTranslation$('formly.errors.max', { label, max })
                : translateService.getGlobalTranslation$('formly.errors.max-no-label',  { max });
            })
          )
        }
      },
      {
        name: 'min',
        message(error: any, field: FormlyFieldConfig): Observable<string> {
          const label$ = getValidationLabel$(field);
          const min = isNumber(field?.props?.min) ? field?.props?.min : -MAX_NUMBER_INPUT;
          return label$.pipe(
            switchMap((label: string): Observable<string> => {
              return label
                ? translateService.getGlobalTranslation$('formly.errors.min', { label, min })
                : translateService.getGlobalTranslation$('formly.errors.min-no-label',  { min });
            })
          )
        }
      }
    ]
  };
}

function getValidationLabel$(field: FormlyFieldConfig): Observable<string> {
  const label$ = field?.props?.['label$'] || of(field?.props?.label);
  return label$.pipe(map((x: string) => x?.trim?.()))
}