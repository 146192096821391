import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProductTransactionTableRequestDto, ProductTransactionTableResultDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class ProductTransactionService {

  constructor(private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string) {
  }

  getTable(operationId: string, productId: string | null, request: ProductTransactionTableRequestDto): Observable<ProductTransactionTableResultDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        productId: productId ?? '',
        ...request
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<ProductTransactionTableResultDto>(`${this.apiUrl}/Operation/${operationId}/ProductTransaction/Table`, options);
  }
}