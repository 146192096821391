import { createReducer, on } from '@ngrx/store';
import { FeedlotPricingSettingsDto } from '@ifhms/models/admin';
import { PricingSettingsActions } from './pricing.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  updating: boolean;
  updated: boolean;
  pricingSettings: FeedlotPricingSettingsDto | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  pricingSettings: null,
  updating: false,
  updated: false
};

export const reducer = createReducer(
  initialState,

  on(PricingSettingsActions.getPricingSettings,
    (state) => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),

  on(PricingSettingsActions.getPricingSettingsSuccess,
    (state, action) => ({
      ...state,
      pricingSettings: action.settings,
      loading: false,
      loaded: true
    })
  ),

  on(
    PricingSettingsActions.updatePricingSettings,
    state => ({
      ...state,
      updating: true
    })
  ),

  on(PricingSettingsActions.updatePricingSettingsSuccess,
    (state, action) => (
      {
        ...state,
        pricingSettings: action.settings,
        updating: false
      })
  ),

  on(PricingSettingsActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false,
    updating: false,
    updated: false
  })),

  on(PricingSettingsActions.resetPricingSettings,
    (state) => ({
      ...state,
      pricingSettings: null
    })
  ),

  on(
    PricingSettingsActions.updatePricingSettings,
    state => ({
      ...state,
      updating: true
    })
  ),

  on(PricingSettingsActions.applyPricingSettingsSuccess,
    (state, action) => (
      {
        ...state,
        pricingSettings: action.settings,
        updating: false
      })
  )
);

export const getPricingSettings = (state: State): FeedlotPricingSettingsDto | null => state.pricingSettings;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getUpdating = (state: State): boolean => state.updated;
export const getUpdated = (state: State): boolean => state.updating;
