import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CompanyUserInviteDto, CompanyUserListItemDto, CompanyUserUpdateDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyUserService {
  constructor(@Inject('apiUrl') private apiUrl: string, private http: HttpClient) {
  }

  getList(companyId: string): Observable<CompanyUserListItemDto[]> {
    return this.http.get<CompanyUserListItemDto[]>(`${this.apiUrl}/Company/${companyId}/Users/List`);
  }

  invite(companyId: string, companyUserUpdateDto: CompanyUserInviteDto): Observable<CompanyUserListItemDto[]> {
    return this.http.put<CompanyUserListItemDto[]>(`${this.apiUrl}/Company/${companyId}/Users/Invite`, companyUserUpdateDto);
  }

  update(companyId: string, companyUserUpdateDto: CompanyUserUpdateDto): Observable<CompanyUserListItemDto[]> {
    return this.http.put<CompanyUserListItemDto[]>(`${this.apiUrl}/Company/${companyId}/Users`, companyUserUpdateDto);
  }

  revoke(companyId: string, companyUserId: string): Observable<CompanyUserListItemDto[]> {
    return this.http.put<CompanyUserListItemDto[]>(`${this.apiUrl}/Company/${companyId}/Users/${companyUserId}/Revoke`, {});
  }

  isEmailValid(companyId: string, email: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { email }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Company/${companyId}/Users/IsEmailUnique`, options);
  }
}
