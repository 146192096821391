import { Injectable } from '@angular/core';
import { AppContextDto, ContextLevel } from '@ifhms/models/shared';
import {
  TreatmentProtocolDefinitionCreateDto,
  TreatmentProtocolDefinitionDto,
  TreatmentProtocolDefinitionUpdateDto,
  TreatmentProtocolListRequestDto,
  TreatmentProtocolListResultDto
} from '@ifhms/models/admin';
import { Observable } from 'rxjs';
import { EnterpriseTreatmentProtocolManagementService } from './enterprise-treatment-protocol-management.service';
import { OperationTreatmentProtocolManagementService } from './operation-treatment-protocol-management.service';
import { SelectListItemDto } from '@common/angular/core/models';

@Injectable()
export class TreatmentProtocolManagementService {

  constructor(private enterpriseTreatmentProtocolManagementService: EnterpriseTreatmentProtocolManagementService,
    private operationTreatmentProtocolManagementService: OperationTreatmentProtocolManagementService) {

  }

  getTable(appContext: AppContextDto, request: TreatmentProtocolListRequestDto): Observable<TreatmentProtocolListResultDto> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseTreatmentProtocolManagementService.getTable(request);
      case ContextLevel.Operation:
        return this.operationTreatmentProtocolManagementService.getTable(appContext.levelId, request);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  getDefinition(appContext: AppContextDto, txGroupId: string): Observable<TreatmentProtocolDefinitionDto> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseTreatmentProtocolManagementService.getDefinition(txGroupId);
      case ContextLevel.Operation:
        return this.operationTreatmentProtocolManagementService.getDefinition(appContext.levelId, txGroupId);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  getSelectList(appContext: AppContextDto): Observable<SelectListItemDto[]> {
    switch (appContext.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseTreatmentProtocolManagementService.getSelectList(appContext.levelId);
      case ContextLevel.Operation:
        return this.operationTreatmentProtocolManagementService.getSelectList(appContext.levelId);
      default:
        throw new Error(`Unknown context level: ${appContext.contextLevel}`);
    }
  }

  updateDefinition(context: AppContextDto, updateDto: TreatmentProtocolDefinitionUpdateDto): Observable<TreatmentProtocolDefinitionDto> {
    switch (context.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseTreatmentProtocolManagementService.updateDefinition(updateDto);
      case ContextLevel.Operation:
        return this.operationTreatmentProtocolManagementService.updateDefinition(context.levelId, updateDto);
      default:
        throw new Error(`Unknown context level: ${context.contextLevel}`);
    }
  }

  create(context: AppContextDto, updateDto: TreatmentProtocolDefinitionCreateDto): Observable<TreatmentProtocolDefinitionDto> {
    switch (context.contextLevel) {
      case ContextLevel.Enterprise:
        return this.enterpriseTreatmentProtocolManagementService.create(updateDto);
      case ContextLevel.Operation:
        return this.operationTreatmentProtocolManagementService.create(context.levelId, updateDto);
      default:
        throw new Error(`Unknown context level: ${context.contextLevel}`);
    }
  }
}