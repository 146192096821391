import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as AuthReducer from './auth.reducer';
import { FEATURE_NAME } from './auth.reducer';

export const selectState = createFeatureSelector<AuthReducer.AuthState>(FEATURE_NAME);

const selectIsAuthenticated = createSelector(selectState, AuthReducer.getIsAuthenticated);

const selectIsAuthInProgress = createSelector(selectState, AuthReducer.getIsAuthInProgress);

const selectIsAuthComplete = createSelector(selectState, AuthReducer.getIsAuthComplete);

const selectUserData = createSelector(selectState, AuthReducer.getUser);
const selectUserRoles = createSelector(selectState, AuthReducer.getUserRoles);

const selectUserId = createSelector(selectUserData, AuthReducer.getUserId);

export const authQuery = {
  selectState,
  selectIsAuthenticated,
  selectIsAuthInProgress,
  selectIsAuthComplete,
  selectUserData,
  selectUserRoles,
  selectUserId
};
