import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-text-area',
  templateUrl: './text-area-type.component.html',
  styleUrls: ['./text-area-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextAreaTypeComponent extends FieldType<FieldTypeConfig> {
}
