import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

export function triggerFormlyFormValidation(control: AbstractControl): void {
  if (control instanceof FormGroup) {
    const group = control as FormGroup;

    for (const field in group.controls) {
      const c = group.controls[field];

      triggerFormlyFormValidation(c);
    }
  } else if (control instanceof FormArray) {
    const group = control as FormArray;

    for (const field in group.controls) {
      const c = group.controls[field];

      triggerFormlyFormValidation(c);
    }
  }

  control.markAsTouched();
  control.markAsDirty();
}

export function scrollToError(scrollDelay = 0): void {
  setTimeout(() => {
    const errorElement = document.querySelector('form .ng-invalid');
    errorElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, scrollDelay);
}

export function resetFormState(form?: FormGroup | FormArray): void {
  form?.markAsPristine();
  form?.markAsUntouched();
}
