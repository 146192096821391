import { AuthServiceConfig } from './auth-config.types';

export const AUTH_DEFAULT_CONFIG: AuthServiceConfig  = {
  clientId: '',
  baseAuthRoutePath: '/auth',
  loginRoutePath: '/auth/login',
  baseAppRoutePath: '/',
  storageReturnUrlKey: 'ng_auth_return_url',
  storageLoginRetriesCountKey: 'ng_auth_retries_count',
  sendAccessToken: true,
  useLocaleStorage: true,
  sessionRefreshEnabled: true,
  sessionChecksEnabled: true,
  forceUserLogin: false,
  silentRenew: true,
  useRefreshToken: true,
  silentRefreshRedirectUri: `${window.location.origin}/assets/auth/silent-refresh.html`
}

