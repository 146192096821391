import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BannerType } from '@common/angular/interfaces';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Message } from 'primeng/api';

@Component({
  selector: 'sersi-banner-type',
  templateUrl: './banner-type.component.html',
  styleUrls: ['./banner-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {

  message: Message[] = [];

  override defaultOptions = {
    props: {
      inline: false,
      severity: BannerType.INFO,
      title: 'Info',
      message: 'I am example text',
      closable: false
    }
  }

  ngOnInit(): void {
    if (!this.props['inline']) {
      this.message.push({
        severity: this.props['severity'],
        summary: this.props['title'],
        detail: this.props['message'],
        closable: false
      });
    }
  }

}
