<p class="mt-1 flex flex-row">
  <i *ngIf="dialogData.icon" class="material-icons-outlined mr-1">{{ dialogData.icon }}</i>
  {{ dialogData.message }}
</p>
<div class="container-footer-buttons">
  <button
    pButton
    iconPos="left"
    class="p-button-secondary"
    data-ele="cancel-btn"
    [ngClass]="dialogData.rejectButtonStyleClass"
    [icon]="dialogData.rejectIcon || ''"
    [label]="dialogData.rejectLabel!"
    [text]="true"
    [rounded]="true"
    (click)="onCancel()"
  ></button>
  <button
    pButton
    iconPos="left"
    class="p-button-secondary"
    data-ele="confirm-btn"
    [ngClass]="dialogData.acceptButtonStyleClass"
    [rounded]="true"
    severity="secondary"
    (click)="onConfirm()"
  >
    <i class="material-icons-outlined mr-1">
      {{ dialogData.acceptIcon || 'check' }}</i
    >
    {{ dialogData.acceptLabel! }}
  </button>
</div>
