import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { TagLocationsActions } from './tag-locations.actions';
import * as reducer from './tag-locations.reducer';
import { TagLocationsSelectors } from './tag-locations.selectors';

@Injectable()
export class TagLocationsFacade {

  loaded$ = this.store.select(TagLocationsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(TagLocationsSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}
  
  get(): void {
    this.store.dispatch(TagLocationsActions.get());
  }
}
