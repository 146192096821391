import { FeedlotDefaultValuesSettingsDto } from '@ifhms/models/admin';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Default Values] - ';

// ===== Default Values Settings ======
const getDefaultValuesSettings = createAction(
  `${NAMESPACE} Get Default Values Settings`
);

const getDefaultValuesSettingsSuccess = createAction(
  `${NAMESPACE} Get Default Values Settings Success`,
  props<{ settings: FeedlotDefaultValuesSettingsDto }>()
);

const updateDefaultValuesSettings = createAction(
  `${NAMESPACE} Update Default Values Settings`,
  props<{ settings: FeedlotDefaultValuesSettingsDto }>()
);

const updateDefaultValuesSettingsSuccess = createAction(
  `${NAMESPACE} Update Default Values Settings Success`,
  props<{ settings: FeedlotDefaultValuesSettingsDto }>()
);

const resetDefaultValuesSettings = createAction(
  `${NAMESPACE} Reset Default Values Settings`
);

const error = createAction(`${NAMESPACE} error`);

export const DefaultValuesSettingsActions = {
  error,
  getDefaultValuesSettings,
  getDefaultValuesSettingsSuccess,
  updateDefaultValuesSettings,
  updateDefaultValuesSettingsSuccess,
  resetDefaultValuesSettings
};
