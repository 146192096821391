import { Injectable } from '@angular/core';

import { AppConfig } from '@ifhms/admin/web/core/config';

import { getAppConfig } from '../utils';

@Injectable()
export class AppConfigService {

  getConfig(): AppConfig {
    return getAppConfig();
  }

  getFeedlotApiUrl(): string {
    const appConfig = this.getConfig();
    return appConfig.adminApiUrl || 'http://host.docker.internal:5227/api';
  }
}
