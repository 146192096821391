import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { MarketingPlansActions } from './marketing-plans.actions';
import * as reducer from './marketing-plans.reducer';
import { MarketingPlansSelectors } from './marketing-plans.selectors';

@Injectable()
export class MarketingPlansFacade {

  loaded$ = this.store.select(MarketingPlansSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(MarketingPlansSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}
  
  get(): void {
    this.store.dispatch(MarketingPlansActions.get());
  }
}
