import { Injectable } from '@angular/core';
import { FeedlotPermissionsDto } from '@ifhms/models/shared';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { OperationPermissionsActions } from './operation-permissions.actions';

import * as reducer from './operation-permissions.reducer';
import { PermissionsSelector } from './operation-permissions.selectors';

@Injectable({ providedIn: 'root' })
export class OperationPermissionsFacade {

  operationPermissions$: Observable<FeedlotPermissionsDto | null> = this.store.select(PermissionsSelector.selectOperationPermissions);
  loading: Observable<boolean> = this.store.select(PermissionsSelector.selectLoading);
  loaded: Observable<boolean> = this.store.select(PermissionsSelector.selectLoaded);

  constructor(private store: Store<reducer.State>) {
  }

  getOperationPermissions(): void {
    this.store.dispatch(OperationPermissionsActions.getOperationPermissions());
  }

  reset(): void {
    this.store.dispatch(OperationPermissionsActions.reset());
  }
}
