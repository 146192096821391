import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'sersi-default-form-field-wrapper',
  templateUrl: './default-form-field-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultFormFieldWrapperComponent extends FieldWrapper {

}
