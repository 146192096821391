import { Injectable } from '@angular/core';
import { CompanyPermissionsService } from '@ifhms/admin/web/domain/permissions';
import { CompanyFacade } from '@ifhms/admin/web/domain/state/company';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, withLatestFrom } from 'rxjs';
import { catchError, exhaustMap, map, timeout } from 'rxjs/operators';
import { CompanyPermissionsActions } from './company-permissions.actions';

@Injectable()
export class CompanyPermissionsEffects {

  private readonly loginTimeout = 10 * 1000; // 10 seconds

  constructor(
    private readonly actions$: Actions,
    private companyFacade: CompanyFacade,
    private companyPermissionsService: CompanyPermissionsService
  ) {
  }

  getCompanyPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyPermissionsActions.getCompanyPermissions),
      withLatestFrom(this.companyFacade.companyId$),
      exhaustMap(([, companyId]) =>
        this.companyPermissionsService.getCompanyPermissionsForUser(companyId).pipe(
          map((companyPermissions) => CompanyPermissionsActions.getCompanyPermissionsSuccess({ companyPermissions })),
          timeout(this.loginTimeout),
          catchError(err => of(CompanyPermissionsActions.error(err)))
        )
      )
    )
  );

}
