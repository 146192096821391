import { Inject, Injectable } from '@angular/core';
import { filter, map, Observable, tap } from 'rxjs';
import { Route, UrlSegment } from '@angular/router';

import { AuthFacade, AuthState } from '../+state';
import { AUTH_CONFIG, AuthServiceConfig } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class AuthSecuredRouteGuard {

  constructor(
    private readonly authFacade: AuthFacade,
    @Inject(AUTH_CONFIG) private readonly authConfig: AuthServiceConfig
  ) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.authFacade.state$.pipe(
      filter(({ isAuthInProgress }) => !isAuthInProgress),
      tap((authState: AuthState) => {
        if (!authState.isAuthComplete) {
          const redirectUrl = segments.join('/') || this.authConfig.baseAppRoutePath;
          this.authFacade.login(redirectUrl);
        }
      }),
      filter(({ isAuthComplete }) => isAuthComplete),
      map(({ isAuthenticated }) => isAuthenticated)
    )
  }
}
