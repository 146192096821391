import { UserProfileDto } from '@common/angular/core/models';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[USER_PROFILE]';

const get = createAction(
  `${NAMESPACE} Get`,
  props<{ userId: string }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ userProfile: UserProfileDto }>()
);
const updateLangSettings = createAction(
  `${NAMESPACE} Update Lang Settings`,
  props<{ langCode: string }>()
);

const updateLangSettingsSuccess = createAction(
  `${NAMESPACE} Update Lang Settings Success`,
  props<{ langCode: string }>()
);

const err = createAction(
  `${NAMESPACE} User Profile Err`,
  props<{ errMsg: string }>()
);

export const CommonUserProfileActions = {
  get,
  getSuccess,
  updateLangSettings,
  updateLangSettingsSuccess,
  err
};