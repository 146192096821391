import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ListInputHelper } from '@sersi/angular/formly/core';
import { MenuItem } from 'primeng/api';
import { map, Observable, of } from 'rxjs';

@Component({
  selector: 'sersi-button-menu',
  templateUrl: './button-menu-type.component.html',
  styleUrls: ['./button-menu-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonMenuTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {

  @Input() styleClass = '';
  @Input() buttonAttrDataEle: string;

  items$: Observable<MenuItem[]> = of([]);

  override defaultOptions = {
    props: {
      disabled: false,
      buttonIcon: 'pi pi-bars',
      menuItems: null,
      items: null,
      items$: null,
      selectedItems$: null,
      showInactive: false
    }
  };

  ngOnInit(): void {
    // Menu items type as inputs
    if (this.props['menuItems'] || this.props['menuItems$']) {
      this.items$ = this.props['menuItems'] ? of(this.props['menuItems']) : this.props['menuItems$'];
    }
    // SersiSelectListItems as inputs
    else {
      const selectedItems$: Observable<string[]> = this.props['selectedItems$'] || of([]);

      const items$ = ListInputHelper.getSelectListItemsAsync(this.formControl, selectedItems$, true, this.props['items'], this.props['items$']);

      this.items$ = items$.pipe(
        map((list) => {
          return list.filter(x => this.props['showInactive'] || x.isActive)
            .map(y => {

              return <MenuItem>{
                key: y.id,
                label: y.description ?? y.code,
                command: this.props['command']
              }
            })
        })
      )
    }
  }

}

