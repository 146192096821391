import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProductPricingCreateDto, ProductPricingDto, ProductPricingUpdateDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ProductPricingService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  getList(operationId: string, productId: string): Observable<ProductPricingDto[]> {
    return this.http.get<ProductPricingDto[]>(`${this.apiUrl}/Operation/${operationId}/ProductPricing/List/${productId}`);
  }

  get(operationId: string, productPricingId: string): Observable<ProductPricingDto[]> {
    return this.http.get<ProductPricingDto[]>(`${this.apiUrl}/Operation/${operationId}/ProductPricing/${productPricingId}`);
  }

  create(operationId: string, productPricingCreate: ProductPricingCreateDto): Observable<ProductPricingDto[]> {
    return this.http.post<ProductPricingDto[]>(`${this.apiUrl}/Operation/${operationId}/ProductPricing`, productPricingCreate);
  }

  update(operationId: string, productPricingUpdate: ProductPricingUpdateDto): Observable<ProductPricingDto[]> {
    return this.http.put<ProductPricingDto[]>(`${this.apiUrl}/Operation/${operationId}/ProductPricing`, productPricingUpdate);
  }

  deletePricing(operationId: string, id: string): Observable<ProductPricingDto[]> {
    return this.http.delete<ProductPricingDto[]>(`${this.apiUrl}/Operation/${operationId}/ProductPricing/${id}`);
  }
}