import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AUTH_CONFIG, AuthFacade, AuthServiceConfig, AuthState } from '@common/angular/auth';
import { UserProfileDto } from '@common/angular/core/models';
import { filter, finalize, first, map, Observable, switchMap, tap } from 'rxjs';
import { CommonUserProfileFacade, State } from '../+state';

@Injectable({
  providedIn: 'root'
})
export class CommonUserProfileCompletedGuard {

  loading = false;

  constructor(
    private readonly authFacade: AuthFacade,
    private readonly userProfileFacade: CommonUserProfileFacade,
    private readonly router: Router,
    @Inject(AUTH_CONFIG) private readonly authConfig: AuthServiceConfig
  ) {
  }

  canMatch(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getUserProfile().pipe(
      map((userProfile) => {

        if (!userProfile?.isComplete) {
          // route to user profile
          return this.router.parseUrl('/user-profile');
        }
        return true;
      })
    );
  }

  private getUserProfile(): Observable<UserProfileDto> {
    return this.authFacade.state$.pipe(
      filter((autState: AuthState) => autState.isAuthenticated),
      switchMap(authState => {
        return this.userProfileFacade.state$.pipe(
          tap((userProfileState: State) => {
            if (!this.loading && !userProfileState.loaded) {
              this.loading = true;
              this.userProfileFacade.get(authState.authUser?.id as string);
            }
          }),
          filter((userProfileState: State) => userProfileState.loaded),
          first(),
          finalize(() => (this.loading = false)),
          map((userProfileState: State) => userProfileState.userProfile!)
        );
      })
    )
  }
}
