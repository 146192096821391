import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { AppContextDto } from '@ifhms/models/shared';
import { FacilitiesActions } from './facilities.actions';
import * as reducer from './facilities.reducer';
import { FacilitiesSelectors } from './facilities.selectors';

@Injectable()
export class FacilitiesFacade {

  loaded$ = this.store.select(FacilitiesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(FacilitiesSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}
  
  get(context?: AppContextDto): void {
    this.store.dispatch(FacilitiesActions.get({ context }));
  }
}
