import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  FeedlotArrivalSettingsDto,
  FeedlotReHandlingSettingsDto,
  FeedlotPostMortemSettingsDto,
  FeedlotDefaultValuesSettingsDto,
  FeedlotPricingSettingsDto,
  FeedlotGeneralSettingsDto,
  FeedlotGeneralSettingsUpdateDto,
  OperationDto,
  OperationSettingsCciaDto,
  OperationSettingsLocationDto,
  OperationSettingsPmIntegrationDto,
  OperationSettingsSortGroupDto
} from '@ifhms/models/admin';

import { Observable } from 'rxjs';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class FeedlotSettingsService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  getGeneralSettings(feedlotId: string): Observable<FeedlotGeneralSettingsDto> {
    return this.http.get<FeedlotGeneralSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/General`
    );
  }

  updateGeneralSettings(
    feedlotId: string,
    dto: FeedlotGeneralSettingsUpdateDto
  ): Observable<FeedlotGeneralSettingsDto> {
    return this.http.put<FeedlotGeneralSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/General`,
      dto
    );
  }

  getArrivalSettings(feedlotId: string): Observable<FeedlotArrivalSettingsDto> {
    return this.http.get<FeedlotArrivalSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/WorkOrdersAndEvents/ArrivalSettings`
    );
  }

  updateArrivalSettings(
    feedlotId: string,
    dto: FeedlotArrivalSettingsDto
  ): Observable<FeedlotArrivalSettingsDto> {
    return this.http.put<FeedlotArrivalSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/WorkOrdersAndEvents/ArrivalSettings`,
      dto
    );
  }

  getReHandlingSettings(
    feedlotId: string
  ): Observable<FeedlotReHandlingSettingsDto> {
    return this.http.get<FeedlotReHandlingSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/WorkOrdersAndEvents/ReHandlingSettings`
    );
  }

  updateReHandlingSettings(
    feedlotId: string,
    dto: FeedlotReHandlingSettingsDto
  ): Observable<FeedlotReHandlingSettingsDto> {
    return this.http.put<FeedlotReHandlingSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/WorkOrdersAndEvents/ReHandlingSettings`,
      dto
    );
  }

  getPostMortemSettings(
    feedlotId: string
  ): Observable<FeedlotPostMortemSettingsDto> {
    return this.http.get<FeedlotPostMortemSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/WorkOrdersAndEvents/PostMortemSettings`
    );
  }

  updatePostMortemSettings(
    feedlotId: string,
    dto: FeedlotPostMortemSettingsDto
  ): Observable<FeedlotPostMortemSettingsDto> {
    return this.http.put<FeedlotPostMortemSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/WorkOrdersAndEvents/PostMortemSettings`,
      dto
    );
  }

  getDefaultValuesSettings(
    feedlotId: string
  ): Observable<FeedlotDefaultValuesSettingsDto> {
    return this.http.get<FeedlotDefaultValuesSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/GlobalSettings`
    );
  }

  updateDefaultValuesSettings(
    feedlotId: string,
    dto: FeedlotDefaultValuesSettingsDto
  ): Observable<FeedlotDefaultValuesSettingsDto> {
    return this.http.put<FeedlotDefaultValuesSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/GlobalSettings`,
      dto
    );
  }

  getPricingSettings(feedlotId: string): Observable<FeedlotPricingSettingsDto> {
    return this.http.get<FeedlotPricingSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/GlobalSettings/Pricing`
    );
  }

  updatePricingSettings(
    feedlotId: string,
    dto: FeedlotPricingSettingsDto
  ): Observable<FeedlotPricingSettingsDto> {
    return this.http.put<FeedlotPricingSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/GlobalSettings/Pricing`,
      dto
    );
  }

  applyPricingSettings(
    feedlotId: string,
    dto: FeedlotPricingSettingsDto
  ): Observable<FeedlotPricingSettingsDto> {
    return this.http.post<FeedlotPricingSettingsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Settings/GlobalSettings/Pricing`,
      dto
    );
  }

  updateLocationSettings(
    operationId: string,
    dto: OperationSettingsLocationDto
  ): Observable<OperationDto> {
    return this.http.put<OperationDto>(
      `${this.apiUrl}/Operations/${operationId}/Settings/Location`,
      dto
    );
  }

  updateSortGroupSettings(
    operationId: string,
    dto: OperationSettingsSortGroupDto
  ): Observable<OperationDto> {
    return this.http.put<OperationDto>(
      `${this.apiUrl}/Operations/${operationId}/Settings/SortGroup`,
      dto
    );
  }

  getCCIAAccountSettings(
    operationId: string
  ): Observable<OperationSettingsCciaDto> {
    return this.http.get<OperationSettingsCciaDto>(
      `${this.apiUrl}/Operations/${operationId}/Settings/Ccia`
    );
  }

  updateCCIAAccountSettings(
    operationId: string,
    dto: OperationSettingsCciaDto
  ): Observable<OperationSettingsCciaDto> {
    return this.http.put<OperationSettingsCciaDto>(
      `${this.apiUrl}/Operations/${operationId}/Settings/Ccia`,
      dto
    );
  }

  getPmIntegrationSettings(
    operationId: string
  ): Observable<OperationSettingsPmIntegrationDto> {
    return this.http.get<OperationSettingsPmIntegrationDto>(
      `${this.apiUrl}/Operations/${operationId}/Settings/PmIntegration`
    );
  }

  updatePmIntegrationSettings(
    operationId: string,
    dto: OperationSettingsPmIntegrationDto
  ): Observable<OperationSettingsPmIntegrationDto> {
    return this.http.put<OperationSettingsPmIntegrationDto>(
      `${this.apiUrl}/Operations/${operationId}/Settings/PmIntegration`,
      dto
    );
  }
}