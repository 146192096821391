import { HttpClient, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FeedlotCustomFieldCreateDto, FeedlotCustomFieldDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class FeedlotCustomFieldsSettingsService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  getAll(feedlotId: string): Observable<FeedlotCustomFieldDto[]> {
    return this.http.get<FeedlotCustomFieldDto[]>(
      `${this.apiUrl}/Feedlots/${feedlotId}/Settings/CustomFields`
    );
  }

  get(
    feedlotId: string,
    customFieldId: string
  ): Observable<FeedlotCustomFieldDto> {
    return this.http.get<FeedlotCustomFieldDto>(
      `${this.apiUrl}/Feedlots/${feedlotId}/Settings/CustomFields/${customFieldId}`
    );
  }

  isCodeValid(
    feedlotId: string,
    customFieldId: string,
    code: string
  ): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        code,
        customFieldId
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(
      `${this.apiUrl}/Feedlots/${feedlotId}/Settings/CustomFields/IsCodeValid`,
      options
    );
  }

  add(
    feedlotId: string,
    customField: FeedlotCustomFieldCreateDto
  ): Observable<FeedlotCustomFieldDto> {
    return this.http.post<FeedlotCustomFieldDto>(
      `${this.apiUrl}/Feedlots/${feedlotId}/Settings/CustomFields`,
      JSON.stringify(customField),
      {
        headers: this.headers
      }
    );
  }

  update(
    feedlotId: string,
    customField: FeedlotCustomFieldDto
  ): Observable<FeedlotCustomFieldDto> {
    return this.http.put<FeedlotCustomFieldDto>(
      `${this.apiUrl}/Feedlots/${feedlotId}/Settings/CustomFields`,
      JSON.stringify(customField),
      {
        headers: this.headers
      }
    );
  }

  reorder(
    feedlotId: string,
    customFieldIdList: string[]
  ): Observable<FeedlotCustomFieldDto[]> {
    return this.http.put<FeedlotCustomFieldDto[]>(
      `${this.apiUrl}/Feedlots/${feedlotId}/Settings/CustomFields/Reorder`,
      JSON.stringify(customFieldIdList),
      {
        headers: this.headers
      }
    );
  }

  remove(
    feedlotId: string,
    customFieldId: string
  ): Observable<FeedlotCustomFieldDto> {
    return this.http.delete<FeedlotCustomFieldDto>(
      `${this.apiUrl}/Feedlots/${feedlotId}/Settings/CustomFields/${customFieldId}`
    );
  }
}
