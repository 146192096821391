import { AfterViewInit, Component, Type, ViewChild, ViewEncapsulation } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'sersi-calender-type',
  templateUrl: './calender-type.component.html',
  styleUrls: ['./calender-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalenderTypeComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  type: 'datepicker' | Type<CalenderTypeComponent>;

  @ViewChild(Calendar, { static: false }) calendar: Calendar | null;

  override defaultOptions = {
    props: {
      placeholder: '',
      description: 'Description',
      dateFormat: 'M dd, yy',
      hourFormat: '24',
      numberOfMonths: 1,
      selectionMode: 'single',
      required: true,
      readonlyInput: false,
      showTime: false,
      showButtonBar: true,
      showIcon: true,
      showOtherMonths: true,
      selectOtherMonths: false,
      monthNavigator: false,
      yearNavigator: false,
      yearRange: '2020:2030',
      inline: false,
      styleClass: '',
      maxDate: null,
      hideOnSelect: true,
      showOnFocus: true
    }
  };

  ngAfterViewInit(): void {
    this.field.props['calendar'] = this.calendar;
  }

  protected readonly Date = Date;
}
