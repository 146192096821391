import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as reducer from './pricing.reducer';
import { Observable } from 'rxjs';
import { FeedlotPricingSettingsDto } from '@ifhms/models/admin';
import { PricingSettingsActions } from './pricing.actions';
import { PricingSettingsSelectors } from './pricing.selectors';

@Injectable()
export class PricingSettingsFacade {

  loading$: Observable<boolean> = this.store.select(PricingSettingsSelectors.selectLoading);
  loaded$: Observable<boolean> = this.store.select(PricingSettingsSelectors.selectLoaded);
  updating$: Observable<boolean> = this.store.select(PricingSettingsSelectors.selectUpdating);
  updated$: Observable<boolean> = this.store.select(PricingSettingsSelectors.selectUpdated);
  pricingSettings$: Observable<FeedlotPricingSettingsDto | null> = this.store.select(PricingSettingsSelectors.selectPricingSettings);

  constructor(private store: Store<reducer.State>) {}
  
  getPricingSettings(): void {
    this.store.dispatch(PricingSettingsActions.getPricingSettings());
  }

  updatePricingSettings(settings: FeedlotPricingSettingsDto): void {
    this.store.dispatch(PricingSettingsActions.updatePricingSettings({ settings }));
  }

  resetPricingSettings(): void {
    this.store.dispatch(PricingSettingsActions.resetPricingSettings());
  }

  applyPricingSettings(settings: FeedlotPricingSettingsDto): void {
    this.store.dispatch(PricingSettingsActions.applyPricingSettings({ settings }));
  }
}
