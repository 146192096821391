import { createSelector } from '@ngrx/store';

import { selectFeatureState, State } from '../reducer';
import * as reducer from './labour-charge-types.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.labourChargeTypes
);

const selectItems = createSelector(selectState, reducer.getItems);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

export const LabourChargeTypesSelectors = {
  selectItems,
  selectLoaded
};
