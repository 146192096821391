import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterState, RouterStateData } from '../../models';

export const selectRouter = createFeatureSelector<RouterState,
fromRouter.RouterReducerState<RouterStateData>>('router');

export const selectRouteState = createSelector(selectRouter, router => router?.state);

const {
  selectCurrentRoute,   // select the current route
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl            // select the current url
} = fromRouter.getRouterSelectors(selectRouter);

export const routerSelectors = {
  selectCurrentRoute,   // select the current route
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectRouteState,     // select all nested routes state
  selectUrl            // select the current url
};

