import { createAction, props } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

const NAMESPACE = '[Reference - Pens] - ';

const get = createAction(
  `${NAMESPACE} Get`,
  props<{ levelId?: string }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ items: SersiSelectListItem[] }>()
);

const error = createAction(`${NAMESPACE} error`);

export const PensActions = {
  error,
  get,
  getSuccess
};
