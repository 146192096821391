import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CompanyCreateDto, CompanyDto } from '@ifhms/models/admin';
import { getLoadedStateData } from '@common/angular/utils';

import { CompaniesActions } from './companies.actions';
import { State } from './companies.reducer';
import { CompaniesSelectors } from './companies.selectors';

@Injectable()
export class CompaniesFacade {

  state$ = this.store.select(CompaniesSelectors.selectState);

  companies$: Observable<CompanyDto[] | null> = this.store.select(CompaniesSelectors.selectCompanies);

  isCreating$: Observable<boolean> = this.store.select(CompaniesSelectors.selectCreating);
  isCreated$: Observable<boolean> = this.store.select(CompaniesSelectors.selectCreated);

  isLoading$: Observable<boolean> = this.store.select(CompaniesSelectors.selectLoading);
  isLoaded$: Observable<boolean> = this.store.select(CompaniesSelectors.selectLoaded);

  loadedCompanies$: Observable<CompanyDto[]> = getLoadedStateData<CompanyDto[]>(this.companies$, this.isLoaded$);

  constructor(private store: Store<State>) {}

  create(companyCreateDto: CompanyCreateDto): void {
    this.store.dispatch(CompaniesActions.create({ companyCreateDto }))
  }

  getCompanies(forceReload = true): void {
    this.store.dispatch(CompaniesActions.get({ forceReload }));
  }

  reset(): void {
    this.store.dispatch(CompaniesActions.reset());
  }
}
