export enum FormlyTypesEnum {
  ACCORDION = 'accordion',
  AUTO_COMPLETE = 'auto-complete',
  BANNER = 'banner',
  BULLET_LIST = 'bullet-list',
  BUTTON = 'button',
  BUTTON_MENU = 'button-menu',
  CALENDAR_INPUT = 'calendar-input',
  CALENDAR_RANGE_INPUT = 'calendar-range-input',
  CHECKBOX = 'checkbox',
  CHECKBOX_GROUP = 'checkbox_group',
  COLOUR_PICKER = 'colour-picker',
  CURRENCY_INPUT = 'currency-input',
  EDITOR = 'editor',
  INFO_ICON = 'info-icon',
  MULTI_SELECT = 'multi-select',
  MULTI_EMAIL_INPUT = 'multi-email-input',
  NUMBER_INPUT = 'number-input',
  PASSWORD = 'password',
  PHONE_NUMBER = 'phone-number',
  POSTAL_CODE = 'postal-code',
  RADIO_BUTTON = 'radio-button',
  RANGE_SLIDER = 'range-slider',
  REPEATING_GRID_TYPE = 'grid',
  REPEAT_SECTION_TYPE = 'repeat-section-type',
  SEARCH_INPUT = 'search-input',
  SECTION_TITLE = 'section-title',
  SINGLE_SELECT = 'single-select',
  SWITCH_INPUT = 'switch-input',
  TEXT_AREA_INPUT = 'text-area-input',
  TEXT_INPUT = 'text-input',
  TEXT_READONLY = 'text-readonly',
  TRANSLATION = 'translation',
  ZIP_CODE = 'zip-code',
  COLOR_BOX = 'color-box'
}
