import { Injectable } from '@angular/core';

import { OperationFacade } from '@ifhms/admin/web/domain/state/operation';
import { FeedlotSettingsService } from '@ifhms/common/angular/data-access/admin-api';
import { FeedlotPricingSettingsDto } from '@ifhms/models/admin';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';

import { catchError, exhaustMap, map, of, tap, withLatestFrom } from 'rxjs';
import { PricingSettingsActions } from './pricing.actions';

@Injectable({
  providedIn: 'root'
})
export class PricingSettingsEffects {
  translateScope = 'feedlotSettings.state';

  constructor(
    private actions$: Actions,
    private feedlotSettingsService: FeedlotSettingsService,
    private operationFacade: OperationFacade,
    private translateService: TranslocoService,
    public messageService: MessageService
  ) {
  }

  getPricingSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingSettingsActions.getPricingSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([, operationId]) =>
        this.feedlotSettingsService.getPricingSettings(operationId).pipe(
          map((settings: FeedlotPricingSettingsDto) => PricingSettingsActions.getPricingSettingsSuccess({ settings })),
          catchError(() => of(PricingSettingsActions.error()))
        )
      )
    )
  );

  updatePricingSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingSettingsActions.updatePricingSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([action, operationId]) =>
        this.feedlotSettingsService.updatePricingSettings(operationId, action.settings).pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: this.getTranslation('success-title'),
              detail: this.getTranslation('saved-success-message')
            });
          }),
          map((settings: FeedlotPricingSettingsDto) => PricingSettingsActions.updatePricingSettingsSuccess({ settings })),
          catchError(() => of(PricingSettingsActions.error()))
        )
      )
    )
  );

  applyPricingSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingSettingsActions.applyPricingSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([action, operationId]) =>
        this.feedlotSettingsService.applyPricingSettings(operationId, action.settings).pipe(
          map((settings) => PricingSettingsActions.applyPricingSettingsSuccess({ settings })),
          catchError(() => of(PricingSettingsActions.error()))
        )
      )
    )
  );

  applySettingsPricingSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PricingSettingsActions.applyPricingSettingsSuccess),
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: this.getTranslation('success-title'),
            detail: this.getTranslation('applied-pricing-success-message')
          });
        })
      ),
    { dispatch: false }
  );

  getTranslation(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }

}
