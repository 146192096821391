// PrimeNg Dialog doesn't respect blockScroll property
// and always adds overflow helper class to body
// while we want to remove this in some cases
import { DialogService } from 'primeng/dynamicdialog';

export function removeBodyOverflow(): void {
  window.setTimeout(() => {
    document.body.classList.remove('p-overflow-hidden');
  }, 500)
}

export function closeDialogs(dialogService: DialogService): void {
  dialogService.dialogComponentRefMap.forEach(dialog => {
    dialog.destroy();
  })
}