import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { getPreviousValue } from '@common/angular/utils';
import { CompanyFacade } from '@ifhms/admin/web/domain/state/company';
import { OperationsService } from '@ifhms/common/angular/data-access/admin-api';
import { CompanyDto, OperationListItemDto } from '@ifhms/models/admin';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { catchError, exhaustMap, filter, map, of, tap, withLatestFrom } from 'rxjs';

import { OperationsActions } from './operations.actions';
import { OperationsFacade } from './operations.facade';

@Injectable()
export class OperationsEffects {

  private translateScope = 'domain.state.operations.effects';

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationsActions.get),
      withLatestFrom(getPreviousValue(this.operationsFacade.state$)),
      filter(([action, state]) => {
        if (action.forceReload || action.companyId !== state.companyId) return true;
        return !state.loaded && !state.loading;
      }),
      exhaustMap(([action]) => {
        return this.operationsService.getAll(action.companyId).pipe(
          map((result: OperationListItemDto[]) =>
            OperationsActions.getSuccess({
              operations: result,
              companyId: action.companyId
            })
          ),
          catchError(() => of(OperationsActions.error()))
        )
      })
    )
  );

  getCached$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationsActions.get),
      withLatestFrom(getPreviousValue(this.operationsFacade.state$)),
      filter(([action, state]) => {
        return !action.forceReload && state.loaded && action.companyId === state.companyId;
      }),
      map(() => OperationsActions.getSuccessCached())
    )
  );

  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationsActions.refresh),
      withLatestFrom(this.companyFacade.companyId$),
      exhaustMap(([, companyId]) =>
        this.operationsService.getAll(companyId).pipe(
          map((result: OperationListItemDto[]) =>
            OperationsActions.getSuccess({
              operations: result,
              companyId
            })
          ),
          catchError(() => of(OperationsActions.error()))
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationsActions.create),
      withLatestFrom(this.companyFacade.company$.pipe(filter((val): val is CompanyDto => !!val))),
      exhaustMap(([action, company]) =>
        this.operationsService.create(
          company.id,
          {
            name: action.name,
            code: action.code,
            companyId: company.id,
            countryId: action.countryId,
            operationTypeId: action.operationTypeId
          }).pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: this.getTranslation('create-success-title'),
              detail: this.getTranslation('create-success-message')
            })
          }),
          map((operations: OperationListItemDto[]) => {
            this.router.navigate(['/company/' + company.slug + '/operations']);
            return OperationsActions.createSuccess({ operations });
          }
          ),
          catchError(() => of(OperationsActions.error()))
        )
      )
    )
  );

  private getTranslation(key: string): string {
    return this.translocoService.translate(`${this.translateScope}.${key}`);
  }

  constructor(
    private actions$: Actions,
    private companyFacade: CompanyFacade,
    private operationsFacade: OperationsFacade,
    private operationsService: OperationsService,
    private messageService: MessageService,
    public translocoService: TranslocoService,
    private router: Router
  ) {
  }
}
