import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';
import {
  ProFeederEnabledDto,
  ProFeederListItemDto,
  ProFeederModalSettingsDto
} from '@ifhms/models/admin';

@Injectable()
export class FeedlotSettingsProFeederService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  getTable(operationId: string): Observable<ProFeederListItemDto[]> {
    return this.http.get<ProFeederListItemDto[]>(
      `${this.apiUrl}/Operation/${operationId}/ProFeeder/History`
    );
  }

  getModalSettings(operationId: string): Observable<ProFeederModalSettingsDto> {
    return this.http.get<ProFeederModalSettingsDto>(
      `${this.apiUrl}/Operation/${operationId}/ProFeeder/Settings`
    );
  }

  updateModalSettings(
    settings: ProFeederModalSettingsDto,
    operationId: string
  ): Observable<ProFeederModalSettingsDto> {
    return this.http.put<ProFeederModalSettingsDto>(
      `${this.apiUrl}/Operation/${operationId}/ProFeeder/Settings`,
      settings
    );
  }

  getProFeederIsEnabled(operationId: string): Observable<ProFeederEnabledDto> {
    return this.http.get<ProFeederEnabledDto>(
      `${this.apiUrl}/Operation/${operationId}/ProFeeder/IsEnabled`
    );
  }

  updateProFeederIsEnabled(
    operationId: string,
    isEnabled: boolean
  ): Observable<ProFeederEnabledDto> {
    return this.http.put<ProFeederEnabledDto>(
      `${this.apiUrl}/Operation/${operationId}/ProFeeder/IsEnabled/${isEnabled}`,
      null
    );
  }

  generateUsageReport(
    startDate: string,
    endDate: string,
    operationId: string
  ): Observable<any> {
    const options = {
      startDate,
      endDate
    };

    return this.http.put(
      `${this.apiUrl}/Operation/${operationId}/ProFeeder/ExportVetUsage`,
      options,
      { 
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/octet-stream'
        },
        observe: 'response'
      }
    );
  }

  generateMoveReport(
    startDate: string,
    endDate: string,
    operationId: string
  ): Observable<any> {
    const options = {
      startDate,
      endDate
    };
    return this.http.put(
      `${this.apiUrl}/Operation/${operationId}/ProFeeder/ExportVetMoves`,
      options,
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/octet-stream'
        },
        observe: 'response'
      }
    );
  }
}
