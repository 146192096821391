import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProductMappingCreateDto, ProductMappingDto, ProductMappingUpdateDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class OperationProductMappingService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  getList(operationId: string, productId: string): Observable<ProductMappingDto[]> {
    return this.http.get<ProductMappingDto[]>(`${this.apiUrl}/OperationProductMapping/${operationId}/List/${productId}`);
  }

  create(operationId: string, productMappingCreate: ProductMappingCreateDto): Observable<ProductMappingDto[]> {
    return this.http.post<ProductMappingDto[]>(`${this.apiUrl}/OperationProductMapping/${operationId}`, productMappingCreate);
  }

  update(operationId: string, productMappingUpdate: ProductMappingUpdateDto): Observable<ProductMappingDto[]> {
    return this.http.put<ProductMappingDto[]>(`${this.apiUrl}/OperationProductMapping/${operationId}`, productMappingUpdate);
  }

  isSkuUnique(operationId: string, vendorId: string, sku: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}/OperationProductMapping/${operationId}/IsSkuUnique/${vendorId}/${sku}`);
  }

  toggleActiveStatus(operationId: string, id: string): Observable<ProductMappingDto[]> {
    return this.http.put<ProductMappingDto[]>(`${this.apiUrl}/OperationProductMapping/${operationId}/ToggleActiveStatus/${id}`, null);
  }
}