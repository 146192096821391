import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IfhmsAdminDomainStateCompaniesModule } from '@ifhms/admin/domain/state/companies';
import { IfhmsAdminWebDomainStateCompanyModule } from '@ifhms/admin/web/domain/state/company';
import { IfhmsAdminWebDomainStateLayoutModule } from '@ifhms/admin/web/domain/state/layout';
import { IfhmsAdminWebDomainStateOperationsModule } from '@ifhms/admin/web/domain/state/operations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

//Test

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true
        }
      }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // DONT COMMIT ME
      name: 'TFT - Admin',
      logOnly: false
      , connectInZone: true }),
    EffectsModule.forRoot([]),
    IfhmsAdminWebDomainStateCompanyModule,
    IfhmsAdminDomainStateCompaniesModule,
    IfhmsAdminWebDomainStateOperationsModule,
    IfhmsAdminWebDomainStateLayoutModule
  ]
})
export class IfhmsAdminDomainStateRootModule {
}
