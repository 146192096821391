import { Component, HostBinding, inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { scopeLoader, TranslateService } from '@common/angular/translation';

import { filterSelectListByAttribute, updateFieldSelectItems } from '@common/angular/utils';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyTypesEnum } from '@sersi/angular/formly/core';
import { isEqual } from 'lodash-es';
import { filter, Observable, take } from 'rxjs';
import { AnimalSelectorFacade } from '../../+state';

import { AnimalSelectorAnimalItem, AnimalSelectorSearchParams } from '../../interfaces';

@UntilDestroy()
@Component({
  selector: 'ifhms-animal-selector-form',
  templateUrl: './animal-selector-form.component.html',
  styleUrls: ['./animal-selector-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'animalSelector',
        alias: 'animalSelector',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class AnimalSelectorFormComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  readonly translateScope = 'animalSelector.components.animal-selector-form';

  @HostBinding('class.animal-selector-form') hostClass = true;

  nestedForm = new UntypedFormGroup({});
  nestedFields: FormlyFieldConfig[];
  nestedOptions: FormlyFormOptions = {};
  nestedModel: AnimalSelectorSearchParams;

  protected translateService = inject(TranslateService);
  private readonly translocoService = inject(TranslocoService);

  private readonly animalSelectorFacade = inject(AnimalSelectorFacade);
  private readonly scope = inject(TRANSLOCO_SCOPE);

  availableAnimals$ = this.animalSelectorFacade.animals$;
  isLoading$ = this.animalSelectorFacade.loading$;

  private get hasSearchParams(): boolean {
    const { lotId, currentPenId, homePenId, subGroupId } = this.nestedModel;
    const hasSearchParam = lotId || currentPenId || homePenId || subGroupId;
    return !!hasSearchParam;
  }

  ngOnInit(): void {
    this.nestedModel = {} as AnimalSelectorSearchParams;
    this.setFieldsOnTranslationReady();
    this.listenSelectedAnimalsChange();
  }

  ngOnDestroy(): void {
    this.animalSelectorFacade.reset();
    this.nestedForm.reset();
  }

  onSelectedAnimalsChange(animals: AnimalSelectorAnimalItem[]): void {
    this.animalSelectorFacade.setSelectedAnimals(animals);
  }
  private setFieldsOnTranslationReady(): void {
    this.loadTranslation().subscribe(() => {
      this.setFormFields();
    });
  }

  private setFormFields(): void {
    this.nestedFields = [
      this.setHeaderSection()
    ]
  }

  private setHeaderSection(): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'flex gap-4 header-container',
      fieldGroup: [
        this.setFiltersSection(),
        this.setActionsButtons()
      ]
    }
  }

  private setFiltersSection(): FormlyFieldConfig {
    return {
      className: 'block w-full',
      fieldGroupClassName: 'flex gap-4 filters-section',
      fieldGroup: [
        this.setPen(),
        this.setHomePen(),
        this.setLot(),
        this.setLotsSubGroup()
      ]
    }
  }

  private setPen(): FormlyFieldConfig {
    return {
      key: 'currentPenId',
      type: FormlyTypesEnum.SINGLE_SELECT,
      props: {
        label$: this.translateService.getTranslation$(this.translateScope, 'filters.pen'),
        placeholder$: this.translateService.getTranslation$(this.translateScope, 'filters.option-select'),
        items$: this.props['penList$'],
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE',
        preventOverlayClickEvent: true
      }
    };
  }

  private setHomePen(): FormlyFieldConfig {
    return {
      key: 'homePenId',
      type: FormlyTypesEnum.SINGLE_SELECT,
      props: {
        label$:  this.translateService.getTranslation$(this.translateScope, 'filters.home-pen'),
        placeholder$:  this.translateService.getTranslation$(this.translateScope, 'filters.option-select'),
        items$: this.props['homePenList$'],
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE',
        preventOverlayClickEvent: true
      }
    };
  }

  private setLot(): FormlyFieldConfig {
    return {
      key: 'lotId',
      type: FormlyTypesEnum.SINGLE_SELECT,
      props: {
        label$:  this.translateService.getTranslation$(this.translateScope, 'filters.lot'),
        placeholder$:  this.translateService.getTranslation$(this.translateScope, 'filters.option-select'),
        items$: this.props['lotList$'],
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE',
        preventOverlayClickEvent: true
      }
    };
  }

  private setLotsSubGroup(): FormlyFieldConfig {
    return {
      key: 'subGroupId',
      type: FormlyTypesEnum.SINGLE_SELECT,
      props: {
        label$:  this.translateService.getTranslation$(this.translateScope, 'filters.sub-group'),
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE',
        preventOverlayClickEvent: true
      },
      expressions: {
        'props.disabled': (): boolean => !this.nestedModel.lotId
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.form
            ?.get('lotId')
            ?.valueChanges.pipe(untilDestroyed(this))
            .subscribe((value: string) => {
              const subGroupList$ = filterSelectListByAttribute(
                this.props['lotSubGroupList$'],
                'lotId',
                value
              );
              updateFieldSelectItems(field, subGroupList$);
            });
        }
      }
    };
  }

  private setActionsButtons(): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'flex gap-2 filters-action-buttons',
      fieldGroup: [
        this.setClearButton(),
        this.setSearchButton()
      ]
    };
  }

  private setClearButton(): FormlyFieldConfig {
    return {
      type: FormlyTypesEnum.BUTTON,
      props: {
        buttonClass: 'p-button-raised p-button-outlined p-button-secondary sersi-min-width-125 p-button-rounded',
        label$:  this.translateService.getTranslation$(this.translateScope, 'filters.btn-clear'),
        click: (): void => {
          this.animalSelectorFacade.reset();
          this.nestedForm.reset();
        }
      }
    }
  }

  private setSearchButton(): FormlyFieldConfig {
    return {
      type: FormlyTypesEnum.BUTTON,
      props: {
        buttonClass: 'p-button-primary p-button-raised p-button-secondary sersi-min-width-100 p-button-rounded',
        label$:  this.translateService.getTranslation$(this.translateScope, 'filters.btn-search'),
        click: () => this.searchAnimals(),
        attributes: {
          'data-ele': 'search-button'
        }
      },
      expressions: {
        'props.disabled': (): boolean => !this.hasSearchParams
      }
    }
  }

  private searchAnimals(): void {
    this.animalSelectorFacade.search({
      ...this.nestedModel,
      operationId: this.props['operationId']
    });
  }

  private listenSelectedAnimalsChange(): void {
    this.animalSelectorFacade.selectedAnimals$
      .pipe(
        filter((animals) => !isEqual(this.formControl.value, animals)),
        untilDestroyed(this)
      )
      .subscribe((selectedAnimals) => {
        console.log('assigning selected animals to form')
        this.formControl.setValue(selectedAnimals)
      });
  }
  private loadTranslation(): Observable<void> {
    return this.translocoService.selectTranslate('', {}, this.scope)
      .pipe(
        take(1),
        untilDestroyed(this)
      );
  }
}
