import { FormlyFieldConfig } from '@ngx-formly/core'

export function toggleReadForWriteAccess(field: FormlyFieldConfig): void {
  field?.form?.get('write')?.valueChanges.subscribe(
    (value) => {
      if (value && field?.formControl?.value === false) {
        field?.formControl.setValue(true)
      }
    }
  )
}

export function toggleWriteForNoReadAccess(field: FormlyFieldConfig): void {
  field?.form?.get('read')?.valueChanges.subscribe(
    (value) => {
      if (!value && field?.formControl?.value) {
        field?.formControl.setValue(false)
      }
    }
  )
}

export function toggleFinalizeForNoReadWriteAccess(field: FormlyFieldConfig): void {
  field?.form?.get('read')?.valueChanges.subscribe(
    (value) => {
      if (!value && field?.formControl?.value) {
        field?.formControl.setValue(false)
      }
    }
  )
  field?.form?.get('write')?.valueChanges.subscribe(
    (value) => {
      if (!value && field?.formControl?.value) {
        field?.formControl.setValue(false)
      }
    }
  )
}

export function toggleReadWriteForFinalizeAccess(field: FormlyFieldConfig): void {
  field?.form?.get('finalize')?.valueChanges.subscribe(
    (value) => {
      if (value && field?.formControl?.value === false) {
        field?.formControl.setValue(true)
      }
    }
  )
}

export function toggleReadWriteForCompleteAccess(field: FormlyFieldConfig): void {
  field?.form?.get('complete')?.valueChanges.subscribe(
    (value) => {
      if (value && field?.formControl?.value === false) {
        field?.formControl.setValue(true)
      }
    }
  )
}