import { FeedlotPostMortemSettingsDto } from '@ifhms/models/admin';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[PostMortem] - ';

// ===== PostMortem Settings ======
const getPostMortemSettings = createAction(
  `${NAMESPACE} Get PostMortem Settings`
);

const getPostMortemSettingsSuccess = createAction(
  `${NAMESPACE} Get PostMortem Settings Success`,
  props<{ settings: FeedlotPostMortemSettingsDto }>()
);

const updatePostMortemSettings = createAction(
  `${NAMESPACE} Update PostMortem Settings`,
  props<{ settings: FeedlotPostMortemSettingsDto }>()
);

const updatePostMortemSettingsSuccess = createAction(
  `${NAMESPACE} Update PostMortem Settings Success`,
  props<{ settings: FeedlotPostMortemSettingsDto }>()
);

const resetPostMortemSettings = createAction(
  `${NAMESPACE} Reset PostMortem Settings`
);

const error = createAction(`${NAMESPACE} error`);

export const PostMortemSettingsActions = {
  error,
  getPostMortemSettings,
  getPostMortemSettingsSuccess,
  updatePostMortemSettings,
  updatePostMortemSettingsSuccess,
  resetPostMortemSettings
};
