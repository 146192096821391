import { Injectable } from '@angular/core';
import { HashMap, TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { identity, pickBy } from 'lodash-es';
import { MessageService } from 'primeng/api';
import { catchError, map, of, tap } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';

import { AnimalSelectorSearchParams } from '../interfaces';
import { AnimalSelectorService } from '../services/animal-selector.service';
import { AnimalSelectorActions } from './animal-selector.actions';

@Injectable()
export class AnimalSelectorEffects {

  readonly translateScope = 'animalSelector.state';

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnimalSelectorActions.search),
      exhaustMap((action) => {
        const requestParams = pickBy(action.searchParams, identity) as AnimalSelectorSearchParams;
        return this.animalSelectorService.search(requestParams)
          .pipe(
            map(result => AnimalSelectorActions.searchSuccess({ animals: result.animals })),
            catchError(() => of(AnimalSelectorActions.error()))
          )
      })
    )
  );
    
  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnimalSelectorActions.error),
      tap(() => {
        this.messageService.add({
          severity: 'error',
          summary: this.getTranslation('generic-err.title'),
          detail: this.getTranslation('generic-err.message')
        });

      })
    ), { dispatch: false }
  );
  
  constructor(
    private actions$: Actions,
    private animalSelectorService: AnimalSelectorService,
    private translateService: TranslocoService,
    private messageService: MessageService
  ) {}

  private getTranslation(key: string, params?: HashMap): string {
    return this.translateService.translate(`${this.translateScope}.${key}`, params);
  }

}
