<ng-container *transloco="let t; read: translateScope">
  <formly-form
    [fields]="nestedFields"
    [form]="nestedForm"
    [model]="nestedModel"
    [options]="nestedOptions"
  />
  <sersi-spin [spin]="isLoading$ | async">
    <ifhms-animal-selector-dual-list-box
      [animals]="availableAnimals$ | async"
      [selectedAnimals]="formControl.value"
      (selectedAnimalsChange)="onSelectedAnimalsChange($event)"
    />
  </sersi-spin>
</ng-container>