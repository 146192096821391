<div *ngIf="props">
    <div class="group-wrapper" [class.formly-field-no-margin]="props['collapsed']">
        <label class="sersi-group-label">
            <span>{{props['label']}}
            <span *ngIf="props['collapsed'] && fieldProps['isRequired'] !== false" class="required-symbol">*</span></span>
            <span class="icon-group" (click)="toggleCollapsed()">
                <sersi-icon name="translate" classNames="mr-1" [pTooltip]="props['tooltipTranslate'] || null"></sersi-icon>
                <i [@rotatedState]="props['collapsed']" class="pi pi-angle-up"></i>
            </span>
        </label>

        <div [class.group-wrapper-border]="showBorder && !props['collapsed']">
            <ng-container *ngFor="let field of field.fieldGroup">
                <formly-field [field]="field" [class]="field.className"></formly-field>
            </ng-container>
        </div>
    </div>
</div>