import { createReducer, on } from '@ngrx/store';
import { FeedlotDefaultValuesSettingsDto } from '@ifhms/models/admin';
import { DefaultValuesSettingsActions } from './default-values.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  updating: boolean;
  updated: boolean;
  defaultValuesSettings: FeedlotDefaultValuesSettingsDto | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  defaultValuesSettings: null,
  updating: false,
  updated: false
};

export const reducer = createReducer(
  initialState,

  on(DefaultValuesSettingsActions.getDefaultValuesSettings,
    (state) => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),

  on(DefaultValuesSettingsActions.getDefaultValuesSettingsSuccess,
    (state, action) => ({
      ...state,
      defaultValuesSettings: action.settings,
      loading: false,
      loaded: true
    })
  ),

  on(
    DefaultValuesSettingsActions.updateDefaultValuesSettings,
    state => ({
      ...state,
      updating: true
    })
  ),

  on(DefaultValuesSettingsActions.updateDefaultValuesSettingsSuccess,
    (state, action) => (
      {
        ...state,
        defaultValuesSettings: action.settings,
        updating: false
      })
  ),

  on(DefaultValuesSettingsActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false,
    updating: false,
    updated: false
  })),

  on(DefaultValuesSettingsActions.resetDefaultValuesSettings,
    (state) => ({
      ...state,
      defaultValuesSettings: null
    })
  )
);

export const getDefaultValuesSettings = (state: State): FeedlotDefaultValuesSettingsDto | null => state.defaultValuesSettings;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getUpdating = (state: State): boolean => state.updated;
export const getUpdated = (state: State): boolean => state.updating;
