import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ReferenceDataCountryService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) { }

  get(): Observable<SelectListItemWithTranslationDto[]> {
    return this.http.get<SelectListItemWithTranslationDto[]>(`${this.apiUrl}/Reference/Countries/SelectList`);
  }
}
