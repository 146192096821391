import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthUser } from '../interfaces';

export const AUTH_SERVICE_TOKEN = new InjectionToken<CommonAuthService>('AUTH_SERVICE');

export abstract class CommonAuthService {
  abstract init(): Observable<boolean>;
  abstract login(redirectUrl?: string): Observable<AuthUser | null>;
  abstract logout(): Observable<boolean>;
  abstract refreshSession(): Observable<boolean>;
  abstract clearSession(): Observable<boolean>;
}
