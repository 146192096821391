import { HashMap } from '@common/angular/interfaces';

/**
 * Transforms an array of objects into an object where each key is the value of a specified property from the objects in the array, and each value is `true`.
 *
 * @param {HashMap[]} list - The array of objects to be transformed.
 * @param {string} indexKey - The property name to be used as a key in the resulting object.
 * @returns {HashMap<boolean>} - An object where each key is the value of the `indexKey` property from the objects in the array, and each value is `true`.
 *
 * @example
 * const list = [{ id: 1 }, { id: 2 }, { id: 3 }];
 * const indexKey = 'id';
 * const result = getIndexedMapFromList(list, indexKey);
 * console.log(result); // { '1': true, '2': true, '3': true }
 */
export function getIndexedMapFromList(list: HashMap[], indexKey: string): HashMap<boolean> {
  return list.reduce((memo, iter) => {
    memo[iter[indexKey]] = true;
    return memo;
  }, {});
}

/**
 * Reset the value of a property in an object.
 * Finds all occurrences of the property on any nested level
 *
 * @param obj The object to reset
 * @param key The property key to reset
 * @param value The value to reset to
 */
export function resetObjectProperty(obj: HashMap | null, key: string, value: unknown): HashMap | null {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // If the object has target property, set it to target value
  if (Object.prototype.hasOwnProperty.call(obj, key)) {
    return { ...obj, [key]: value };
  }

  // Recursively apply to all object properties
  const newState = Array.isArray(obj) ? obj.slice() : { ...obj };
  Object.keys(newState).forEach(k => {
    // @ts-ignore
    newState[k] = resetObjectProperty(newState[k], key, value);
  });

  return newState;
}