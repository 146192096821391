<div
  class="text-tags"
  [ngClass]="className"
  [class.disabled-text]="disabled"
>
  <sersi-color-box
    *ngIf="item.attributes && item.attributes['hexCode']"
    [hexCode]="item.attributes['hexCode']!.toString()">
  </sersi-color-box>
  <ng-container *ngIf="viewMode === SelectViewMode.CODE">
    {{ item.code }}
  </ng-container>
  <ng-container *ngIf="viewMode === SelectViewMode.CODE_DESCRIPTION">
    @if (item.codeDescription) {
      {{ item.codeDescription }}
    } @else if (item.code) {
      ({{ item.code }}) {{ item.description }}
    } @else {
      {{ item.description }}
    }
  </ng-container>
  <ng-container *ngIf="viewMode === SelectViewMode.DESCRIPTION">
    {{ item.description }}
  </ng-container>
  <ng-container *ngIf="viewMode === SelectViewMode.DESCRIPTION_OR_CODE">
    {{ item.description || item.code }}
  </ng-container>

</div>
