import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { BreedTypesActions } from './breed-types.actions';
import * as reducer from './breed-types.reducer';
import { BreedTypesSelectors } from './breed-types.selectors';

@Injectable()
export class BreedTypesFacade {

  loaded$ = this.store.select(BreedTypesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(BreedTypesSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}

  get(): void {
    this.store.dispatch(BreedTypesActions.get());
  }
}
