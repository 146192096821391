import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-radio-button',
  templateUrl: './radio-button-type.component.html',
  styleUrls: ['./radio-button-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonTypeComponent extends FieldType<FieldTypeConfig> {}
