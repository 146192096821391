export enum OperationPermissionsProductManagement {
  ProductManagementDefinitionRead = 'productManagement.definition.read',
  ProductManagementDefinitionWrite = 'productManagement.definition.write',
  ProductManagementMappingRead = 'productManagement.mapping.read',
  ProductManagementMappingWrite = 'productManagement.mapping.write',
  ProductManagementPricingRead = 'productManagement.pricing.read',
  ProductManagementPricingWrite = 'productManagement.pricing.write',
  ProductManagementReceiptRead = 'productManagement.receipt.read',
  ProductManagementReceiptWrite = 'productManagement.receipt.write',
  ProductManagementReconciliationRead = 'productManagement.reconciliation.read',
  ProductManagementReconciliationWrite = 'productManagement.reconciliation.write',
  ProductManagementHasLotNoExpiry = 'productManagement.hasLotNoExpiry',
  ProductManagementHasTransactions = 'productManagement.hasTransactions'
}
