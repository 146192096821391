import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';

@Component({
  selector: 'sersi-grid-add-button-renderer',
  templateUrl: './add-button-renderer.component.html'
})

export class GridAddButtonRendererComponent implements OnInit {

  @Input() disabled: boolean;
  @Input() hotKey?: string;
  @Output() addNewRow = new EventEmitter<void>();

  private hotKeys = inject(HotkeysService);

  ngOnInit(): void {
    this.handleHotKey();
  }

  private handleHotKey(): void {
    if (!this.hotKey) return;

    this.hotKeys.add(new Hotkey(this.hotKey, (): boolean => {
      if(!this.disabled)
        this.addNewRow.emit();
      return false;
    }, ['Input']))
  }

}
