import { createSelector } from '@ngrx/store';

import { convertSelectListItemDtoToSelectListItem } from '@common/angular/translation';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { CommonUserProfileSelectors } from '@common/angular/features/user-profile';

import { selectFeatureState, State } from '../reducer';
import * as reducer from './dx-groups.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.dxGroups
);

const selectItems = createSelector(selectState, reducer.getAll);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

const selectTranslatedItems = createSelector(
  selectItems,
  CommonUserProfileSelectors.selectLanguage,
  (items: SelectListItemWithTranslationDto[], lang: string) => convertSelectListItemDtoToSelectListItem(items, lang));

const selectPmGroups = createSelector(selectTranslatedItems, (x: SersiSelectListItem[]) => {
  return x.filter(item => item.attributes ? item.attributes['DxGroupCategory'] === 'PM' : false);
});

const selectTxGroups = createSelector(selectTranslatedItems, (x: SersiSelectListItem[]) => {
  return x.filter(item => item.attributes ? item.attributes['DxGroupCategory'] === 'TX' : false);
});

export const DxGroupsSelectors = {
  selectItems,
  selectLoaded,
  selectTranslatedItems,
  selectPmGroups,
  selectTxGroups
};
