import {
  AdgBulkUpdateDto,
  AdgSettingsListDto,
  AdgSettingsListRequestDto,
  AdgSettingsUpdateSelectItemsDto
} from '@ifhms/models/admin';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

@Injectable()
export class EnterpriseAdgSettingsManagementService {
  constructor(
    private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string
  ) {
  }

  getAdgSettingsList(request: AdgSettingsListRequestDto): Observable<AdgSettingsListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<AdgSettingsListDto>(`${this.apiUrl}/Settings/AdgSettings`, { params });
  }

  bulkUpdateAdgSettings(settingsUpdateDto: AdgBulkUpdateDto): Observable<AdgSettingsUpdateSelectItemsDto> {
    return this.http.put<AdgSettingsUpdateSelectItemsDto>(`${this.apiUrl}/Settings/AdgSettings/BulkUpdate`, settingsUpdateDto);
  }

  updateSelectedAdgSettings(settingsUpdateDto: AdgSettingsUpdateSelectItemsDto): Observable<AdgSettingsUpdateSelectItemsDto> {
    return this.http.put<AdgSettingsUpdateSelectItemsDto>(`${this.apiUrl}/Settings/AdgSettings`, settingsUpdateDto);
  }

  getAdgWeightRangeSelectList(): Observable<SersiSelectListItem[]> {
    return this.http.get<SersiSelectListItem[]>(`${this.apiUrl}/Settings/AdgSettings/AdgWeightRangeSelectList`);
  }
}