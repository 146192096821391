import { FactoryProvider, InjectionToken } from '@angular/core';
import { CommonAppConfig, IAppConfigService } from '../interfaces';

export const APP_CONFIG = new InjectionToken<CommonAppConfig>('APP_CONFIG');
export const IS_DESKTOP = new InjectionToken<boolean>('IS_DESKTOP');

export const SHOW_DEV_FEATURE = new InjectionToken<boolean>('SHOW_DEV_FEATURE');
export const WINDOW = new InjectionToken<Window>('WINDOW');
export const WindowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: getWindow
};

export function getWindow(): Window {
  return window;
}

export const getConfig = <T>(appConfig: IAppConfigService<T>): T => {
  return appConfig.getConfig();
};

export const getReportApiUrl = (appConfig: IAppConfigService<CommonAppConfig>): string | undefined => {
  return `${appConfig.getConfig().apiUrl}/Reports`;
};

export const getReportSelectListApiUrl = (appConfig: IAppConfigService<CommonAppConfig>): string | undefined => {
  return `${appConfig.getConfig().apiUrl}/ReportSelectLists`;
};

export const getAnimalsApiUrl = (appConfig: IAppConfigService<CommonAppConfig>): string | undefined => {
  return `${appConfig.getConfig().apiUrl}/Animals`;
};

export const getAuthServerUrls = (appConfig: IAppConfigService<CommonAppConfig>): string | undefined => {
  return appConfig.getConfig().authServerUrl;
};

export const getAuthAppOrigin = (appConfig: IAppConfigService<CommonAppConfig>): string | undefined => {
  return appConfig.getConfig().authRedirectOrigin;
};

export const getApiUrl = (appConfig: IAppConfigService<CommonAppConfig>): string | undefined => {
  return appConfig.getConfig().apiUrl;
};
