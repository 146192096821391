export enum ProductSortBy {
  CodeAscending = 0,
  CodeDescending = 1,
  DescriptionAscending = 2,
  DescriptionDescending = 3,
  NoteAscending = 4,
  NoteDescending = 5,
  TypeAscending = 6,
  TypeDescending = 7,
  SourceAscending = 8,
  SourceDescending = 9,
  ActiveAscending = 10,
  ActiveDescending = 11
}