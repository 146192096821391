import { Pipe, PipeTransform } from '@angular/core';
import { camelCaseToKebab } from '../helpers';

@Pipe({
  name: 'enumKey'
})
export class EnumKeyPipe implements PipeTransform {

  transform(value: string | number, enumObject: Record<string, string|number>): string {
    const enumKey = Object.keys(enumObject).find(key => enumObject[key] === value);
    return enumKey ? camelCaseToKebab(enumKey) : '';
  }

}
