import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, of, withLatestFrom } from 'rxjs';

import { CompaniesService } from '@ifhms/common/angular/data-access/admin-api';
import { CompanyDto } from '@ifhms/models/admin';
import { getPreviousValue } from '@common/angular/utils';

import { CompanyActions } from './company.actions';
import { CompanyFacade } from './company.facade';
import { State } from './company.reducer';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private companyFacade: CompanyFacade,
    private companiesService: CompaniesService
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.get),
      withLatestFrom(getPreviousValue(this.companyFacade.state$)),
      filter(([action, state]) => {
        const isCompanyChange = this.isCompanyChange(action.slug, state);

        if (action.forceReload || isCompanyChange) return true;

        return !state.loaded && !state.loading;
      }),
      exhaustMap(([action]) =>
        this.companiesService.getBySlug(action.slug).pipe(
          map((result: CompanyDto) =>
            CompanyActions.getSuccess({
              company: result
            })
          ),
          catchError(() => of(CompanyActions.error()))
        )
      )
    )
  );

  getCached$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.get),
      withLatestFrom(getPreviousValue(this.companyFacade.state$)),
      filter(([action, state]) => {
        return !action.forceReload && state.loaded && !this.isCompanyChange(action.slug, state);
      }),
      map(() => CompanyActions.getSuccessCached())
    )
  );

  private isCompanyChange(targetSlug: string, state: State): boolean {
    const currentCompanySlug = state?.company?.slug;
    return !!currentCompanySlug && currentCompanySlug !== targetSlug;
  }

}
