import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeapService } from './services';

@NgModule({
  imports: [CommonModule]
})

export class HeapModule {
  constructor(
    private heapService: HeapService,
    @Optional() @SkipSelf() parentModule?: HeapModule
  ) {
    if (parentModule) {
      throw new Error(
        'HeapModule is already loaded. Import it once in the AppModule'
      );
    }
    this.heapService.init();
  }
}
