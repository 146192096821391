import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { SpinComponent } from './components/spin/spin.component';
import { NgLetModule } from 'ng-let';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  imports: [ CommonModule, TranslocoModule, ProgressSpinnerModule, NgLetModule ],
  declarations: [SpinComponent],
  exports: [SpinComponent]
})
export class SersiAngularUiSpinModule {
}
