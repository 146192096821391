import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { DoseUnitsActions } from './dose-units.actions';
import * as reducer from './dose-units.reducer';
import { DoseUnitsSelectors } from './dose-units.selectors';

@Injectable()
export class DoseUnitsFacade {

  loaded$ = this.store.select(DoseUnitsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(DoseUnitsSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}

  get(): void {
    this.store.dispatch(DoseUnitsActions.get());
  }
}
